/*
 * @Author: your name
 * @Date: 2020-09-25 16:05:47
 * @LastEditTime: 2020-10-13 18:27:27
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/pages/Daily/Model/Box/style.ts
 */

import styled from 'styled-components'

export const Box = styled.div`
  position: absolute;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index:4;
`

export const PopoverHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Order = styled.div`
  width:100px; 
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`