import React, { FC, ReactNode, CSSProperties } from 'react'

interface HeaderProps {
  title: string,
  style?: CSSProperties,
  children?: ReactNode
}

const Info: FC<HeaderProps> = (props) => (
  <div style={{ fontSize: '14px', display: 'flex', ...props.style }}>
    <p style={{ color: 'rgba(0, 0, 0, 0.85)' }}>{props.title}:</p>
    <p style={{ color: 'rgba(0, 0, 0, 0.65)' }}>&nbsp;&nbsp;{props.children}</p>
  </div>
)

export default Info