/*
 * @Author: your name
 * @Date: 2020-09-02 15:12:12
 * @LastEditTime: 2020-11-06 18:32:15
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/utils/vac.ts
 */
import { message } from "antd"
// import { AddNewSupplierPayload } from "services/supplier"
import { Entry } from "types/system"

export const timeTableNewItem = (date: Date | undefined, timeRange: string[] | undefined,) => {
  if (!date) {
    message.error('请选择一个日期')
    return true
  }
  if (!timeRange || !timeRange.length || !timeRange[0]) {
    message.error('请选择开始时间')
    return true
  }
  if (!timeRange || !timeRange.length || !timeRange[1]) {
    message.error('请选择结束时间')
    return true
  }
  return false
}

export const timeQuickNewItem = (date: Date | undefined, timeRange: string[] | undefined) => {
  if (!date) {
    message.error('请选择一个日期')
    return true
  }
  if (!timeRange || !timeRange.length || !timeRange[0]) {
    message.error('请选择开始时间')
    return true
  }
  if (!timeRange || !timeRange.length || !timeRange[1]) {
    message.error('请选择结束时间')
    return true
  }
  return false
}

export const userLoginVac = (username: string | undefined, password: string | undefined) => {
  if (!username) {
    message.error('用户名不能为空')
    return true
  }
  if (!password) {
    message.error('密码不能为空')
    return true
  }
  return false
}

// export const addSupplierVac = (payload: AddNewSupplierPayload) => {
//   if (!payload.name) {
//     message.error('供应商名称不能为空')
//     return true
//   }
//   if (!payload.phone) {
//     message.error('供应商联系方式不能为空')
//     return true
//   }
//   if (!payload.group) {
//     message.error('必须选择一个策略分组')
//     return true
//   }
//   if (!payload.weight) {
//     message.error('必须填写权重')
//     return true
//   }
//   return false
// }

export const addCustomOrderModalVac = (payload: Entry) => {
  if (!payload.name) {
    message.error('题目名称不能为空')
    return true
  }
  if (!payload.type) {
    message.error('请选择题目类型')
    return true
  }
  if ((payload.type === "单选" || payload.type === "多选") && !(payload.options && payload.options.length)) {
    message.error('请选至少添加一个选项')
    return true
  }
  return false
}
