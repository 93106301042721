import styled from "styled-components"

export const Row = styled.div`
  display: flex;
`

export const InputRow = styled.div`
  display: flex;
  width: 260px;
  justify-content: space-between;
  align-items: center;
`