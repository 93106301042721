import React, { FC, useState } from "react"
import { Route } from "react-router-dom"
import { BreadcrumbOption } from "types/antd"
import { Header } from "components"
import { useTranslation } from "react-i18next"
import List from "./View/List"
import Detail from "./View/Detail"
import Annex from "./View/Annex"
import Endorse from "./View/Endorse"

const Order: FC = () => {
  const { t } = useTranslation()
  const [breadcrumbs] = useState<BreadcrumbOption[]>([{
    name: "订单管理"
  }, {
    name: "订单列表"
  }])

  // init
  // useEffect(() => {
  //   (async () => {
  //     const data = await order.getConfigure()
  //     setConfigure(data)
  //   })()
  // }, [])

  return (
    <div>
      <Route path='/order/list' render={() =>
        <React.Fragment>
          <Header
            title={t("page_order_title")}
            description={t("page_order_description")}
            breadcrumbs={breadcrumbs}
          />
          <List />
        </React.Fragment>
      } />
      <Route path='/order/detail/:orderId' render={() => <Detail />} />
      <Route path='/order/annex/:orderId' render={() => <Annex />} />
      <Route path='/order/endorse/:orderId/:subjectId' render={() => <Endorse />} />
    </div>
  )
}

export default Order
