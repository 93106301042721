import styled from 'styled-components'
import { Input, Select } from 'antd'

export const Box = styled.div`
  padding: 15px 16px;
  box-sizing: border-box;
  border-bottom: 1px solid #EBEDF0;
  margin-bottom: 10px;
  background-color: #FFFFFF;
`

export const Title = styled.h4`
  font-size: 16px;
  color: #323233;
  line-height: 20px;
  padding-bottom: 10px;
`

export const Search = styled(Input.Search)`
  width: 90%;
  margin: 10px 5% 0px 5%;
  border-radius: 12px;
`

export const StatusSelect = styled(Select)`
  width: 80%;
  display: block;
  margin: -5px auto 10px auto;
`
