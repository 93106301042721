import { Block } from 'components'
import React, { FC, useEffect, useState } from 'react'
import Add from 'src/pages/Time/Model/Add'
import Card from 'src/pages/Time/Model/Card'
import { List } from 'src/pages/Time/View/style'
import { CardList as CardListType } from 'services/time'
import { system } from 'services'
import AddRestCardDialog from '../../Model/addRestCardDialog'

const DisplayTimeRest: FC = () => {

  const [loading, setLoading] = useState<boolean>(true)
  const [cardList, setCardList] = useState<CardListType[]>()
  const [render, setRender] = useState<number>(0)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  useEffect(() => {
    (async () => {
      setLoading(true)
      const data = await system.getDisplayTimeRestCardList()
      setLoading(false)
      setCardList(data)
    })()
  }, [render])

  return (

    <div>
      <Block
        showBack
        title="预约项目列表"
        style={{ padding: 0 }}
        loading={loading}
      />
      <List>
        <Add onClick={() => setIsOpen(true)} />
        {cardList && cardList.length ? cardList.map(item =>
          <Card key={item.id} tag={`/system/display_time/detail/${item.id}`} name={item.name} description={item.description} img_url={item.img_url} />
        ) : null
        }
      </List>
      <AddRestCardDialog
        isOpen={isOpen}
        onSubmit={() => {
          setIsOpen(false)
          setRender(render + 1)
        }}
        onClose={() => setIsOpen(false)}
      />
    </div >
  )
}

export default DisplayTimeRest