/*
 * File: /src/pages/Order/View/Annex/index.tsx
 * Author: Mxsyx (zsimline@163.com)
 * Create Time: Wednesday 2020-11-11 05:45:03
 * Last Modified: Wednesday 2020-11-11 05:45:03
 * Modified By: Mxsyx (zsimline@163.com>)
 * 
 * Copyright © 2020 https://www.joyreserve.com
 */
import React, { FC, Fragment } from 'react'
import Pictures from './Pictures'
import Feedbacks from './Feedbacks'

const Annex: FC = () => {
  return (
    <Fragment>
      <Pictures />
      <Feedbacks />
    </Fragment>
  )
}

export default Annex
