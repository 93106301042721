import { Button, message } from 'antd'
import React, { FC, Fragment, ReactNode, useCallback, useEffect, useState } from 'react'
import { weekItem } from 'types/time'
import { FormatDay } from 'utils/time'
import { order } from 'services'
import manual from 'services/manual'
import { Block } from 'components'
import { TimeFragment } from 'types/app'
import WeekTable from 'components/WeekTable'
import { BoxSpace, NoneBox } from './style'
import { getWeekTableRange } from 'components/WeekTable/utils'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router'
import Box from 'src/pages/Manual/Model/Box'

const Endorse: FC = () => {
  const now = new Date()
  const { orderId, subjectId } = useParams<{ orderId: string, subjectId: string }>()
  const history = useHistory()
  const [date, setDate] = useState<Date>(now)
  const [weekList, setWeekList] = useState<weekItem[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [FragmentList, setFragmentList] = useState<TimeFragment[]>()
  const [selectTimeFragmentList, setSelectTimeFragmentList] = useState<string[]>([])
  const [scrollTop, setScrollTop] = useState<number>(590)
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)

  //渲染空的格子
  const renderNoneBoxList = useCallback(() => {
    const result: ReactNode[] = []
    for (let index = 0; index < 168; index++) {
      result.push(<NoneBox><BoxSpace /></NoneBox>)
    }
    return result
  }, [])

  //请求时间列表
  useEffect(() => {
    subjectId && (async () => {
      setLoading(true)
      const data = await manual.getReserveList({
        date: FormatDay(new Date(date.getTime() + (24 * 60 * 60 * 1000))),
        resource_id: subjectId
      })
      setLoading(false)
      const result: TimeFragment[] = []
      for (let date in data) {
        data[date].forEach(item => {
          result.push({ ...item, date, display_id: `${item.id}_${date}_${item.time}` })
        })
      }
      setFragmentList(result)
    })()
  }, [subjectId, date])

  const handleClickFragment = useCallback((display_id: string) => {
    if (!!selectTimeFragmentList?.some(id => id === display_id)) {
      setSelectTimeFragmentList([])
    } else {
      setSelectTimeFragmentList([display_id])
    }
  }, [selectTimeFragmentList])

  //计算时间格子生成后移动高度
  useEffect(() => {
    if (!FragmentList || !FragmentList.length) return
    let min = 590
    FragmentList.forEach(item => {
      const [hour, minute] = item.time.split("-")[0].split(":")
      const startTime = Number(hour) * 60 + Number(minute)
      if (min > getWeekTableRange(startTime)) min = getWeekTableRange(startTime)
    })
    setScrollTop(min - 1 + Math.random())
  }, [FragmentList])

  useEffect(() => {
    setSelectTimeFragmentList([])
  }, [date])

  const handleClickSubmit = useCallback(async () => {
    setSubmitLoading(true)
    const [_id, date, time] = selectTimeFragmentList[0].split("_")
    const tag = date.split("-").join("") + time.split("-").join("").split(":").join("")
    try {
      await order.action({
        action: "改签",
        id: [orderId],
        payload: { tag: [tag] }
      })
      message.success("改签成功")
      setSubmitLoading(false)
      history.push(`/order/detail/${orderId}`)
    } catch (err) {
      message.error(err.response.data.message)
      setSubmitLoading(false)
    }

    console.log(selectTimeFragmentList)
  }, [selectTimeFragmentList, orderId])

  return (
    <Fragment>
      <Block
        style={{ paddingTop: 17 }}
      >
        <WeekTable
          now={now}
          date={date}
          loading={loading}
          scrollTop={scrollTop}
          setDate={(payload) => { setDate(payload) }}
          renderNoneBoxList={renderNoneBoxList}
          getWeekList={(weekList) => setWeekList(weekList)}
          customHeader={
            <React.Fragment>
              <Button
                disabled={!selectTimeFragmentList.length}
                type="primary"
                style={{
                  position: "absolute",
                  right: 0,
                  top: 0
                }}
                loading={submitLoading}
                onClick={handleClickSubmit}
              >
                确认修改
                 </Button>
            </React.Fragment>
          }
        >
          {FragmentList?.map(item => <Box
            data={item}
            isSelect={!!(selectTimeFragmentList?.some(id => id === item.display_id))}
            weekList={weekList}
            onClick={handleClickFragment}
          />)}
        </WeekTable>
      </Block>
    </Fragment>
  )
}

export default Endorse