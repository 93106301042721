import React, { FC, useCallback, useState } from 'react'
import { Button, message, Modal } from 'antd'
import XLSX from 'components/XLSX'
import services from 'services'

interface Props {
  visible: boolean,
  onCancel: () => void,
  onOk: () => void
}

const ImportUser: FC<Props> = (props) => {
  const { visible, onCancel, onOk } = props
  const [matrix, setMatrix] = useState<AnyObject[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const handleClick = useCallback(async () => {
    if (!matrix.length) {
      message.warning('请选择要导入的用户列表')
      return
    }
    setLoading(true)
    services.user.create({users: matrix})
      .then(() => {
        message.success('导入用户列表成功')
        onOk()
        onCancel()
      })
      .catch(() => {
        message.error('导入用户列表失败')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [matrix])

  return (
    <Modal
      title="导入用户列表"
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <XLSX
        mapping={{
          'A': { name: 'username' },
          'B': { name: 'phone' },
          'C': { name: 'password', default: '123456' }
        }}
        onChange={matrix => setMatrix(matrix)}
        hasHeader
        type="dragger"
        loading={loading}
      />
      <Button
        type="primary"
        style={{ display: 'block', margin: '10px auto 0px auto' }}
        onClick={handleClick}
      >确定导入</Button>
    </Modal>
  )
}

export default ImportUser
