import { Modal } from "antd"
import React, { FC, ReactNode } from "react"
interface ModalType {
  title: string,
  isOpen: boolean,
  onClose: () => void
  callBack: (type: string) => void,
  children: ReactNode,
}

const EditModal: FC<ModalType> = (props) => {
  const { isOpen, callBack, onClose, children, title } = props

  return (
    <Modal
      visible={isOpen}
      title={title}
      okText="当前时段"
      cancelText="全部时段"
      onOk={() => { callBack("single") }}
      onCancel={(e) => {
        const dom = e.target as Element
        dom.textContent === "全部时段" ? callBack("multiple") : onClose()
      }}
    >
      { children}
    </Modal >
  )
}

export default EditModal
