import { DeleteOutlined } from "@ant-design/icons"
import { Button, Checkbox, Col, Divider, Input, Popconfirm, Row, Select } from "antd"
import { InputBox } from "components"
import React, { FC } from "react"
import { Rule } from "services/system"
import { Body, Mark, Text } from "./style"

interface DisplayTimeRuleCardType {
  state: string,
  form: Rule,
  resourceList: { id: number, name: string }[],
  setForm: (id: Rule) => void,
  handleClickDelete: (form: Rule) => void,
  handleClickEdit: (id: number) => void,
  handleClickReset: (id: number) => void,
  handleClickSubmit: (id: number) => void,
}

const DisplayTimeRuleCard: FC<DisplayTimeRuleCardType> = (props) => {
  const { Option } = Select
  const { state, form, resourceList, setForm, handleClickDelete,
    handleClickEdit, handleClickReset, handleClickSubmit
  } = props
  const isEdit = !!(state === "edit")
  return (
    <Body>
      <Row>
        <Col flex="20px" style={{ display: "flex", alignItems: "center" }}>
          <Checkbox
            checked={form.is_show_days}
            onChange={(e) => { setForm({ ...form, is_show_days: e.target.checked}) }}
          />
        </Col>
        <Col span={4}>
          <Input
            placeholder="请输入规则名称"
            value={form.rule_name}
            onChange={(e) => { setForm({ ...form, rule_name: e.target.value }) }}
            style={{
              border: isEdit ? "" : "1px solid #fff",
              fontWeight: 600,
              fontSize: 16
            }}
          />
        </Col>
        <Col flex={"25px"} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
          <Popconfirm
            title={`删除规则${form.rule_name}`}
            onConfirm={() => { handleClickDelete(form) }}
          >
            <DeleteOutlined style={{ color: "#ccc", zIndex: 11 }} />
          </Popconfirm>
        </Col>
      </Row>
      <Text>适用预约项目：</Text>
      <Select
        mode="multiple"
        value={form.resource_id_list}
        style={{ marginTop: 15, width: 464 }}
        onChange={(value) => { setForm({ ...form, resource_id_list: value }) }}
      >
        {resourceList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
      </Select>
      <Row justify="space-between" style={{ marginTop: 15 }}>
        <InputBox title="展示天数" >
          <Input
            type="number"
            value={form.show_days}
            style={{ width: 80 }}
            onChange={(e) => { setForm({ ...form, show_days: Number(e.target.value) }) }}
          />
        </InputBox>
        <Col span={4}>
          {isEdit ?
            <div>
              <Button onClick={() => { handleClickReset(form.id!) }}>重置</Button>
              <Button onClick={() => { handleClickSubmit(form.id!) }} style={{ marginLeft: 8 }} type="primary">保存</Button>
            </div> :
            <Button style={{ zIndex: 11 }} onClick={() => { handleClickEdit(form.id!) }}>修改</Button>
          }
        </Col>
      </Row>
      <Divider style={{ margin: "32px 0 0" }} />
      { isEdit ? null : <Mark />}
    </Body >
  )

}

export default DisplayTimeRuleCard