import styled from 'styled-components'

export const ItemImg = styled.img`
  width: 48px;
  height: 48px;
`

export const ItemName = styled.h5`
  font-size: 14px;
  color: rgba(0,0,0,0.65);
  line-height: 22px;
`

export const ItemSummary = styled.span`
  font-size: 14px;
  color: rgba(0,0,0,0.45);
  line-height: 22px;
`

export const WechatVisible = styled.span<{ visible: boolean }>`
  border-radius:2px;
  border:1px solid ${props => props.visible ? '#B7EB8F' : 'rgba(0,0,0,0.15)'};
  background-color: ${props => props.visible ? '#F6FFED' : 'rgba(0,0,0,0.04)'};
  font-size: 12px;
  color: ${props => props.visible ? '#52C41A' : 'rgba(0,0,0,0.45)'};
  line-height: 20px;
  padding: 0px 8px;
`
