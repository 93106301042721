/*
 * File: /src/services/order_rule.ts
 * Author: Mxsyx (zsimline@163.com)
 * Create Time: Monday 2020-11-09 04:20:44
 * Last Modified: Monday 2020-11-09 04:20:44
 * Modified By: Mxsyx (zsimline@163.com>)
 * 
 * Copyright © 2020 https://www.joyreserve.com
 */
import http from 'utils/http'
import { CommonRule, PrivateRule, RuleToResource } from 'types/orderRule'

export default {
  common: {
    // 获取通用下单规则
    get: () => http.get<CommonRule>('/Admin/reserve_rule/rule'),
    // 更新通用规则
    update: (payload: CommonRule) => http.put<any>('/Admin/reserve_rule/rule', payload),
  },
  private: {
    // 创建私有下单规则
    create: (payload: PrivateRule) => http.post<any>('/Admin/reserve_rule/privates_rule', payload),
    // 删除私有下单规则
    delete: (id: number) => http.del<any>(`/Admin/reserve_rule/privates_rule/${id}`),
    // 更新私有下单规则
    update: (id: number, payload: PrivateRule) => http.put<any>(`/Admin/reserve_rule/privates_rule/${id}`, payload),
    // 获取私有下单规则列表
    getList: () => http.get<PrivateRule[]>('/Admin/reserve_rule/privates_rule'),
    // 获取预约对象与下单规则的映射关系
    getResources: () => http.get<RuleToResource[]>('/Admin/reserve_rule/resources')
  }
}


