/*
 * File: /src/services/attend.tsx
 * Author: Mxsyx (zsimline@163.com)
 * Create Time: Wednesday 2020-11-11 01:38:30
 * Last Modified: Wednesday 2020-11-11 01:38:30
 * Modified By: Mxsyx (zsimline@163.com>)
 * 
 * Copyright © 2020 https://www.joyreserve.com
 */
import http from 'utils/http'
import { CommonRule, Order, PrivateRule, RuleToResource } from 'types/attend'

export default {
  common: {
    // 获取通用下单规则
    get: () => http.get<CommonRule>('/Base/attend/rule'),
    // 更新通用规则
    update: (payload: CommonPayload<CommonRule>) => http.put<any>('/Base/attend/rule', payload),
  },
  private: {
    // 创建私有下单规则
    create: (payload: PrivateRule) => http.post<any>('/Base/attend/privates_rule', payload),
    // 删除私有下单规则
    delete: (id: number) => http.del<any>(`/Base/attend/privates_rule/${id}`),
    // 更新私有下单规则
    update: (id: number, payload: PrivateRule) => http.put<any>(`/Base/attend/privates_rule/${id}`, payload),
    // 获取私有下单规则列表
    getList: () => http.get<PrivateRule[]>('/Base/attend/privates_rule'),
    // 获取预约资源列表
    getResourceList: () => http.get<RuleToResource[]>('/Admin/Resource/resource_list'),
    // 获取预约资源与下单规则的映射关系
    getResourceIds: () => http.get<RuleToResource[]>('/Base/attend/resource_ids')
  },
  // 获取签到二维码
  getQRCode: (payload?: QRCodePayload) => http.get<QRCodeResult>('/Admin/Config/attend_code', payload),
  // 刷新签到二维码
  refreshQRCode: (payload?: QRCodePayload) => http.put<QRCodeResult>('/Admin/Config/attend_code', payload),
  // 获取签到订单列表
  getOrderList: (payload: QueryPagePayload) => http.get<DataList<Order>>('/Scene/attended', payload)
}
