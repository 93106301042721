/*
 * File: /src/pages/System/View/Attend/index.tsx
 * Author: Mxsyx (zsimline@163.com)
 * Create Time: Monday 2020-11-09 04:00:18
 * Last Modified: Monday 2020-11-09 04:00:19
 * Modified By: Mxsyx (zsimline@163.com>)
 * 
 * Copyright © 2020 https://www.joyreserve.com
 */
import React, { FC, Fragment, useState } from 'react'
import { Route, useHistory } from 'react-router-dom'
import { Header } from 'components'
import { BreadcrumbOption, TabOption } from 'types/antd'
import QRCode from './QRCode'
import Rule from './Rule'
import List from './List'
import { getSelectTabKey } from 'utils/common'

const breadcrumbs: BreadcrumbOption[] = [{
  name: '系统配置',
}, {
  name: '预约签到设置'
}]

const tabs: TabOption[] = [{
  key: '0',
  name: '签到二维码',
  link: '/system/attend/qrcode'
}, {
  key: '1',
  name: '签到规则',
  link: '/system/attend/rule'
}, {
  key: '2',
  name: '签到列表',
  link: '/system/attend/list'
}]

const Attend: FC = () => {
  const { pathname } = useHistory().location
  const [selectTabKey, setSelectTabKey] = useState<string>(getSelectTabKey(tabs, pathname))

  return (
    <Fragment>
      <Header
        title='预约规则设置'
        description=''
        tabs={tabs}
        breadcrumbs={breadcrumbs}
        selectTabKey={selectTabKey}
        onTabClick={key => setSelectTabKey(key)}
      />
      <Route path='/system/attend/qrcode' render={() => <QRCode />} />
      <Route path='/system/attend/rule' render={() => <Rule />} />
      <Route path='/system/attend/list' render={() => <List />} />
    </Fragment>
  )
}

export default Attend
