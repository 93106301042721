import { message, Select } from 'antd'
import { Block, InputBox } from 'components'
import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react'
import { FormatDay } from 'utils/time'
import { Box as BoxBorder, BoxSpace } from './style'
import { daily } from 'services'
import { TimeFragment } from 'services/dilay'
import Box from '../Model/Box'
import order, { ActionPayload } from 'services/order'
import { weekItem } from 'types/time'
import WeekTable from 'components/WeekTable'
import { getWeekTableRange } from 'components/WeekTable/utils'

const Week: FC = () => {
  const { Option } = Select
  const now = new Date()
  const [resourceList, setResourceList] = useState<{ id: string, name: string }[]>()
  const [selectTimeFragment, setSelectTimeFragment] = useState<string>("")
  const [timeList, setTimeList] = useState<TimeFragment[]>()
  const [loading, setLoading] = useState<boolean>(false)
  const [selectRowIdList, setSelectRowIdList] = useState<React.ReactText[]>([])
  const [render, setRender] = useState<number>(0)
  const [date, setDate] = useState<Date>(now)
  const [weekList, setWeekList] = useState<weekItem[]>([])
  const [selectResource, setSelectResource] = useState<number>()
  const [scrollTop, setScrollTop] = useState<number>(590)

  //渲染时间格子
  const renderBoxList = useCallback(() => {
    const result: ReactNode[] = []
    for (let index = 0; index < 168; index++) {
      result.push(<BoxBorder><BoxSpace /></BoxBorder>)
    }
    return result
  }, [weekList])

  useEffect(() => {
    (async () => {
      const data = await daily.getResourceList()
      setResourceList(data)
    })()
  }, [])

  useEffect(() => {
    if (!selectResource) {
      setTimeList([])
      return
    }
    (async () => {
      try {
        setLoading(true)
        const timeList = await daily.getWeekFragmentList({
          date: FormatDay(new Date(date.getTime() + (24 * 60 * 60 * 1000))),
          resource_id: selectResource.toString()
        })
        const result: TimeFragment[] = []
        for (let index in timeList) {
          timeList[index].length && timeList[index].forEach(item => {
            item.date = index
            result.push(item)
          })
        }
        setTimeList(result)
      } catch (err) {
        err.response && message.error(err.response.data.message)
        setTimeList([])
      } finally {
        setLoading(false)
      }
    })()
  }, [date, selectResource, render])

  //计算时间格子生成后移动高度
  useEffect(() => {
    if (!timeList || !timeList.length) return
    let min = 590
    timeList.forEach(item => {
      const [hour, minute] = item.time.split("-")[0].split(":")
      const startTime = Number(hour) * 60 + Number(minute)
      if (min > getWeekTableRange(startTime)) min = getWeekTableRange(startTime)
    })
    setScrollTop(min - 1 + Math.random())
  }, [timeList])

  const orderSubmit = useCallback(async () => {
    if (!selectRowIdList || !selectRowIdList.length) return
    try {
      let payload: ActionPayload = {
        id: selectRowIdList as string[],
        action: "签到",
        payload: {}
      }
      await order.action(payload)
      message.success(`您已完成操作签到`, 5)
      setSelectRowIdList([])
      setSelectTimeFragment("")
      setRender(render + 1)
    } catch (err) {
      message.error(`操作出现异常`, 5)
    }
  }, [selectRowIdList, render])

  useEffect(() => {
    setSelectRowIdList([])
  }, [selectTimeFragment])

  return (
    <Block
      style={{ paddingTop: 17 }}
    >
      <WeekTable
        now={now}
        date={date}
        loading={loading}
        setDate={(payload) => { setDate(payload) }}
        renderNoneBoxList={renderBoxList}
        getWeekList={(weekList) => setWeekList(weekList)}
        scrollTop={scrollTop}
        customHeader={
          <InputBox title="预约项目" style={{ marginRight: 30 }}>
            <Select
              style={{ minWidth: 200, marginLeft: 15 }}
              placeholder="请选择预约项目"
              onChange={(value) => { setSelectResource(value) }}
              value={selectResource}
            >
              {resourceList && resourceList.length && resourceList.map(item => (
                <Option key={item.id} value={item.id}>{item.name}</Option>
              ))}
            </Select>
          </InputBox>
        }
      >
        {timeList?.map(item =>
          <Box
            key={Math.random()}
            data={item}
            type="time"
            weekList={weekList}
            selectTimeFragment={selectTimeFragment}
            onClick={(key) => { setSelectTimeFragment(selectTimeFragment === key ? "" : key) }}
            selectRowIdList={selectRowIdList}
            onChange={(selectRowIdList: React.ReactText[]) => { setSelectRowIdList(selectRowIdList) }}
            onSubmit={orderSubmit}
            onCancel={() => {
              setSelectRowIdList([])
              setSelectTimeFragment("")
              setRender(render + 1)
            }}
          />
        )}
      </WeekTable>
    </Block>
  )
}

export default Week