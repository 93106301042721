import React, { FC } from 'react'
import { Route } from 'react-router-dom'
import { Header } from 'components'
import Detail from './View/Detail'

const Manual: FC = () => {

  return (
    <div>
      <Header
        title={"手动添加预约"}
        description={""}
      />
      <Route path='/manual/detail' render={() => <Detail />} />
    </div>
  )
}

export default Manual
