/*
 * File: /src/pages/System/View/OrderRule/Rule.tsx
 * Author: Mxsyx (zsimline@163.com)
 * Create Time: Tuesday 2020-11-10 10:47:17
 * Last Modified: Tuesday 2020-11-10 10:47:18
 * Modified By: Mxsyx (zsimline@163.com>)
 * 
 * Copyright © 2020 https://www.joyreserve.com
 */
import React, { FC } from "react"
import { Row, Col, InputNumber, Radio } from "antd"
import { Range } from 'types/attend'

interface Props {
  range: Range,
  onChange: (value: Range) => void
  editable?: boolean
}

const Rule: FC<Props> = (props) => {
  const { range, onChange, editable } = props

  return (
    <Row gutter={[0, 20]}>
      <Col span={24}>
        <Radio
          disabled={editable}
          checked={range[0][0] === 'T'}
          onClick={() => {
            range[0][0] = range[0][0] === 'T' ? 'F' : 'T'
            onChange([...range])
          }}
        />提前签到
        <InputNumber
          disabled={editable}
          value={range[0][1]}
          style={{ marginLeft: 10 }} 
          onChange={(value) => {
            range[0][1] = Math.abs(value as number)
            onChange([...range])
          }}
        /> 分钟
      </Col>
      <Col span={24}>
        <Radio 
          disabled={editable}
          checked={range[1][0] === 'T'} 
          onClick={() => {            
            range[1][0] = range[1][0] === 'T' ? 'F' : 'T'
            onChange([...range])
          }}  
        />迟到签到
        <InputNumber
          disabled={editable}
          value={range[1][1]}
          style={{ marginLeft: 10 }}
          onChange={(value) => {
            range[1][1] = Math.abs(value as number)
            onChange([...range])
          }}
        /> 分钟
      </Col>
    </Row>
  )
}

export default Rule
