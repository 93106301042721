/*
 * @Author: your name
 * @Date: 2020-09-02 15:12:12
 * @LastEditTime: 2020-10-21 15:15:37
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/types/time.ts
 */
export enum RereadRuleType {
  day = 'day',
  work = 'work',
  rest = 'rest',
  week = 'week',
  month = 'month',
  custom = 'custom',
  today = 'today'
}

export enum PeriodSpaceType {
  day = "day",
  week = "week",
  month = "month"
}

export enum PeriodType {
  always = "always",
  date = "date",
  number = "number"
}

export interface weekItem {
  year: string,
  month: string,
  day: string,
  week: string,
  isToady: boolean
}

export interface EditDefaultType {
  defaultTimeId: number,
  defaultDate: string,
  isEdit: boolean
}

export interface NewDefaultType {
  defaultDate: string,
  defaultTime: string,
}

export interface CustomRuleType {
  period_space: PeriodSpaceType,
  period_value: number,
  period_type: PeriodType,
  period_data: any,
  day: number[],
}
