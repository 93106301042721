import { SearchOutlined } from "@ant-design/icons"
import { Button, Col, Input, message, Row, Spin, Switch } from "antd"
import { InputBox } from "components"
import React, { FC, useCallback, useEffect, useState } from "react"
import system, { EmpowerWeChatResult } from "services/system"

function stringToHex(str: string) {
  let result = "";
  for (let i = 0; i < str.length; i++) {
    result += str.charCodeAt(i).toString(16);
  }
  return result
}

const WeChat: FC = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [isUse, setIsUse] = useState<boolean>(false)
  const [config, setConfig] = useState<EmpowerWeChatResult>()

  useEffect(() => {
    system.getEmpowerWeChat()
      .then((data) => {
        setConfig(data)
        setIsUse(data.is_use)
      })
      .catch(() => {
        message.error('获取微信授权信息失败')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const auth = useCallback(async () => {
    if (!config) return
    window.location.href = `https://cqp.joyreserve.com/index.php/Admin/Config/redirect_auth_url?empower_url=${stringToHex(config.empower_url || '')}`
  }, [config])

  const handleChangeSwitch = useCallback(async (value) => {
    setLoading(true)
    system.postEmpowerWeChat({ is_wechat_config: value })
      .then(() => {
        setIsUse(value)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return (
    <Spin spinning={loading}>
      <Row>
        <Col>
          <InputBox title="开启微信公众号预约配置" style={{ marginTop: 5 }}>
            <Switch
              checkedChildren="开启"
              unCheckedChildren="关闭"
              checked={isUse}
              onChange={handleChangeSwitch}
            />
          </InputBox>
        </Col>
        <Col offset={1}>
          <Button type="primary" disabled={!isUse} onClick={auth}>
            <SearchOutlined />点击授权
          </Button>
        </Col>
      </Row>
      <InputBox title="AppId" style={{ marginTop: 5 }}>
        <Input disabled value={config?.app_id} style={{ width: 234 }} />
      </InputBox>
    </Spin>
  )
}

export default WeChat
