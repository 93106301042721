import { Header } from "components"
import React, { FC, useEffect, useState } from "react"
import { Route, useHistory } from "react-router"
import { BreadcrumbOption, TabOption } from "types/antd"
import DisplayTimeCommon from "./DisplayTimeCommon"
import DisplayTimeRest from "./DisplayTimeRest"
import DisplayTimeDetail from "./DisplayTimeRestDetail"
import "./CanlendarStyle.css"

const DisplayTime: FC = () => {
  const { pathname } = useHistory().location
  const [selectTabKey, setSelectTabKey] = useState<string>(pathname === "/system/display_time/common" ? "0" : "1")
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbOption[]>([{
    name: "时间管理"
  }, {
    name: "项目时间段配置"
  }])

  const [tabs] = useState<TabOption[]>([{
    key: "0",
    name: "预约日期显示",
    link: "/system/display_time/common"
  }, {
    key: "1",
    name: "具体日期显示",
    link: "/system/display_time/rest"
  }])

  useEffect(() => {
    if (selectTabKey === "0") {
      setBreadcrumbs([{
        name: "预约实况"
      }, {
        name: "日预约实况"
      }
      ])
    } else {
      setBreadcrumbs([{
        name: "预约实况"
      }, {
        name: "周预约实况"
      }])
    }
  }, [selectTabKey])

  return (

    <div>
      {pathname === "/system/display_time/common" || pathname === "/system/display_time/rest" ?
        <Header
          title="预约显示设置"
          breadcrumbs={breadcrumbs}
          description=""
          tabs={tabs}
          selectTabKey={selectTabKey}
          onTabClick={(key) => { setSelectTabKey(key) }}
        /> : null
      }

      <Route path="/system/display_time/common" render={() => <DisplayTimeCommon />} />
      <Route path="/system/display_time/rest" render={() => <DisplayTimeRest />} />
      <Route path="/system/display_time/detail/:id" render={() => <DisplayTimeDetail />} />
    </div >
  )
}

export default DisplayTime