import React, { FC, useEffect, useState } from "react"
import { Route, useHistory } from "react-router-dom"
import { BreadcrumbOption, TabOption } from "types/antd"
import { Header } from "components"
import Today from "./View/Today"
import Week from "./View/Week"

const Daily: FC = () => {
  const { pathname } = useHistory().location
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbOption[]>()
  const [tabs] = useState<TabOption[]>([{
    key: "0",
    name: "日预约实况",
    link: "/daily/today"
  }, {
    key: "1",
    name: "周预约实况",
    link: "/daily/week"
  }])
  const [selectTabKey, setSelectTabKey] = useState<string>(pathname === "/daily/today" ? "0" : "1")

  useEffect(() => {
    if (selectTabKey === "0") {
      setBreadcrumbs([{
        name: "预约实况"
      }, {
        name: "日预约实况"
      }
      ])
    } else {
      setBreadcrumbs([{
        name: "预约实况"
      }, {
        name: "周预约实况"
      }])
    }
  }, [selectTabKey])

  return (
    <div>
      <Header
        title="预约实况"
        description=""
        breadcrumbs={breadcrumbs}
        tabs={tabs}
        selectTabKey={selectTabKey}
        onTabClick={(key) => { setSelectTabKey(key) }}
      />
      <Route path='/daily/today' render={() => <Today />} />
      <Route path='/daily/week' render={() => <Week />} />
    </div>
  )
}

export default Daily