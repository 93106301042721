import http from 'utils/http'
import { FieldItem } from 'types/register'

export default {
  // 创建表单
  create: (payload: Payload.Create) => http.post<any>('/Admin/Config/register', payload),
  // 删除表单
  delete: (id: number) => http.del<any>(`/Admin/Config/register/${id}`),
  // 更新表单
  update: (id: number, payload: Payload.Create) => http.put<any>(`/Admin/Config/register/${id}`, payload),
  // 获取表单列表
  getList: () => http.get<FieldItem[]>('/Admin/Config/register'),
}

declare namespace Payload {
  export interface Create {
    title: string,
    is_show: boolean,
    is_required: boolean,
    type: number,
    content?: string[]
  }
}
