import React, { FC, useState, useEffect, useCallback } from 'react'
import { Drawer, Select, Button, message } from 'antd'
import { CardList as CardListType } from 'services/time'
import { useParams } from 'react-router'
import { time } from 'services'
import { InputBox } from 'components'

interface EditPanelType {
  isOpen: boolean,
  onClose: () => void,
  onSubmit: () => void
}

const SyncPanel: FC<EditPanelType> = (props) => {
  const { Option } = Select
  const resource_id = Number(useParams<{ cardId: string }>().cardId)
  const [cardList, setCardList] = useState<CardListType[]>()
  const [targetId, setTargetId] = useState<number>()
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    (async () => {
      const data = await time.getCardList()
      data.splice(data.findIndex(item => item.id === resource_id), 1)
      setCardList(data)
    })()
  }, [])

  const handleClickSubmit = useCallback(async () => {
    if (!targetId) {
      message.error('请选择目标项目')
      return
    }
    setLoading(true)
    const data = await time.submitSync({ resource_id, check_resource_id: targetId })
    setLoading(false)
    props.onSubmit()
  }, [targetId, resource_id])

  return (
    <Drawer
      visible={props.isOpen}
      width={512}
      title='一键同步'
      bodyStyle={{
        padding: ' 0 24px',
      }}
      onClose={props.onClose}
      footerStyle={{ display: 'flex', justifyContent: 'flex-end' }}
      footer={
        <React.Fragment>
          <Button onClick={props.onClose}>取消</Button>
          <Button
            loading={loading}
            disabled={loading}
            onClick={handleClickSubmit}
            type='primary'
            style={{ marginLeft: '8px' }}
          >
            确定
          </Button>
        </React.Fragment>
      }
    >
      <InputBox title='选择同步到的预约项目' style={{ marginTop: '32px', marginLeft: '36px' }}>
        <Select
          value={targetId}
          placeholder='请选择时间表'
          style={{ width: '224px' }}
          onChange={(value) => { setTargetId(value) }}
        >
          {cardList && cardList.length ? cardList.map(item => (
            <Option key={item.id} value={item.id}>{item.name}</Option>
          )) : null}
        </Select>
      </InputBox>
    </Drawer>
  )
}

export default SyncPanel