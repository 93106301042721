/*
 * @Author: your name
 * @Date: 2020-09-02 15:12:12
 * @LastEditTime: 2020-10-19 10:28:18
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/pages/Time/Model/Card/style.ts
 */
import styled from "styled-components"

export const Body = styled.div`
  margin:0 0 24px 24px;
  width: 363px;
  height: 184px;
  background: rgba(255,255,255,1);
  border-radius: 2px;
  border: 1px solid rgba(233,233,233,1);
  display: flex;
  cursor: pointer;
  position: relative;

  :hover {
    box-shadow: 0 0 2px 2px rgba(233,233,233,1);
  }
`

export const Left = styled.div`
  margin-left: 24px;
  width: 48px;
`

export const Header = styled.img`
  width: 48px;
  height: 48px;
  margin-top: 24px;
`

export const Right = styled.div`
  width: 232px;
  margin-left: 16px;
`

export const Title = styled.div`
  height: 24px;
  margin-top: 22px;
  font-size: 16px;
  font-weight: 500;
  color: rgba(0,0,0,0.85);
  line-height: 24px;
`

export const Description = styled.div`
  width:232px;
  height:44px;
  font-size:14px;
  font-weight:400;
  color:rgba(0,0,0,0.45);
  line-height:22px;
  margin-top:12px;
  text-overflow: -o-ellipsis-lastline;  
  overflow: hidden;  
  text-overflow: ellipsis;  
  display: -webkit-box;  
  -webkit-line-clamp: 2;  
  -webkit-box-orient: vertical;  
`

export const Bottom = styled.div`
  display: flex;
  height: 48px;
  width: 100%;
  text-align: center;
  align-items: center;
  position: absolute;
  background: #F7F9FA;
  border-top: 1px solid rgba(233,233,233,1);
  bottom: 0;
  left: 0;
`

export const BottomButton = styled.div`
  text-align: center;
  color: rgba(0, 0, 0, 0.45);
  width: 100%;
`

export const Space = styled.div`
  width: 1px;
  height: 16px;
  background: #E8E8E8;
`