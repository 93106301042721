/*
 * @Author: your name
 * @Date: 2020-10-23 15:56:45
 * @LastEditTime: 2020-10-29 16:47:07
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/consts/url.ts
 */
export const ossURL = 'http://storage.joyreserve.com/Public/phoenix'
export const OSS_SIGN = 'https://cqp-sign.joyreserve.com'
