import styled from "styled-components"

export const Body = styled.div`
  margin:0 0 24px 24px;
  width: 363px;
  height: 184px;
  background: rgba(255,255,255,1);
  border-radius: 2px;
  border: 1px solid rgba(233,233,233,1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  :hover {
    box-shadow: 0 0 2px 2px rgba(233,233,233,1);
  }
`

export const Text = styled.div`
  user-select: none;
  margin-left: 10px;
`