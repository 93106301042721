import { Tabs } from "antd"
import { Block, Header } from "components"
import React, { FC, useState } from "react"
import { BreadcrumbOption } from "types/antd"
import WeChat from "./WeChat"

const Empower: FC = () => {
  const { TabPane } = Tabs
  const [breadcrumbs] = useState<BreadcrumbOption[]>([{
    name: "系统配置"
  }, {
    name: "第三方平台配置"
  }])

  return (
    <div>
      <Header
        title="第三方平台配置"
        description=""
        breadcrumbs={breadcrumbs}
      />
      <Block> 
        <Tabs defaultActiveKey="1">
          <TabPane tab="&nbsp;微信公众号&nbsp;" key="1">
            <WeChat />
          </TabPane>
        </Tabs>
      </Block>
    </div>
  )
}

export default Empower
