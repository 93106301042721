import { UserOutlined } from '@ant-design/icons'
import { Avatar, Button, Col, Descriptions, Input, message, Modal, Popconfirm, Row, Select, Switch, Table } from 'antd'
import { Block, InputBox } from 'components'
import { resourceColumn } from 'consts/administrators'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import administrator, { AdministratorInfo } from 'services/administrator'
import item from 'services/item'
import { Column } from 'types/administrator'
import { Name } from './style'

const grades: AnyObject = {
  'null':'超级管理员', 
  '1': '普通管理员', 
  '2': '外部协助人员'
}

const Detail: FC = () => {
  const { Option } = Select
  const { id } = useParams<{ id: string }>()
  const { Item } = Descriptions
  const history = useHistory()
  const [display, setDisplay] = useState<string>("display")
  const [data, setData] = useState<AdministratorInfo>()
  const [onlineData, setOnlineData] = useState<AdministratorInfo>()
  const [resourceList, setResourceList] = useState<{ id: number, name: string }[]>()
  const [selectResourceColumn, setSelectResourceColumn] = useState<Column[]>()
  const [render, setRender] = useState<number>(1)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  useEffect(() => {
    (async () => {
      const data = await item.getSummaryList()
      setResourceList(data)
    })()
  }, [])

  useEffect(() => {
    (async () => {
      const data = await administrator.getAdministrator({ id })
      setData(data)
      setOnlineData(data)
    })()
  }, [render, id])

  useEffect(() => {
    if (!resourceList || !data) return
    setSelectResourceColumn(handleResourceList(data.resource_id_list))
  }, [data, resourceList, display])

  const handleSubmit = useCallback(async () => {
    try {
      await administrator.editAdministrator({ ...data, id })
      message.success("修改成功")
      setDisplay("display")
      setRender(render + 1)
    } catch (err) {
      err.response && message.error(err.response.data.message)
    }
  }, [render, data, id])

  const handleCancel = useCallback(async () => {
    setData(onlineData)
    setDisplay("display")
  }, [onlineData])

  const handleDelete = useCallback(async () => {
    try {
      await administrator.delAdministrator({ id: Number(id) })
      history.push('/administrators/list')
      message.success("删除成功")
    } catch (err) {
      err.response && message.error(err.response.data.message)
    }
  }, [render, id])

  const handleResourceList = useCallback((idList: number[]) => {
    const list: Column[] = []
    if (resourceList && resourceList.length) {
      resourceList.forEach(item => {
        idList.some(id => id === item.id) && list.push({
          ...item, handle:
            <div>
              <Link to="">
                <Button type="link" style={{ paddingLeft: 0 }}>详情</Button>
              </Link>
              {display === "edit" ? <Button type="link" onClick={() => handleChangeResourceList(item.id)}>删除</Button> : null}
            </div>
        })
      })
    }
    return list
  }, [resourceList, display, data])

  const handleChangeResourceList = useCallback((id: number | number[]) => {
    const newData = JSON.parse(JSON.stringify(data))
    if (typeof id === "number") {
      newData.resource_id_list.splice(newData.resource_id_list.findIndex((item: number) => item === id), 1)
    } else {
      newData.resource_id_list = id
    }
    setData(newData)
  }, [data, resourceList])

  return (
    <React.Fragment>
      <Block
        loading={isLoading}
        title="管理员详情"
        showBack
        description={
          <div>
            {data && !data.is_admin &&
              <Popconfirm
                placement="left"
                title={`确认删除管理员 ?`}
                onConfirm={handleDelete}
              >
                <Button style={{ marginRight: 8 }}>删除管理员</Button>
              </Popconfirm>
            }
            {display === "display" ?
              <Button onClick={() => setDisplay("edit")}>编辑</Button> :
              <span>
                <Button onClick={handleCancel} style={{ marginRight: 8 }}>取消</Button>
                <Button onClick={handleSubmit} type="primary">保存</Button>
              </span>
            }
          </div>
        }
      >
        <Row>
          <Col span={2}>
            <Avatar
              size={64}
              icon={<UserOutlined />}
              style={{
                marginTop: 24,
              }}
            />
          </Col>
          {display === "display" ?
            <Col span={22}>
              <Name>{data?.name}</Name>
              <Descriptions style={{ marginTop: 15 }}>
                <Item label="账号">{data?.account}</Item>
                <Item label="联系方式">
                  {data?.phone}
                </Item>
                <Item label="微信通知">
                  <Switch checkedChildren="开启" unCheckedChildren="关闭" checked={data?.is_use_wechat_message} />
                </Item>
                <Item label="管理员权限">
                  {grades[data ? String(data.grade) : 'null']}
                </Item>
                <Item label="备注">
                  {data?.description}
                </Item>
              </Descriptions>
            </Col>
            : <Col span={22}>
              <Name>
                <Input
                  value={data?.name}
                  size="small"
                  style={{ width: 200 }}
                  onChange={(e) => { setData({ ...data!, name: e.target.value }) }}
                />
              </Name>
              <Descriptions style={{ marginTop: 15 }}>
                <Item label="账号">
                  <Input
                    value={data?.account}
                    size="small"
                    style={{ width: 200 }}
                    onChange={(e) => { setData({ ...data!, account: e.target.value }) }}
                  />
                </Item>
                <Item label="联系方式">
                  <Input
                    value={data?.phone}
                    size="small"
                    type="number"
                    style={{ width: 200 }}
                    onChange={(e) => { setData({ ...data!, phone: Number(e.target.value) }) }}
                  />
                </Item>
              </Descriptions>
              <Descriptions>
                {data && !data.is_admin &&
                  <Item label="管理员权限">
                    <Select value={data?.grade} onChange={value => setData({ ...data!, grade: value })}>
                      <Select.Option value={1}>普通管理员</Select.Option>
                      <Select.Option value={2}>外部协助人员</Select.Option>
                    </Select>
                  </Item>
                }
                <Item label="微信通知">
                  <Switch
                    checkedChildren="开启"
                    unCheckedChildren="关闭"
                    checked={data?.is_use_wechat_message}
                    onChange={(value) => { setData({ ...data!, is_use_wechat_message: value }) }}
                  />
                </Item>
              </Descriptions>
              <Descriptions>
                <Item label="备注">
                  <Input.TextArea
                    value={data?.description}
                    onChange={(e) => { setData({ ...data!, description: e.target.value }) }}
                  />
                </Item>
              </Descriptions>
            </Col>}
        </Row>
      </Block>
      <Block title="项目权限" loading={isLoading}>
        {display === "edit" ?
          <Button
            type="primary"
            onClick={() => { setIsOpen(true) }}
            style={{ marginTop: 24 }}
          >
            修改项目
        </Button>
          : null
        }
        <Table
          dataSource={selectResourceColumn}
          columns={resourceColumn}
          style={{ marginTop: 24 }}
          pagination={false}
        />
      </Block>
      <Modal
        visible={isOpen}
        onCancel={() => { setIsOpen(false) }}
        title="管理员预约项目管理权限"
        footer={null}
      >
        <InputBox title="预约项目">
          <Select
            value={selectResourceColumn?.map(item => item.id)}
            mode="multiple"
            placeholder="请选择预约项目(可多选)"
            style={{ width: 240 }}
            onChange={handleChangeResourceList}
          >
            {
              resourceList && resourceList.length ? resourceList.map(item => (
                <Option key={item.id} value={item.id}>{item.name}</Option>
              )) : null
            }
          </Select>
        </InputBox>
      </Modal>
    </React.Fragment >
  )
}

export default Detail