import React, { FC, useCallback, useEffect, useState } from 'react'
import { Form, Input, message, Modal, Select, Typography } from 'antd'
import services from 'services'
import { FieldItem } from 'types/register'

function renderField (field: FieldItem)  {
  switch (field.type) {
    case 1:
    case 1001: {
      return (
        <Form.Item key={field.id} label={field.title} name={field.id}>
          <Input />
        </Form.Item>
      )
    }
    case 2: {
      return (
        <Form.Item key={field.id} label={field.title} name={field.id}>
          <Select>
            {field.content.map((option,index) =>
              <Select.Option key={index} value={option}>{option}</Select.Option>
            )}
          </Select>
        </Form.Item>
      )
    }
    case 3: {
      return (
        <Form.Item key={field.id} label={field.title} name={field.id}>
          <Select mode="multiple">
            {field.content.map((option,index) =>
              <Select.Option key={index} value={option}>{option}</Select.Option>
            )}
          </Select>
        </Form.Item>
      )
    }
  }
}

interface Props {
  visible: boolean,
  onCancel: () => void,
  onOk: () => void
}

const AddUser: FC<Props> = (props) => {
  const { visible, onCancel, onOk } = props
  const [form] = Form.useForm()
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [fieldList, setFieldList] = useState<FieldItem[]>([])

  const handleCancel = useCallback(() => {
    form.resetFields()
    onCancel()
  }, [])

  const handleOk = useCallback(() => {
    form.validateFields()
      .then((data) => {
        const systemFields = {
          username: data.username,
          phone: data.phone,
          password: data.password || '123456'
        }
        const customFields: AnyObject = {} 
        Object.keys(data)
          .filter(key => Object.keys(systemFields).indexOf(key) === -1)
          .forEach(key => customFields[key] = data[key])
        
        setConfirmLoading(true)
        services.user.create({ users: [{ ...systemFields, custom: customFields }] })
          .then(() => {
            message.success(`用户“${data.username}”添加成功`)
            onOk()
            handleCancel()
          })
          .catch(() => {
            message.error(`用户“${data.username}”添加失败`)
          })
          .finally(() => {
            setConfirmLoading(false)
          })
      })
      .catch(() => {
        message.warning('请正确填写表单')
      })
  }, [])

  useEffect(() => {
    form.setFieldsValue({
      password: '123456'
    })
  }, [visible])

  useEffect(() => {
    services.register.getList()
      .then(data => {
        setFieldList(data)
      })
  }, [])
  
  return (
    <Modal
      title="添加用户"
      visible={visible}
      okText="确认"
      cancelText="重置"
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      onOk={handleOk}
    >
      <Form
        name="item"
        form={form}
        validateMessages={{ required: "'${label}' 不能为空" }}
        preserve={false}
      >
        <Form.Item
          label="用户姓名"
          name="username"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="联系方式"
          name="phone"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="登录密码"
          name="password"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Typography.Title level={5} style={{ fontSize: 14 }}>自定义注册信息: </Typography.Title>
        {fieldList.filter(field => field.id && field.is_show).map(field => renderField(field))}
      </Form>
    </Modal>
  )
}

export default AddUser
