import React, { FC } from "react"
import { PlusOutlined } from "@ant-design/icons"
import { Body, Text } from "./style"


interface AddType {
  onClick: () => void
}

const Add: FC<AddType> = (props) => {
  return (
    <Body onClick={props.onClick}>
      <PlusOutlined size={12} />
      <Text>新增时间段设置</Text>
    </Body>
  )
}

export default Add