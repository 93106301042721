import React, { FC, useState, useCallback, CSSProperties } from "react"
import { Modal, Button, Input, message } from "antd"
import { InputBox } from "components"
import { useSelector, useDispatch } from "react-redux"
import { Dispatch, RootState } from "src/store"
import { userLogin } from "services"
import { userLoginVac } from "utils/vac"
import { useHistory } from "react-router"

const LoginModel: FC = () => {
  const history = useHistory()
  const { userLogin: { isOpen }, app: { render } } = useSelector((state: RootState) => state)
  const userLoginDispatch = useDispatch<Dispatch>().userLogin
  const appDispatch = useDispatch<Dispatch>().app
  const [username, setUsername] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(false)

  const submit = useCallback(async () => {
    try {
      if (userLoginVac(username, password)) return
      setLoading(true)
      const data = await userLogin.getToken({ username, password })
      localStorage.setItem("token", data.token)
      userLoginDispatch.setIsOpen(false)
      appDispatch.setRender(render + 1)
      message.success('欢迎回来，管理员')
    } catch (err) {
      message.error(err.response.data.message)
    } finally {
      setLoading(false)
    }
  }, [username, password, render])

  const inputBoxStyle: CSSProperties = {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: 90
  }

  return (
    <Modal
      title="登录"
      visible={isOpen}
      footer={
        <div>
          <Button
            loading={loading}
            disabled={loading}
            type="primary"
            onClick={submit}
          >
            登录
          </Button>
        </div >
      }
      closable={false}
      maskClosable={false}
      keyboard={false}
    >
      <InputBox style={inputBoxStyle} title="用户名">
        <Input
          disabled={loading}
          style={{ width: 250 }}
          value={username}
          onChange={(e) => { setUsername(e.target.value) }}
        />
      </InputBox>
      <InputBox style={{ ...inputBoxStyle, marginTop: "15px" }} title="密码">
        <Input.Password
          disabled={loading}
          style={{ width: 250 }}
          value={password}
          onChange={(e) => { setPassword(e.target.value) }}
        />
      </InputBox>
    </Modal >
  )
}

export default LoginModel
