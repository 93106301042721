/*
 * File: /src/pages/Reserve/View/Platform/Detail.tsx
 * Author: Mxsyx (zsimline@163.com)
 * Create Time: Monday 2020-11-16 02:17:05
 * Last Modified: Monday 2020-11-16 02:17:05
 * Modified By: Mxsyx (zsimline@163.com>)
 * 
 * Copyright © 2020 https://www.joyreserve.com
 */
import React, { FC, Fragment, useCallback, useEffect, useState } from 'react'
import { Button, Col, Divider, Form, Input, message, Modal, Row, Select, Spin } from 'antd'
import { Block } from 'components'
import services from 'services'
import { Rule, Topic } from 'types/platform'
import { DeleteOutlined } from '@ant-design/icons'
import { useParams } from 'react-router-dom'
import { CustomOrderType, Entry, RoughCustomOrderItemType } from 'types/system'

function findPropById(obj: any[], id: string | number, propName: string = 'name') {
  const index = obj.findIndex(o => o.id == id)
  return index !== -1 ? obj[index][propName] : undefined
}

interface Props {
  index: number,
  rule: Rule,
  topics: Entry[],
  onChange: (cancel?: boolean) => void
}
const RuleItem: FC<Props> = (props) => {
  const platformId = parseInt(useParams<{ platformId: string }>().platformId)

  const [loading, setLoading] = useState<boolean>(false)
  const [visible, setVisible] = useState<boolean>(props.rule.id === 0)
  const [form] = Form.useForm()

  useEffect(() => {
    props.topics.forEach(topic => {
      const value = findPropById(props.rule.topics, topic.id as number, 'value')
      form.setFieldsValue({ [topic.id as number]: value })
    })
  }, [props, form])

  const handleDelete = useCallback(() => {
    setLoading(true)
    services.platform.deleteRule(platformId, props.rule.id)
      .then(() => {
        message.success('删除月台规则成功！')
        props.onChange()
      })
      .catch(() => {
        message.error('删除月台规则失败~')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [props, platformId])

  const handleSubmit = useCallback(() => {
    setVisible(false)
    const operation = props.rule.topics.length ? '更新' : '新增'
    form.validateFields()
      .then((data: any) => {
        setLoading(true)
        const _topics: Topic[] = []
        props.topics.forEach(topic => {
          _topics.push({
            id: topic.id as number,
            name: topic.name,
            value: data[topic.id as number]
          })
        })

        const postData = { topics: _topics }
        const promise = operation === '更新'
          ? services.platform.updateRule(platformId, props.rule.id, postData)
          : services.platform.createRule(platformId, postData)
        promise
          .then(() => {
            message.success(`${operation}月台规则成功`)
            props.onChange()
          })
          .catch(() => {
            message.error(`${operation}月台规则失败`)
          })
          .finally(() => {
            setLoading(false)
          })
      })
      .catch(() => {
        message.warning('请正确填写表单！')
      })
  }, [props, form, platformId])

  return (
    <Spin spinning={loading}>
      <Row align="middle" style={{ marginTop: 25 }}>
        <Col span={4} style={{ fontWeight: 800, marginBottom: 15 }}>月台规则{props.index}</Col>
        <Col>
          <DeleteOutlined style={{ color: '#DDD', cursor: 'pointer' }} onClick={handleDelete} />
        </Col>
      </Row>
      <Row gutter={[30, 10]}>
        {props.rule.topics.map(topic =>
          <Col key={topic.id} style={{ fontSize: 14 }}>{topic.name}: {topic.value}</Col>
        )}
      </Row>
      <Row justify="end">
        <Button onClick={() => setVisible(true)}>编辑</Button>
      </Row>
      <Modal
        visible={visible}
        title={`编辑月台规则${props.index}`}
        okText="确认"
        cancelText="取消"
        onCancel={() => {
          props.onChange(true)
          setVisible(false)
        }}
        onOk={handleSubmit}
      >
        <Form form={form}>
          {props.topics.map(topic =>
            <Form.Item key={topic.id} label={topic.name} name={`${topic.id}`}>
              <Select>
                {topic.options && topic.options?.map((option, index) =>
                  <Select.Option key={index} value={option}>{option}</Select.Option>
                )}
              </Select>
            </Form.Item>
          )}
          <Form.Item></Form.Item>
        </Form>
      </Modal>
      <Divider />
    </Spin>
  )
}

const Detail: FC = () => {
  const platformId = parseInt(useParams<{ platformId: string }>().platformId)

  const [loading, setLoading] = useState<boolean>(false)
  const [ruleList, setRuleList] = useState<Rule[]>([])
  const [operation, setOperation] = useState<'create' | 'update'>('create')
  const [form] = Form.useForm()
  const [papers, setPapers] = useState<RoughCustomOrderItemType[]>([])
  const [paper, setPaper] = useState<CustomOrderType>()

  useEffect(() => {
    if (platformId !== 0) setOperation('update')
  }, [])

  // 加载试卷列表
  useEffect(() => {
    services.system.getCustomOrderList()
      .then((data) => {
        setPapers(data)
      })
      .catch(() => {
        message.warning('加载可关联的试卷列表失败~')
      })
  }, [])

  useEffect(() => {
    if (operation === 'create' || !papers) return
    setLoading(true)
    // 加载月台基本信息
    services.platform.getPlatform(platformId)
      .then((data) => {
        form.setFieldsValue({
          name: data.name,
          paper_id: findPropById(papers, data.paper_id) as number
        })
        const index = papers.findIndex(paper => paper.id == data.paper_id)
        if (index !== -1) {
          // 加载试卷题目列表
          services.system.getCustomOrder({ id: papers[index].id })
            .then((data) => {
              setPaper(data)
            })
            .catch(() => {
              message.error('加载试卷题目列表失败~')
            })
        }
      })
      .catch(() => {
        message.error('获取月台基本信息失败~')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [operation, papers])

  const loadList = useCallback(() => {
    if (operation === 'create') return
    setLoading(true)
    services.platform.getRuleList(platformId)
      .then((data) => {
        setRuleList(data)
      })
      .catch(() => {
        message.error('获取月台规则列表失败~')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [operation])

  const handleCreate = useCallback(async () => {
    if (operation === 'create' || platformId === 0 || !paper) {
      message.warning('请先选择关联的订单信息，并点击保存。')
      return
    }
    ruleList.push({ id: 0, topics: [] })
    setRuleList([...ruleList])
  }, [ruleList, operation, papers, paper])

  const handleSubmit = useCallback(() => {
    if (ruleList.length > 0) {
      message.warning('变更关联的订单信息之前，请先删除该月台下的所有规则。')
      return
    }

    form.validateFields()
      .then((data) => {
        setLoading(true)
        const postData = { name: data.name, paper_id: data.paper_id }
        const promise = operation === 'create'
          ? services.platform.createPlatform(postData)
          : services.platform.updatePlatform(platformId, postData)
        promise
          .then((data) => {
            if (operation === 'create') {
              window.location.href = `/reserve/platform/detail/${data.id}`
            }
            message.success(`更新月台基本信息成功！`)
          })
          .catch(() => {
            message.error('更新月台基本信息失败~')
          })
          .finally(() => {
            setLoading(false)
          })
      })
      .catch(() => {
        message.warning('请正确填写表单！')
      })
  }, [ruleList])

  useEffect(loadList, [operation])

  return (
    <Fragment>
      <Block
        title="月台管理配置"
        loading={loading}
        showBack
        description={<Button type="primary" onClick={handleSubmit}>保存</Button>}
      >
        <Form form={form} validateMessages={{ required: "'${label}' 不能为空" }}>
          <Form.Item name="name" label="月台名称" rules={[{ required: true }]}>
            <Input placeholder="请输入月台名" style={{ width: 300 }} />
          </Form.Item>
          <Form.Item name="paper_id" label="关联订单信息" rules={[{ required: true }]}>
            <Select placeholder="请选择" style={{ width: 300 }}>
              {papers.map(paper =>
                <Select.Option key={paper.id} value={paper.id}>{paper.name}</Select.Option>
              )}
            </Select>
          </Form.Item>
        </Form>
      </Block>
      <Block title="月台规则" loading={loading}>
        <Button type="primary" onClick={handleCreate} >添加月台规则</Button>
        {ruleList.map((rule, index) =>
          <RuleItem
            key={index}
            index={index + 1}
            rule={rule}
            topics={paper && paper.entry_list.filter(entry => entry.type === 'single') || []}
            onChange={cancel => cancel ? setRuleList(ruleList.slice(0,-1)) : loadList()}
          />
        )}
      </Block>
    </Fragment>
  )
}

export default Detail
