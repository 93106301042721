/*
 * File: /src/pages/Reserve/View/Platform/List.tsx
 * Author: Mxsyx (zsimline@163.com)
 * Create Time: Monday 2020-11-16 02:15:53
 * Last Modified: Monday 2020-11-16 02:15:53
 * Modified By: Mxsyx (zsimline@163.com>)
 * 
 * Copyright © 2020 https://www.joyreserve.com
 */
import { Button, Col, Divider, message, Row, Table } from 'antd'
import { Block, Header } from 'components'
import React, { FC, Fragment, useCallback, useEffect, useState } from 'react'
import services from 'services'
import { BreadcrumbOption } from 'types/antd'
import { Platform } from 'types/platform'

const breadcrumbs: BreadcrumbOption[] = [{
  name: "预约管理"
}, {
  name: "月台管理"
}]

const columns = [{
  key: 'name',
  dataIndex: 'name',
  title: '月台名称',
  width: 140
}, {
  key: 'rules',
  dataIndex: 'rules',
  title: '月台规则'
}, {
  key: 'operation',
  dataIndex: 'operation',
  title: '操作',
  width: 160
}]

const List: FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [list, setList] = useState<any[]>([])

  const handleData = useCallback((data: Platform[]) => {
    return data.map(item => {
      return {
        key: item.id,
        name: item.name,
        rules:
          <Row>
            {item.rules.map((rule, index) =>
              <Col span={24}>
                月台规则{index+1} {rule.topics.map(topic => ` ${topic.name}:${topic.value} `)}
              </Col>
            )}
          </Row>,
        operation:
          <Fragment>
            <Button 
              type="link"
              style={{ padding: 0 }} 
              onClick={() => window.location.href = `/reserve/platform/detail/${item.id}`}
            >编辑</Button>
            <Divider type="vertical" style={{ backgroundColor: '#30A694' }} />
            <Button 
              type="link" 
              style={{ padding: 0 }} 
              onClick={() => handleDelete(item.id)}
            >删除</Button>
          </Fragment>
      }
    })
  }, [])

  const loadList = useCallback(() => {
    setLoading(true)
    services.platform.getPlatformList()
      .then((data) => {
        setList(handleData(data))
      })
      .catch(() => {
        message.error('获取月台列表失败~')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const handleDelete = useCallback((id: number) => {
    setLoading(true)
    services.platform.deletePlatform(id)
      .then(() => {
        loadList()
      })
      .catch(() => {
        message.error('删除月台失败~')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  useEffect(loadList, [])

  return (
    <Fragment>
      <Header
        title="月台管理"
        description="设置系统根据用户提交的货物种类、件数等相关订单信息自动判断并分配相应月台。"
        breadcrumbs={breadcrumbs}
      />
      <Block loading={loading}>
        <Button 
          type="primary"
          onClick={() => window.location.href= '/reserve/platform/detail/0'}
        >新增月台</Button>
        <Table 
          columns={columns} 
          dataSource={list} 
          style={{ marginTop: 15 }}
        />
      </Block>
    </Fragment>
  )
}

export default List
