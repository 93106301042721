/*
 * @Author: your name
 * @Date: 2020-10-10 12:17:36
 * @LastEditTime: 2020-10-10 19:20:06
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/components/WeekTable/style.ts
 */

import styled from 'styled-components'

export const TodayButtonStyle = {
  width: "65px",
  borderRadius: "4px",
}

export const Header = styled.div`
  display: flex;
  position: relative;
`

export const DateBorder = styled.div`
  display: flex;
  height: 34px;
  position: relative;
  z-index: 3;
  background: white;
  align-items: center;
`

export const DateBox = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  background: white;
  z-index: 3;
  height: 34px;
`

export const DateTime = styled.div`
  width: 130px;
  text-align: center;
  height:22px;
  font-size:  14px;
  color: rgba(0,0,0,0.85);
  line-height: 22px;
  font-weight: 600;
`

export const Week = styled.div`
`

export const Table = styled.div`
  margin-top: 30px;
`

export const WeekList = styled.div`
  position: relative;
  display: flex;
  margin-left: 35px;
  margin-top: 35px;
  width:1050px;
`

export const WeekBox = styled.div`
  position: relative;
  width: 149px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

export const WeekText = styled.div`
  width: 21px;
  height: 17px;
  font-size: 12px;
  color: rgba(0,0,0,0.45);
  line-height: 14px;
  display: flex;
  justify-content: center;
`

export const WeekDate = styled.div`
  width: 32px;
  height: 32px;
  font-size: 16px;
  text-align: center;
  line-height: 32px;
  color: rgba(0,0,0,0.85);
  font-weight: 600;
`

export const ActiveWeekDate = styled.div`
  width: 32px;
  height: 32px;
  font-size: 16px;
  text-align: center;
  line-height: 32px;
  color: rgba(0,0,0,0.85);
  font-weight: 600;
  border-radius: 50%;
  background: #30A694;
  color: white;
`
