/*
 * File: /src/pages/System/View/Attend/Qrcode.tsx
 * Author: Mxsyx (zsimline@163.com)
 * Create Time: Monday 2020-11-09 03:59:58
 * Last Modified: Monday 2020-11-09 03:59:58
 * Modified By: Mxsyx (zsimline@163.com>)
 * 
 * Copyright © 2020 https://www.joyreserve.com
 */
import React, { FC, useState, useCallback, useEffect } from 'react'
import { Button, message, Space } from 'antd'
import services from 'services'
import { Block } from 'components'
import { ReloadOutlined } from '@ant-design/icons'

const QRCode: FC = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [url, setUrl] = useState<string>('')

  // 处理刷新二维码
  const handleRefresh = useCallback(() => {
    setLoading(true)
    services.attend.refreshQRCode({ size: 200 })
      .then(data => {
        setUrl(data.code)
      })
      .catch(() => {
        message.error('获取二维码失败~')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  // 处理下载二维码
  const handleDownload = useCallback(() => {
    const dom = document.getElementById('download')
    dom && dom.click()
  }, [])

  useEffect(() => {
    services.attend.getQRCode({ size: 200 })
      .then(data => {
        setUrl(data.code)
      })
      .catch(() => {
        message.error('获取二维码失败~')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return (
    <Block loading={loading} style={{ textAlign: 'center', padding: '32px 0px' }}>
      <img src={url} alt="" />
      <a id="download" href={url} download></a>
      <Button
        type="link"
        onClick={handleRefresh}
        style={{ display: 'block', margin: '8px auto', }}
      >
        <ReloadOutlined />刷新二维码
      </Button>
      <Button type="primary" onClick={handleDownload}>下载二维码</Button>
    </Block>
  )
}

export default QRCode
