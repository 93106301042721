import React, { FC, useState, useEffect, ReactNode, useCallback } from 'react'
import { Block } from 'components'
import { time } from 'services'
import { useParams } from 'react-router'
import WeekTable from 'components/WeekTable'
import { BoxItem, BoxItemBorder, Right, Text } from './style'
import { Button, message, Tooltip } from 'antd'
import EditPanel from '../Model/Panel/EditPanel'
import SyncPanel from '../Model/Panel/SyncPanel'
import { EditDefaultType, NewDefaultType, weekItem } from 'types/time'
import { TimeListType } from 'services/time'
import Item from '../Model/Item'
import { FormatDay, handleDateToMin } from 'utils/time'
import QuickPanel from '../Model/Panel/QuickPanel'
import { getWeekTableRange } from 'components/WeekTable/utils'
import NewPanel from '../Model/Panel/NewPanel'

const Detail: FC = () => {
  const { cardId } = useParams<{ cardId: string }>()
  const [name, setName] = useState<string>('加载中')

  useEffect(() => {
    cardId && (async () => {
      const data = await time.getCardList()
      setName(data.filter(item => item.id === Number(cardId))[0].name)
    })()
  }, [cardId])

  const now = new Date()
  const [date, setDate] = useState<Date>(now)
  const [weekList, setWeekList] = useState<weekItem[]>([])
  const [newPanelIsOpen, setNewPanelIsOpen] = useState<boolean>(false)
  const [newDefaultValue, setNewDefaultValue] = useState<NewDefaultType>()
  const [editPanelIsOpen, setEditPanelIsOpen] = useState<boolean>(false)
  const [editDefaultValue, setEditDefaultValue] = useState<EditDefaultType>()
  const [syncPanelIsOpen, setSyncPanelIsOpen] = useState<boolean>(false)
  const [quickPanelIsOpen, setQuickPanelIsOpen] = useState<boolean>(false)
  const [scrollTop, setScrollTop] = useState<number>(590)
  const [loading, setIsLoading] = useState<boolean>(false)
  const [data, setData] = useState<TimeListType[]>([])
  const [render, setRender] = useState<number>(1)

  //渲染时间格子
  const renderBoxList = useCallback(() => {
    const result: ReactNode[] = []
    for (let index = 0; index < 24; index++) {
      weekList.forEach(item => {
        result.push(
          <Item
            key={'box' + `${item.year}-${item.month}-${item.day}` + index}
            date={`${item.year}-${item.month}-${item.day}`}
            time={`${index < 10 ? `0${index}` : index}:00`}
            onClick={(data: NewDefaultType) => {
              setNewPanelIsOpen(true)
              setNewDefaultValue(data)
            }}
          >
          </Item>
        )
      })
    }
    return result
  }, [weekList])

  //渲染已存在的时间格子
  const renderItemList = useCallback(() => data.map(item => {
    const startDate = new Date(item.start_time * 1000)
    const endDate = new Date(item.end_time * 1000)
    const week = startDate.getDay()
    const startTime = handleDateToMin(startDate)
    const time = handleDateToMin(endDate) - startTime
    let content: ReactNode[] = []
    for (let name in item.content) {
      content.push(<Text>{name}:&nbsp;{item.content[name]}</Text>)
    }
    return (
      <Tooltip key={item.id + item.start_time} placement="right" title={content} style={{ color: 'white' }}>
        <BoxItemBorder style={{
          height: `${getWeekTableRange(time) - 3}px`,
          width: `146px`,
          left: `${week * 148 - 1}px`,
          top: `${getWeekTableRange(startTime) - 2}px`,
        }}
        >
          <BoxItem onClick={() => {
            setEditDefaultValue(
              {
                defaultTimeId: item.id,
                defaultDate: FormatDay(startDate),
                isEdit: item.is_edit
              }
            )
            setEditPanelIsOpen(true)
          }}
            style={{
              background: item.disable ? '#D4ECE5' : '#F7F7F7',
              color: item.disable && item.is_edit ? '#333' : 'rgba(0, 0, 0, 0.25)'
            }}
          >
            {content}
          </BoxItem>
        </BoxItemBorder>
      </Tooltip>
    )
  }), [data])

  //计算时间格子生成后移动高度
  useEffect(() => {
    if (!data || !data.length) return
    let min = 590
    data.forEach(item => {
      const date = new Date(item.start_time * 1000)
      const startTime = date.getHours() * 60 + date.getMinutes()
      if (min > getWeekTableRange(startTime)) min = getWeekTableRange(startTime)
    })
    setScrollTop(min - 1 + Math.random())
  }, [data])

  //请求时间格子
  useEffect(() => {
    cardId && weekList.length && (async () => {
      const day = weekList[1]
      setIsLoading(true)
      setData([])
      const data = await time.getTimeList({
        resource_id: Number(cardId),
        date: FormatDay(new Date(`${day.year}-${day.month}-${day.day}`))
      })
      setIsLoading(false)
      setData(data)
    })()
  }, [cardId, weekList, render])

  return (
    <React.Fragment>
      <Block title={name} style={{ width: '1150px' }}>
        <React.Fragment>
          <WeekTable
            now={now}
            headerStyle={{ marginTop: 30 }}
            scrollTop={scrollTop}
            date={date}
            loading={loading}
            setDate={(payload) => { setDate(payload) }}
            renderNoneBoxList={renderBoxList}
            getWeekList={(weekList) => setWeekList(weekList)}
            customHeader={
              <Right>
                <Button onClick={() => { setQuickPanelIsOpen(true) }}>快速生成</Button>
                <Button onClick={() => { setSyncPanelIsOpen(true) }}>一键同步</Button>
              </Right>
            }
          >
            {renderItemList()}
          </WeekTable>
          <NewPanel
            isOpen={newPanelIsOpen}
            onClose={() => setNewPanelIsOpen(false)}
            defaultDate={newDefaultValue?.defaultDate || ""}
            defaultTime={newDefaultValue?.defaultTime || ""}
            onSubmit={() => {
              setNewPanelIsOpen(false)
              setRender(render + 1)
              message.success('新增成功')
            }}
          />
          <EditPanel
            isOpen={editPanelIsOpen}
            onClose={() => setEditPanelIsOpen(false)}
            defaultTimeId={editDefaultValue?.defaultTimeId || 0}
            defaultDate={editDefaultValue?.defaultDate || ""}
            defaultIsEdit={!!editDefaultValue?.isEdit}
            onSubmit={() => {
              setEditPanelIsOpen(false)
              setRender(render + 1)
              message.success('修改成功')
            }}
          />
          <SyncPanel
            isOpen={syncPanelIsOpen}
            onClose={() => setSyncPanelIsOpen(false)}
            onSubmit={() => {
              setSyncPanelIsOpen(false)
              message.success('同步成功')
            }}
          />
          <QuickPanel
            isOpen={quickPanelIsOpen}
            onClose={() => setQuickPanelIsOpen(false)}
            onSubmit={() => {
              setQuickPanelIsOpen(false)
              setRender(render + 1)
              message.success('新增成功')
            }}
          />
        </React.Fragment>
      </Block>
    </React.Fragment >
  )
}

export default Detail