import React, { FC, Fragment } from 'react'
import { Route } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Header } from 'components'
import List from './View/List'

const Item: FC = () => {
  const { t } = useTranslation()

  return (
    <div>
      <Route path='/item/list' render={() =>
        <Fragment>
          <Header
            title={t("page_item_title")}
            description={t("page_item_description")}
          />
          <List />
        </Fragment>
      } />
    </div>
  )
}

export default Item
