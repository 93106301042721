import React, { FC, useState, Fragment, useCallback, useEffect } from 'react'
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Table, Button, message, Divider, Drawer, Input, Form, Switch, Spin, Radio, Select, Modal } from 'antd'
import services from 'services'
import { Block } from 'components'
import { columns } from 'consts/notice'
import { Notice } from 'types/notice'
import RichText from 'components/RichText'
import TextArea from 'antd/lib/input/TextArea'

const cycles: { [index: number]: string } = {
  0: '一直显示',
  1440: '每天提醒一次',
  5: '5分钟内不重复提示',
  10: '10分钟内不重复提示',
  30: '30分钟内不重复提示'
}

const List: FC = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [list, setList] = useState<any[]>([])
  const [notices, setNotices] = useState<Notice[]>([])
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(20)
  const [total, setTotal] = useState<number>(0)
  const [activeIndex, setActiveIndex] = useState<number>(-1)
  const [operation, setOperation] = useState<'add' | 'update'>('add')
  const [visible, setVisible] = useState<boolean>(false)
  const [selectVisible, setSelectVisible] = useState<boolean>(false)
  const [form] = Form.useForm()

  const handleData = useCallback((data: Notice[]) => {
    return data.map((item, index) => ({
      key: index,
      id: item.id,
      title: item.title,
      open: item.open ? '是' : '否',
      cycle: item.rule === 'A' ? cycles[item.cycle] : '每天提醒一次',
      rule: item.rule,
      content: item.content,
      operation: <Fragment>
        <Button type="link" onClick={() => handleUpdate(index)}>编辑</Button>
        <Divider type="vertical" />
        <Button type="link" onClick={() => handleDelete(item.id)}>删除</Button>
      </Fragment>
    }))
  }, [])

  const loadList = useCallback(() => {
    services.notice.getList({page, size: pageSize})
      .then(data => {
        setNotices(data.list)
        setList(handleData(data.list))
        setTotal(data.total)
      })
      .catch(() => {        
        message.error('获取预约公告列表失败')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [page, pageSize])

  useEffect(loadList, [page, pageSize])

  const handleAdd = useCallback(() => {
    setOperation('add')
    setVisible(true)
    setActiveIndex(-1)
  }, [])

  const handleDelete = useCallback((id: number) => {
    Modal.confirm({
      title: '确认要删除这个公告吗？',
      icon: <ExclamationCircleOutlined />,
      content: '注意，公告一旦删除不可复原，请慎重考虑！！！',
      okText: '确定',
      cancelText: '取消',
      onOk() {        
        return new Promise((resolve, reject) => {
          services.notice.delete(id)
            .then(() => {
              message.success('删除预约公告成功！')
              loadList()
              resolve()
            })
            .catch(() => {
              message.success('删除预约公告失败~')
              reject()
            })
        })
      }
    })
  }, [])

  const handleUpdate = useCallback((index: number) => {
    setOperation('update')
    setVisible(true)
    setActiveIndex(index)    
  }, [list])

  const handleSubmit = useCallback(() => {
    form.validateFields()
      .then(async (data) => {        
        const postData = {
          title: data.title,
          open: data.open,
          rule: data.rule,
          cycle: data.cycle,
          content: data.content,
        }
        const promise = operation == 'add' 
          ? services.notice.create(postData) 
          : services.notice.update(data.id, postData)
        promise
          .then(() => {
            setLoading(true)
            message.success(`预约公告${operation == 'add' ? '创建' : '更新'}成功`)
            setVisible(false)
            loadList()
          })
          .catch(() => {
            message.error(`预约公告${operation == 'add' ? '创建' : '更新'}失败`)
          })
          .finally(() => {
            setLoading(false)
          })
      }).catch(() => {
        message.warning('请正确填写表单')
      })
  }, [operation])

  useEffect(() => {
    if (activeIndex === -1) {  // 新增
      form.resetFields()
      form.setFieldsValue({ open: true, rule: 'A', cycle: '0' })
      setSelectVisible(true)
    } else {  // 更新      
      form.setFieldsValue({
        id: list[activeIndex]?.id,
        title: list[activeIndex]?.title,
        open: list[activeIndex]?.open === '是',
        content: list[activeIndex]?.content,
        rule: list[activeIndex]?.rule,
        cycle: `${notices[activeIndex]?.cycle}`,
      })
      setSelectVisible(list[activeIndex]?.rule === 'A')
    }
  }, [activeIndex, list, notices])

  return (
    <Block
      loading={loading}
      style={{
        paddingTop: '32px'
      }}>
      <Button type="primary" onClick={handleAdd}>
        <PlusOutlined />
          新增预约公告
        </Button>
      <Table
        columns={columns}
        loading={loading}
        dataSource={list}
        pagination={{
          total: total,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSize,
          pageSizeOptions: ['10', '20', '50', '100'],
          onChange: (_page, _pageSize) => {
            if (_page !== page) setPage(_page)
            if (_pageSize !== pageSize) setPageSize(_pageSize!)
          },
        }}
        style={{ marginTop: 15 }}
      />
      <Drawer
        visible={visible}
        maskClosable
        onClose={() => setVisible(false)}
        title={`${operation === 'add' ? '新增' : '编辑'}预约公告`}
        width={512}
        footer={
          <div style={{ textAlign: 'right' }}>
            <Button
              style={{ marginRight: 8 }}
              onClick={() => setVisible(false)}
            >取消</Button>
            <Button
              type="primary"
              onClick={handleSubmit}
            >确定</Button>
          </div>
        }
      >
        <Spin spinning={loading}>
          <Form
            name="item"
            form={form}
            layout="vertical"
            validateMessages={{ required: "'${label}' 不能为空" }}
          >
            <Form.Item
              label="预约公告ID"
              name="id"
              style={{ display: 'none' }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="预约公告名称"
              name="title"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="提醒规则"
              name="rule"
              rules={[{ required: true }]}
            >
              <Radio.Group >
                <Radio value={'A'} onClick={() => setSelectVisible(true)}>每次都提示</Radio>
                <Radio value={'B'} onClick={() => setSelectVisible(false)} >每天提醒一次</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item 
              name="cycle" 
              style={{ marginTop: -20, display: selectVisible ? 'block' : 'none' }} 
            >
              <Select placeholder="请选择" style={{ marginTop: 10 }}>
                <Select.Option value={'0'}>一直显示</Select.Option>
                <Select.Option value={'5'}>5分钟内不重复提示</Select.Option>
                <Select.Option value={'10'}>10分钟内不重复提示</Select.Option>
                <Select.Option value={'30'}>30分钟内不重复提示</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="是否开启" name="open" valuePropName="checked" >
              <Switch checkedChildren="开启" unCheckedChildren="关闭"/>
            </Form.Item>
            <Form.Item
              label="请输入预约公告"
              name="content"
              rules={[{ required: true }]}
            >
              <TextArea />
            </Form.Item>
          </Form>
        </Spin>
      </Drawer>
    </Block>
  )
}

export default List
