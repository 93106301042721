/*
 * @Author: your name
 * @Date: 2020-09-02 10:02:38
 * @LastEditTime: 2020-11-06 21:27:29
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/types/app.ts
 */
import { ReactNode } from "react";

export enum Scene {
  johnson = 'johnson',
  cqp = 'cqp'
}

export enum Sort {
  asc = 'asc',
  dec = 'dec'
}

export enum State { init, loading, resolve, reject }

export interface Configure {
  scene: Scene
}

export interface alertDialog {
  isOpen: boolean,
  content: ReactNode,
}

export interface TimeFragment {
  id: string,
  display_id: string,
  resource_id: string,
  date: string,
  time: string,
  max_load: number,
  use_load: number,
  color: string,
  state: string,
}
