/*
 * @Author: your name
 * @Date: 2020-10-23 15:56:45
 * @LastEditTime: 2020-10-26 15:20:34
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/services/item.ts
 */
import http from '../utils/http'
import { Item } from 'types/item'

const baseURL = '/Admin/Resource/'

export default {
  // 新增预约项
  add: (payload: AddPayload) => http.post<IdResult>(`${baseURL}add`, payload),
  // 删除预约项
  delete: (payload: IdPayload) => http.get<any>(`${baseURL}delete`, payload),
  // 更新预约项
  update: (payload: UpdatePayload) => http.post<any>(`${baseURL}update`, payload),
  // 获取预约项列表
  getList: (payload: PagePayload) => http.get<GetListResult>(`${baseURL}get_list`, payload),
  // 获取简易预约列表
  getSummaryList: () => http.get<{ id: number, name: string }[]>(`${baseURL}resource_list`),
  // 反馈区最大张数设置
  imgMax: {
    // 获取图片最大张数
    get: (id: number) => http.get<any>(`/Scene/resource/${id}`),
    // 更新图片最大张数
    update: (id: number, payload: any) => http.put<any>(`/Scene/resource/${id}`, payload)
  }
}

interface AddPayload {
  name: string,
  img_url: string,
  summary: string,
  description?: string
}

interface UpdatePayload extends AddPayload {
  id: string
}

interface GetListResult {
  list: { [index: string]: Item },
  total: number
}