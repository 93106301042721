import React, { FC, useCallback, useState, useEffect } from 'react'
import { Upload, message } from 'antd'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { OSS_SIGN } from 'consts/url'
import { getRandomString } from 'utils/common'
import ImgCrop from 'antd-img-crop'
import axios from 'axios'

interface Config {
  accessid: string,
  host: string,
  policy: string,
  signature: string,
  expire: number,
  callback: string,
  dir: string
}

interface ExtraData {
  key: string,
  OSSAccessKeyId: string,
  policy: string,
  signature: string,
  success_action_status: '200',
  callback: string,
}

interface Props {
  accept?: string,
  listType?: 'text' | 'picture' | 'picture-card',
  showUploadList?: boolean,
  onUploaded?: (key: string) => any
  imgUrl?: string,
  crop?: boolean,
  fullpath?: boolean,
  onUploading?: (on: boolean) => any,
  disabled?: boolean
}
const OSSUpload: FC<Props> = (props) => {
  const { accept = 'image/jpeg, image/png', listType = 'text',
    showUploadList = true, crop = true, disabled = false
  } = props

  const [config, setConfig] = useState<Config>()
  const [extraData, setExtraData] = useState<ExtraData>()
  const [uploading, setUploading] = useState<boolean>(false)

  const getSignature = useCallback(() => {
    return new Promise<void>((resolve, reject) => {
      axios.get<Config>(OSS_SIGN)
        .then(({ data }) => {
          setConfig(data)
          setExtraData({
            key: '',
            OSSAccessKeyId: data.accessid,
            policy: data.policy,
            signature: data.signature,
            success_action_status: '200',
            callback: data.callback,
          })
          resolve()
        })
        .catch(() => {
          message.error('OSS文件上传:从服务器获取签名失败~')
          reject()
        })
    })
  }, [])

  const beforeUpload = useCallback(() => {
    return (!config || Date.now() > config.expire * 1000) || getSignature()
  }, [config])

  const transformFile = useCallback(file => {
    if (!config || !extraData) return
    const filename = `oss-${Date.now()}-${getRandomString()}.jpg`
    extraData.key = config.dir + filename
    return file
  }, [config, extraData])

  const handleChange = useCallback((info: any) => {
    if (!config || !extraData) return    
    if (info.file.status === 'uploading') {
      setUploading(true)
      props.onUploading && props.onUploading(false)
    } else if (info.file.status === 'done') {
      setUploading(false)
      props.onUploading && props.onUploading(false)
      const url = props.fullpath ? `${config.host}/${extraData.key}` : extraData.key.replace(config.dir, '')
      props.onUploaded && props.onUploaded(url)
    }
  }, [config, extraData])

  useEffect(() => { getSignature() }, [])

  const renderUploader = useCallback(() => {
    return (
      <Upload
        action={config?.host}
        data={extraData}
        transformFile={transformFile}
        beforeUpload={beforeUpload}
        accept={accept}
        listType={listType}
        showUploadList={showUploadList}
        onChange={handleChange}
        disabled={disabled}
      >
        {props.imgUrl ?
          <img src={props.imgUrl.startsWith('http') ? props.imgUrl
            : `${config?.host}/${config?.dir}${props.imgUrl}`}
            style={{ width: '100%' }}
          /> : (
            props.children ? props.children :
              <div>
                {uploading ? <LoadingOutlined /> : <PlusOutlined />}
                <div className="ant-upload-text">上传</div>
              </div>
          )
        }
      </Upload>
    )
  }, [config, props])

  return crop ? (
    <ImgCrop
      modalTitle="裁剪图片"
      modalCancel="取消"
      modalOk="确定"
    >
      {renderUploader()}
    </ImgCrop>
  ) : renderUploader()
}

export default OSSUpload
