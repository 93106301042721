import React, { FC, useState, useEffect, useCallback, Fragment } from "react"
import { order } from "services"
import { DetailResult, ConfigureResult, ActionPayload } from "services/order"
import { Button, Table, Popconfirm, message, Modal, Input, Descriptions, Select } from "antd"
import { columns, fieldColumns, infoStyle } from "consts/order"
import { Block, Info, InputBox } from "components"
import { Link, useParams } from "react-router-dom"
import { Title, View, State } from "./style"
import { useDispatch } from "react-redux"
import { Dispatch } from "src/store"
import { getSystemInfo } from "utils/common"
import { useHistory } from 'react-router'

const Detail: FC = () => {
  const { TextArea } = Input
  const { Option } = Select
  const history = useHistory()
  const { setPage } = useDispatch<Dispatch>().order
  const { orderId } = useParams<{ orderId: string }>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [detail, setDetail] = useState<DetailResult>()
  const [render, setRender] = useState<number>(0)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [content, setContent] = useState<string>("")
  const [actionName, setActionName] = useState<string>("")
  const [user, setUser] = useState<{ name: string, value: string }[]>([])
  const [configure, setConfigure] = useState<ConfigureResult>()
  const [adminList, setAdminList] = useState<any[]>()

  //获取详情
  useEffect(() => {
    orderId && (async () => {
      setIsLoading(true)
      const configure = await order.getConfigure()
      setConfigure(configure)
      const data = await order.getDetail({ id: orderId })
      setIsLoading(false)
      data.order_field = data.order_field.filter(item => item.type !== "image")
      setDetail(data)
      const tempUser = []
      for (let name in data.order_user) {
        tempUser.push({ name, value: data.order_user[name] })
      }
      setUser(tempUser)
    })()
  }, [render, orderId])

  useEffect(() => {
    (async () => {
      const data = await order.getTransferUser({ order_id: orderId })
      const id = getSystemInfo().user_id
      const index: number = data.findIndex(item => item.id === id)
      index !== -1 ? data.splice(index, 1) : void 0
      setAdminList(data)
    })()
  }, [])

  useEffect(() => {
    setContent("")
  }, [isOpen])

  //选择状态
  const renderStatus = useCallback(() => {
    if (!configure || !detail) return ""
    const { status } = detail.order_status
    let result = ""
    for (let name in configure.status) {
      if (configure.status[name].filter(item => item === status).length) {
        result = `${name}-${status}`
      }
    }
    return result
  }, [configure, detail])

  const onConfirm = useCallback(async (action) => {
    if (!detail) return
    setIsOpen(false)
    try {
      let payload: ActionPayload = {
        id: [orderId],
        action,
        payload: {}
      }
      switch (action) {
        case "同意": case "签到": break
        case "移交":
          payload.payload["auditor"] = content
          break
        default:
          payload.payload["content"] = content
      }
      switch (action) {
        case "签到":
          await order.actionConfirm({ id: orderId })
          break
        default: await order.action(payload)
      }

      setRender(render + 1)
      setPage(1)
      message.success(`您已完成操作"${action}"`, 5)
      setContent("")
      switch (action) {
        case "移交": history.push(`/order/list`)
          break
      }
    } catch (err) {
      message.error(`操作出现异常`, 5)
    }
  }, [detail, orderId, render, content])

  //点击按钮
  const handleClickButton = useCallback((action) => {
    if (!detail || !orderId || isLoading) return
    setActionName(action)
    switch (action) {
      case "同意": case "签到": case "补签": onConfirm(action); return
      default: setIsOpen(true)
    }
  }, [detail, orderId, render, actionName, content])

  //渲染按钮
  const renderButton = useCallback((name) => (
    <Popconfirm placement="bottom" title={`您要"${name}"么`} onConfirm={() => handleClickButton(name)} okText={name} cancelText="暂不操作">
      <Button style={{ marginLeft: "8px" }}>{name}</Button>
    </Popconfirm>
  ), [detail, orderId, render, actionName, content])

  //渲染一个特殊功能的按钮
  const renderSpecialButton = useCallback((text) => {
    if (text === "改签") {
      return <Link to={`/order/endorse/${orderId}/${detail?.order_status.subject_id}`}>
        <Button style={{ marginLeft: "8px" }}>{text}</Button>
      </Link>
    }
  }, [detail])

  //渲染按钮们
  const renderButtonList = useCallback(() => {
    if (!detail || !detail.order_status.status) return ""
    switch (detail.order_status.status) {
      case "待审核": return <Fragment>{renderButton("移交")}{renderButton("拒绝")}{renderButton("同意")}</Fragment>
      case "待签到": return <Fragment>{renderSpecialButton("改签")}{renderButton("取消")}{renderButton("签到")}</Fragment>
      case "未签到": return <Fragment>{renderButton("补签")}</Fragment>
      case "已签到": return <Fragment>{renderButton("拒绝收货")}{renderButton("同意收货")}</Fragment>
    }
  }, [detail, orderId, render])

  return <React.Fragment>
    <Block
      loading={isLoading}
      title={<Title>订单编号:&nbsp;#{orderId}</Title>}
      description={<div>
        {renderButtonList()}
      </div>}
      style={{ paddingBottom: '15px' }}
    >
      <State>{renderStatus()}</State>
      {detail && detail.order_status && detail.order_status.logs && detail.order_status.logs.length ?
        <Descriptions style={{ marginTop: 0 }}>
          {detail.order_status.logs.map(item =>
            <Descriptions.Item label={item.title}>{item.content}</Descriptions.Item>
          )}
        </Descriptions>
        : null
      }
      <Button><Link to={`/order/annex/${orderId}`}>查看附件</Link></Button>
    </Block>
    <Block
      loading={isLoading}
      title={<Title>用户信息</Title>}
      style={{ padding: '14px 32px 16px' }}
    >
      <View>
        {user && user.length ? user.map(item => <Info title={item.name} style={infoStyle}>{item.value}</Info>) : null}
      </View>
    </Block >
    <Block
      loading={isLoading}
      title={<Title>订单信息</Title>}
      style={{ padding: '24px 32px 32px' }}
    >
      <Table
        columns={columns}
        dataSource={detail?.order_detail}
        pagination={false}
      />
    </Block >
    {
      detail && detail.order_field && detail.order_field.length ?
        <Block
          loading={isLoading}
          title={<Title>自定义预约信息</Title>}
          style={{ padding: '24px 32px 32px' }}
        >
          <Table
            bordered
            columns={fieldColumns}
            dataSource={detail?.order_field}
            pagination={false}
          />
        </Block >
        : null
    }
    <Modal
      visible={isOpen}
      title={actionName}
      onOk={() => onConfirm(actionName)}
      onCancel={() => setIsOpen(false)}
    >
      {actionName === "移交" ?
        <View>
          <InputBox title="选择管理员">
            <Select onChange={(value) => { setContent(value.toString()) }} style={{ minWidth: 234 }}>
              {adminList?.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
            </Select>
          </InputBox>
        </View> :
        <View style={{ marginTop: 0 }}>
          <p>原因:</p>
          <TextArea
            rows={4}
            value={content}
            onChange={(e) => { setContent(e.target.value) }} />
        </View>
      }

    </Modal>
  </React.Fragment >
}

export default Detail