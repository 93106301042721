import http from 'utils/http'

export default {
  // 获取配置项
  getConfig: (payload?: Payload.GetConfig) => http.post<AnyObject>('/Admin/Config/get_configures', payload),
  // 设置配置项
  setConfig: (payload: AnyObject) => http.post<any>('/Admin/Config/set_config', payload)
}

declare namespace Payload {
  export interface GetConfig {
    configs_need: AnyObject
  }
}
