import React, { FC, useState } from "react"
import { Route, useHistory } from "react-router-dom"
import { BreadcrumbOption, TabOption } from "types/antd"
import { Header } from "components"
import List from "./View/List"
import New from "./View/New"
import Detail from "./View/Detail"

const Administrators: FC = () => {
  const { pathname } = useHistory().location
  const [breadcrumbs] = useState<BreadcrumbOption[]>([{
    name: "人员管理"
  }, {
    name: "管理员列表"
  }
  ])
  const [selectTabKey, setSelectTabKey] = useState<string>(pathname === "/administrators/list" ? "0" : "1")
  const [tabs] = useState<TabOption[]>([{
    key: "0",
    name: "管理员列表",
    link: "/administrators/list"
  }])

  return (
    <div>
      {pathname === "/administrators/list" ? <Header
        title="管理员列表"
        description="管理在移动端注册本系统的所有用户，支持批量导入/导出大量用户以及为不同类别的用户分组。"
        breadcrumbs={breadcrumbs}
        tabs={tabs}
        selectTabKey={selectTabKey}
        onTabClick={(key) => { setSelectTabKey(key) }}
      /> : null}
      <Route path='/administrators/list' render={() => <List />} />
      <Route path="/administrators/new" render={() => <New />} />
      <Route path="/administrators/detail/:id" render={() => <Detail />} />
    </div>
  )
}

export default Administrators