import styled from "styled-components"

export const Key = styled.span`
  font-size: 14px;
  color: #969799;
  line-height: 18px;
`

export const Value = styled(Key)`
  color: #323233;
`
