/*
 * File: /src/pages/System/View/Attend/Rule.tsx
 * Author: Mxsyx (zsimline@163.com)
 * Create Time: Monday 2020-11-09 04:00:13
 * Last Modified: Monday 2020-11-09 04:00:14
 * Modified By: Mxsyx (zsimline@163.com>)
 * 
 * Copyright © 2020 https://www.joyreserve.com
 */
import React,{ FC, Fragment } from 'react'
import Common from './Common'
import Private from './Private'

const Rule: FC = () => {
  return (
    <Fragment>
      <Common />
      <Private />
    </Fragment>
  )
}

export default Rule
