/*
 * File: /src/pages/System/View/OrderRule/Common.tsx
 * Author: Mxsyx (zsimline@163.com)
 * Create Time: Monday 2020-11-09 05:04:30
 * Last Modified: Monday 2020-11-09 05:04:30
 * Modified By: Mxsyx (zsimline@163.com>)
 * 
 * Copyright © 2020 https://www.joyreserve.com
 */
import React, { FC, useCallback, useEffect, useState } from 'react'
import { Block } from 'components'
import Rule from './Rule'
import services from 'services'
import { Button, message } from 'antd'
import { Range } from 'types/attend'

const Common: FC = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [range, setRange] = useState<Range>([['F', 0], ['F', 0]])

  const handleSubmit = useCallback(() => {
    setLoading(true)
    services.attend.common.update({payload: range})
      .then(() => {
        message.success('更新通用签到规则成功！')
      })
      .catch(() => {
        message.error('更新通用签到规则失败~')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [range])

  useEffect(() => {
    services.attend.common.get()
      .then((data) => {
        if (data.length) setRange(data)
      })
      .catch(() => {
        message.error('获取通用签到规则失败~')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])  

  return (
    <Block
      loading={loading}
      title="通用签到规则"
      description={
        <Button type="primary" onClick={handleSubmit}>保存</Button>
      }
    >
      <Rule range={range} onChange={(value) => setRange(value)} />
    </Block>
  )
}

export default Common
