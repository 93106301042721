import { get } from '../utils/http/http'

const baseURL = '/Admin/Order/'

export default {
  //日预约实况-对象列表
  getResourceList: () => get<Resource[]>(`${baseURL}resource_list`),
  //日预约实况-时间片段
  getTimeFragmentList: (payload: { date: string, resource_id_list: string }) => get<TimeFragment[]>(`${baseURL}daily`, payload),
  //周预约实况-时间列表
  getWeekFragmentList: (payload: { date: string, resource_id: string }) => get<AnotherTimeFragment>(`${baseURL}daily_week`, payload)
}
interface Resource {
  id: string,
  name: string,
}

export interface AnotherTimeFragment {
  [key: string]: TimeFragment[]
}

export interface TimeFragment {
  id: string,
  resource_id: string,
  state: string,
  time: string,
  date: string,
  max_load: number,
  use_load: number,
  color: string,
  display_list: { name: string, value: string }[],
  user_list: User[]
}

interface User {
  id: string,
  order_id: string,
  phone: string,
  index: number,
  state: string
}