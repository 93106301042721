/*
 * File: /src/pages/System/View/OrderRule/Private.tsx
 * Author: Mxsyx (zsimline@163.com)
 * Create Time: Monday 2020-11-09 05:04:34
 * Last Modified: Monday 2020-11-09 05:04:34
 * Modified By: Mxsyx (zsimline@163.com>)
 * 
 * Copyright © 2020 https://www.joyreserve.com
 */
import React, { FC, useCallback, useEffect, useState } from 'react'
import { Block } from 'components'
import Rule, { mapping, StorePorps, toDHM, toSecond } from './Rule'
import { PrivateRule, RuleToResource } from 'types/orderRule'
import services from 'services'
import { Button, Col, Divider, Form, message, Row, Select, Spin } from 'antd'
import { PrivateRuleName } from './style'
import { DeleteOutlined } from '@ant-design/icons'

// 预约规则与预约资源的映射关系
let resourceList: RuleToResource[] = []

// 通过预约资源的ID获取其名字
function findNameById(id: number) {
  for (let i = 0; i < resourceList.length; i++) {
    if (resourceList[i].id === id) {
      return resourceList[i].name
    }
  }
}

// 获取尚为绑定预约规则的资源ID
function getUnbindIds() {
  return resourceList.filter(r => !r.rule_id).map(r => r.id)
}

interface Props {
  rule: PrivateRule,
  onChange: () => void
}
const PrivateItem: FC<Props> = (props) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [editable, setEditable] = useState<boolean>(!props.rule.id)
  const [form] = Form.useForm()
  const resIds = [...props.rule.for, ...getUnbindIds()]
  
  const [store, setStore] = useState<StorePorps>({
    '0-0-T': toSecond(props.rule.start_time[1]),
    '0-0-D': toSecond(props.rule.start_time[2]),
    '0-1-T': toSecond(props.rule.end_time[1]),
    '0-1-D': toSecond(props.rule.end_time[2]),
    '0-2-T': toSecond(props.rule.cancel_time[1]),
    '0-2-D': toSecond(props.rule.cancel_time[2])
  })
  const [checks, setChecks] = useState<string[]>([])

  const handleChange = (key: keyof StorePorps, value: [number, number, number]) => {
    store[key] = value
    setStore({ ...store })
  }

  useEffect(() => {
    const _checks = []
    props.rule.start_time[0] !== 'N' && _checks.push(`0-0-${props.rule.start_time[0]}`)
    props.rule.end_time[0] !== 'N' && _checks.push(`0-1-${props.rule.end_time[0]}`)
    props.rule.cancel_time[0] !== 'N' && _checks.push(`0-2-${props.rule.cancel_time[0]}`)
    setChecks(_checks)    
  }, [])

  const handleDelete = useCallback(() => {
    if (!props.rule.id) {
      message.error('尚未保存不可删除~')
      return
    }
    setLoading(true)
    services.orderRule.private.delete(props.rule.id as number)
      .then(() => {
        message.success('删除私有预约预约规则成功！')
        props.onChange()
      })
      .catch(() => {
        message.error('删除私有预约规则失败~')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [props])

  const handleCheck = (value: string[]) => {
    const index = value.indexOf(mapping[value[value.length - 1]])
    if (index !== -1) value.splice(index, 1)
    setChecks([...value])
  }

  const handleSubmit = useCallback(() => {
    // 预约开始时间与预约结束时间必须同时为 `按时间段` 或者 `按预约日期`   
    if (checks.indexOf('0-0-T') !== -1 && checks.indexOf('0-1-D') !== -1
      || checks.indexOf('0-0-D') !== -1 && checks.indexOf('0-1-T') !== -1) {
      message.error('预约开始时间与预约结束时间必须同时为 `按时间段` 或者 `按预约日期` ~')
      return
    }

    // 预约开始时间不能小于预约截止时间
    if (checks.indexOf('0-0-T') !== -1 && checks.indexOf('0-1-T') !== -1 && store['0-0-T'] < store['0-1-T']) {
      message.error('预约开始时间不能小于预约截止时间~')
      return
    }
    if (checks.indexOf('0-0-D') !== -1 && checks.indexOf('0-1-D') !== -1 && store['0-0-D'] < store['0-1-D']) {
      message.error('预约开始时间不能小于预约截止时间~')
      return
    }

    const _checks: string[] = []
    for (let i = 0; i < checks.length; i++) {
      _checks[parseInt(checks[i][2])] = checks[i][4]
    }
    form.validateFields()
      .then((data) => {
        const postData: PrivateRule = {
          name: data['name'],
          for: data['for'],
          start_time: [_checks[0] as ('T' | 'D') || 'N', toDHM(store['0-0-T']), toDHM(store['0-0-D'])],
          end_time: [_checks[1] as ('T' | 'D') || 'N', toDHM(store['0-1-T']), toDHM(store['0-1-D'])],
          cancel_time: [_checks[2] as ('T' | 'D') || 'N', toDHM(store['0-2-T']), toDHM(store['0-2-D'])]
        }
        setLoading(true)
        const operation = props.rule.id ? '更新' : '新增'
        const promise = operation === '新增'
          ? services.orderRule.private.create(postData)
          : services.orderRule.private.update(props.rule.id as number, postData)
        promise
          .then(() => {
            message.success(`${operation}私有预约规则成功！`)
            setEditable(false)
            props.onChange()
          })
          .catch(() => {
            message.error(`${operation}私有预约规则失败~`)
          })
          .finally(() => {
            setLoading(false)
          })
      })
      .catch(() => {
        message.warning('请正确填写表单！')
      })
  }, [store, checks, props])

  useEffect(() => {
    form.setFieldsValue({
      name: props.rule.name,
      for: props.rule.for
    })
  }, [props])

  return (
    <Spin spinning={loading}>
      <Form form={form} layout="vertical">
        <Row align="middle" style={{ marginTop: 15 }}>
          <Col span={4}>
            <Form.Item
              name="name"
              style={{ marginBottom: 5 }}
              rules={[{ required: true, message: "规则名不能为空" }]}
            >
              <PrivateRuleName bordered={editable} readOnly={!editable} />
            </Form.Item>
          </Col>
          <Col>
            <DeleteOutlined style={{ color: '#DDD', cursor: 'pointer' }} onClick={handleDelete} />
          </Col>
        </Row>
        <Form.Item
          name="for"
          label="适用的预约项目:"
          style={{ marginBottom: 8 }}
          rules={[{ required: true, message: "至少选择一个适用的预约项目" }]}
        >
          <Select mode="multiple" style={{ width: '464px' }} disabled={!editable}>
            {resIds.map(id =>
              <Select.Option key={id} value={id}>{findNameById(id)}</Select.Option>
            )}
          </Select>
        </Form.Item>
        <Rule editable={!editable} store={store} checks={checks} onChange={handleChange} onCheck={handleCheck} />
        <Row justify="end" gutter={[10, 0]}>
          <Col>
            <Button onClick={() => setEditable(!editable)} >{editable ? '取消' : '编辑'}</Button>
          </Col>
          <Col>
            {editable && <Button type="primary" onClick={handleSubmit}>保存</Button>}
          </Col>
        </Row>
        <Divider />
      </Form>
    </Spin >
  )
}

const Private: FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [rules, setRules] = useState<PrivateRule[]>([])

  // 加载私有预约规则列表
  // 加载预约规则与资源的映射关系
  const loadList = useCallback(() => {
    setLoading(true)
    Promise.all([
      services.orderRule.private.getList(),
      services.orderRule.private.getResources()
    ])
      .then(([rules, resoucres]) => {
        setRules(rules)
        resourceList = resoucres
      })
      .catch(() => {
        message.error('获取私有预约规则列表失败~')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const handleCreate = useCallback(() => {
    setRules([...rules, {
      name: `自定义预约规则${rules.length + 1}`,
      start_time: ['N', 0, 0],
      end_time: ['N', 0, 0],
      cancel_time: ['N', 0, 0],
      for: []
    }])
  }, [rules])

  useEffect(loadList, [])

  return (
    <Block loading={loading} title="高级预约规则">
      <Button type="primary" onClick={handleCreate}>添加预约规则</Button>
      {rules.map((rule, index) => <PrivateItem key={index} rule={rule} onChange={() => loadList()} />)}
    </Block>
  )
}

export default Private
