/*
 * File: /src/pages/System/View/attend/Private.tsx
 * Author: Mxsyx (zsimline@163.com)
 * Create Time: Monday 2020-11-09 05:04:34
 * Last Modified: Monday 2020-11-09 05:04:34
 * Modified By: Mxsyx (zsimline@163.com>)
 * 
 * Copyright © 2020 https://www.joyreserve.com
 */
import React, { FC, useCallback, useEffect, useState } from 'react'
import { Button, Col, Divider, Form, Input, message, Row, Select, Spin } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { Block } from 'components'
import { PrivateRule, RuleToResource } from 'types/attend'
import { Range } from 'types/attend'
import services from 'services'
import Rule from './Rule'

// 签到规则与预约资源的映射关系
let ruleToResourceList: RuleToResource[] = []

// 通过预约资源的ID获取其名字
function findNameById(id: number) {
  for (let i = 0; i < ruleToResourceList.length; i++) {
    if (ruleToResourceList[i].id === id) {
      return ruleToResourceList[i].name
    }
  }
}

// 获取尚为绑定签到规则的资源ID
function getUnbindIds() {
  return ruleToResourceList.filter(r => !r.rule_id).map(r => r.id)
}

interface Props {
  rule: PrivateRule,
  onChange: () => void
}
const PrivateItem: FC<Props> = (props) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [editable, setEditable] = useState<boolean>(!props.rule.id)
  const [range, setRange] = useState<Range>([['F', 0], ['F', 0]])
  const [form] = Form.useForm()
  const resIds = [...props.rule.for, ...getUnbindIds()]

  const handleDelete = useCallback(() => {
    if (!props.rule.id) {
      message.error('尚未保存不可删除~')
      return
    }
    setLoading(true)
    services.attend.private.delete(props.rule.id as number)
      .then(() => {
        message.success('删除私有预约签到规则成功！')
        props.onChange()
      })
      .catch(() => {
        message.error('删除私有签到规则失败~')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [props])

  const handleSubmit = useCallback(() => {
    form.validateFields()
      .then((data) => {
        const postData: PrivateRule = {
          name: data['name'],
          for: data['for'],
          range: range
        }
        setLoading(true)
        const operation = props.rule.id ? '更新' : '新增'
        const promise = operation === '新增'
          ? services.attend.private.create(postData)
          : services.attend.private.update(props.rule.id as number, postData)
        promise
          .then(() => {
            message.success(`${operation}私有签到规则成功！`)
            setEditable(false)
            props.onChange()
          })
          .catch(() => {
            message.error(`${operation}私有签到规则失败~`)
          })
          .finally(() => {
            setLoading(false)
          })
      })
      .catch(() => {
        message.warning('请正确填写表单！')
      })
  }, [props, range])

  useEffect(() => {
    form.setFieldsValue({
      name: props.rule.name,
      for: props.rule.for
    })
  }, [props])

  return (
    <Spin spinning={loading}>
      <Form form={form} layout="vertical">
        <Row align="middle" style={{ marginTop: 15 }}>
          <Col span={4}>
            <Form.Item
              name="name"
              style={{ marginBottom: 5 }}
              rules={[{ required: true, message: "规则名不能为空" }]}
            >
              <Input style={{ fontSize: 18, fontWeight: 800 }} bordered={editable} readOnly={!editable} />
            </Form.Item>
          </Col>
          <Col>
            <DeleteOutlined style={{ color: '#DDD', cursor: 'pointer' }} onClick={handleDelete} />
          </Col>
        </Row>
        <Form.Item
          name="for"
          label="适用的预约项目:"
          style={{ marginBottom: 8 }}
          rules={[{ required: true, message: "至少选择一个适用的预约项目" }]}
        >
          <Select mode="multiple" style={{ width: '464px' }} disabled={!editable}>
            {resIds.map(id =>
              <Select.Option value={id}>{findNameById(id)}</Select.Option>
            )}
          </Select>
        </Form.Item>
        <Rule editable={!editable} range={props.rule.range} onChange={(value) => setRange(value)} />
        <Row justify="end" gutter={[10, 0]}>
          <Col>
            <Button onClick={() => setEditable(!editable)} >{editable ? '取消' : '编辑'}</Button>
          </Col>
          <Col>
            {editable && <Button type="primary" onClick={handleSubmit}>保存</Button>}
          </Col>
        </Row>
        <Divider />
      </Form>
    </Spin >
  )
}

const Private: FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [rules, setRules] = useState<PrivateRule[]>([])

  // 加载私有签到规则列表
  // 加载签到规则与资源的映射关系
  const loadList = useCallback(() => {
    setLoading(true)
    Promise.all([
      services.attend.private.getList(),
      services.attend.private.getResourceIds(),
      services.attend.private.getResourceList()
    ])
      .then(([rules, resourceIds, resourceList]) => {
        setRules(rules)
        const ruleToResources: RuleToResource[] = []
        resourceList.map(resource => {
          ruleToResources.push({
            id: resource.id,
            name: resource.name,
            rule_id: (()=>{              
              const index = resourceIds.findIndex(item => item.id === resource.id)
              return index === -1 ? null : resourceIds[index].rule_id
            })()
          })
        })
        ruleToResourceList = ruleToResources        
      })
      .catch(() => {
        message.error('获取私有签到规则列表失败~')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const handleCreate = useCallback(() => {
    setRules([...rules, {
      name: `自定义签到规则${rules.length + 1}`,
      range: [['F', 0], ['F', 0]],
      for: []
    }])
  }, [rules])

  useEffect(loadList, [])

  return (
    <Block loading={loading} title="特殊签到规则">
      <Button type="primary" onClick={handleCreate}>添加签到规则</Button>
      {rules.map(rule => <PrivateItem rule={rule} onChange={() => loadList()} />)}
    </Block>
  )
}

export default Private
