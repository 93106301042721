import React, { FC, useCallback } from 'react'
import { Body, Left, Right, Title, Description, Header, Bottom, BottomButton } from './style'
import { useHistory } from 'react-router'

export interface CardList {
  tag: string,
  name: string,
  img_url: string,
  description: string,
}

const Card: FC<CardList> = (props) => {
  const history = useHistory()

  const handleClickEdit = useCallback(() => {
    history.push(props.tag)
  }, [props.tag, history])

  return (
    <Body onClick={handleClickEdit}>
      <Left>
        <Header src={props.img_url} />
      </Left>
      <Right>
        <Title>{props.name}</Title>
        <Description>{props.description}</Description>
      </Right>
      <Bottom>
        <BottomButton>编辑</BottomButton>
      </Bottom>
    </Body>
  )
}

export default Card