/*
 * @Author: your name
 * @Date: 2020-11-03 11:21:00
 * @LastEditTime: 2020-11-03 11:22:41
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/pages/Order/Model/Refuse/style.ts
 */
import styled from "styled-components"


export const Box = styled.div`
  display: flex;
  margin-bottom: 10px;
  align-items: center;
`