/*
 * File: /src/pages/Reserve/index.tsx
 * Author: Mxsyx (zsimline@163.com)
 * Create Time: Monday 2020-11-16 02:12:46
 * Last Modified: Monday 2020-11-16 02:12:46
 * Modified By: Mxsyx (zsimline@163.com>)
 * 
 * Copyright © 2020 https://www.joyreserve.com
 */
import React, { FC } from 'react'
import { Route } from 'react-router-dom'
import PlatformList from './View/Platform/List'
import PlatformDetail from './View/Platform/Detail'

const Reserve: FC = () => {
  return (
    <div>
      <Route path='/reserve/platform/list' render={() => <PlatformList />} />
      <Route path='/reserve/platform/detail/:platformId' render={() => <PlatformDetail />} />
    </div>
  )
}

export default Reserve
