/*
 * File: /src/pages/Order/View/Annex/style.ts
 * Author: Mxsyx (zsimline@163.com)
 * Create Time: Wednesday 2020-11-11 06:34:17
 * Last Modified: Wednesday 2020-11-11 06:34:17
 * Modified By: Mxsyx (zsimline@163.com>)
 * 
 * Copyright © 2020 https://www.joyreserve.com
 */
import styled from "styled-components"

export const Index = styled.div`
  text-align: center;
  font-weight: 700;
`

export const Badge = styled.div`
  background-color: red;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
`
