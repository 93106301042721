/*
 * File: /src/pages/Order/View/Annex/Feedback.tsx
 * Author: Mxsyx (zsimline@163.com)
 * Create Time: Wednesday 2020-11-11 05:48:22
 * Last Modified: Wednesday 2020-11-11 05:48:22
 * Modified By: Mxsyx (zsimline@163.com>)
 * 
 * Copyright © 2020 https://www.joyreserve.com
 */
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Button, Col, Divider, Input, Row, Image, message } from 'antd'
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { useParams } from 'react-router'
import services from 'services'
import { Block, OSSUpload } from 'components'
import { Feedback } from 'types/annex'
import { Badge, Index } from './style'

interface Props {
  index: number,
  feedback: Feedback,
  onViewed: (index: number) => void
}
const Message: FC<Props> = (props) => {
  const { index, feedback, onViewed } = props
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current) ref.current.innerHTML = feedback.content
  }, [props])

  return (
    <Row
      align="middle"
      style={{ position: 'relative', padding: 10, fontSize: 14 }}
      onClick={feedback.viewed ? undefined : () => onViewed(index)}
    >
      <Col span={20}>
        <Row gutter={[0, 10]}>
          <Col style={{ fontWeight: 600 }}>{feedback.title}</Col>
          <Col span={24} style={{ color: 'rgba(0,0,0,0.65)' }}>
            <div ref={ref}></div>
          </Col>
        </Row>
      </Col>
      <Col span={4} style={{ color: 'rgba(0,0,0,0.25)' }}>{feedback.time}</Col>
      <Divider style={{ margin: '8px 0px' }} />
      {!feedback.viewed && <Badge />}
    </Row>
  )
}

const Feedbacks: FC = () => {
  const { orderId } = useParams<{ orderId: string }>()

  const [loading, setLoading] = useState<boolean>(false)
  const [sending, setSending] = useState<boolean>(false)
  const [content, setContent] = useState<string>('')
  const [pictures, setPictures] = useState<string[]>([])
  const [feedbackList, setFeedbackList] = useState<Feedback[]>([])
  const [feedbackable, setFeedbackable] = useState<boolean>(false)

  const handleUploaded = useCallback((url: string) => {
    pictures.push(url)
    setPictures([...pictures])
  }, [content, pictures])

  const loadList = useCallback(() => {
    setLoading(true)
    services.annex.getFeedbackList({ id: orderId })
      .then((data) => {
        setFeedbackList(data)
      })
      .catch(() => {
        message.error('获取反馈内容列表失败~')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    const store = { scale: 1, pageX1: 0, pageX2: 0, pageY1: 1, pageY2: 0, moveable: false, originScale: 1 }

    const zooz = document.createElement('div')
    zooz.id = 'zooz'
    zooz.style.display = 'none'
    zooz.style.position = 'fixed'
    zooz.style.top = '0px'
    zooz.style.left = '0px'
    zooz.style.width = '100vw'
    zooz.style.height = '100vh'
    zooz.style.zIndex = '99999'
    zooz.style.alignItems = 'center'
    zooz.style.justifyContent = 'center'
    zooz.style.backgroundColor = 'rgba(0, 0, 0, 0.8)'

    const zoozImg = document.createElement('img')
    zoozImg.id = 'zooz-img'
    zoozImg.width = 300

    const symbolClose = document.createElement('span')
    symbolClose.innerHTML = 'X'
    symbolClose.style.position = 'fixed'
    symbolClose.style.top = '0px'
    symbolClose.style.right = '0px'
    symbolClose.style.color = '#fff'
    symbolClose.style.padding = '20px'
    symbolClose.style.fontSize = '24px'
    symbolClose.style.cursor = 'pointer'
    symbolClose.style.lineHeight = '20px'
    symbolClose.style.userSelect = 'none'
    symbolClose.onclick = (event) => {
      event.preventDefault()
      store.scale = 1
      zoozImg.style.transform = 'scale(1)'
      zooz.style.display = 'none'
    }

    const symbolZoomIn = document.createElement('span')
    symbolZoomIn.innerHTML = '+'
    symbolZoomIn.style.position = 'fixed'
    symbolZoomIn.style.top = '0px'
    symbolZoomIn.style.right = '40px'
    symbolZoomIn.style.color = '#fff'
    symbolZoomIn.style.padding = '20px'
    symbolZoomIn.style.fontSize = '34px'
    symbolZoomIn.style.lineHeight = '12px'
    symbolZoomIn.style.cursor = 'pointer'
    symbolZoomIn.style.userSelect = 'none'
    symbolZoomIn.onclick = (event) => {
      event.preventDefault()
      store.scale = store.scale + 0.3
      zoozImg.style.transform = 'scale(' + store.scale + ')'
    }

    const symbolZoomOut = document.createElement('span')
    symbolZoomOut.innerHTML = '-'
    symbolZoomOut.style.position = 'fixed'
    symbolZoomOut.style.top = '0px'
    symbolZoomOut.style.right = '80px'
    symbolZoomOut.style.color = '#fff'
    symbolZoomOut.style.padding = '20px'
    symbolZoomOut.style.fontSize = '34px'
    symbolZoomOut.style.lineHeight = '12px'
    symbolZoomOut.style.cursor = 'pointer'
    symbolZoomOut.style.userSelect = 'none'
    symbolZoomOut.onclick = (event) => {
      event.preventDefault()
      if (store.scale <= 0.25) return
      store.scale = store.scale - 0.25
      zoozImg.style.transform = 'scale(' + store.scale + ')'
    }

    zooz.appendChild(zoozImg)
    zooz.appendChild(symbolClose)
    zooz.appendChild(symbolZoomIn)
    zooz.appendChild(symbolZoomOut)
    document.body.appendChild(zooz)

    if (!window.hasOwnProperty('openPreview')) {
      Object.defineProperty(window, 'openPreview', {
        value: (target: HTMLAnchorElement) => {
          const preview = document.getElementById('zooz') as HTMLDivElement
          const dom = document.getElementById('zooz-img') as HTMLImageElement
          dom.src = target.getAttribute('href') as string
          preview.style.display = 'flex'
        }
      })
    }
  }, [])

  const sendMsg = useCallback(() => {
    setSending(true)
    const msg = content +
      pictures.map((picture, index) => `&nbsp;<span style="color: rgb(48,166,148); cursor: pointer;" href="${picture}" onclick="window.openPreview(this)">图片[${index + 1}]</span>&nbsp;`)
    services.annex.sendMsg({ order_id: orderId, content: msg })
      .then(() => {
        setPictures([])
        setContent('')
        loadList()
      })
      .catch(() => {
        message.error('发送消息失败~')
      })
      .finally(() => {
        setSending(false)
      })
  }, [content, pictures, feedbackList])

  const handleViewed = useCallback((index: number) => {
    services.annex.changeFeedbackViewed(feedbackList[index].id)
      .then(() => {
        feedbackList[index].viewed = true
        setFeedbackList([...feedbackList])
      })
      .catch(() => {
        message.warn('改变反馈查看状态时遇到错误~')
      })
  }, [feedbackList])

  // 检查是否可反馈
  useEffect(() => {
    services.annex.checkFeedbackable({ id: orderId })
      .then((data) => {
        setFeedbackable(data.feedbackable)
      })
  }, [])

  useEffect(loadList, [])

  return (
    <Block loading={loading} title="反馈区">
      {feedbackList.map((feedback, index) =>
        <Message
          index={index}
          feedback={feedback}
          onViewed={index => handleViewed(index)}
        />
      )}
      <Row align="middle">
        <Col flex="1 1 auto">
          <Input
            placeholder="请输入反馈内容"
            value={content}
            onChange={e => setContent(e.target.value)}
            disabled={!feedbackable}
          />
        </Col>
        <Col flex="0 0 45px" style={{ textAlign: 'center' }}>
          {feedbackable &&
            <OSSUpload
              showUploadList={false}
              crop={false}
              fullpath
              onUploaded={url => handleUploaded(url)}
              disabled={!feedbackable}
            >
              <PlusCircleOutlined style={{ color: "#30A694", fontSize: 30 }} />
            </OSSUpload>
          }
        </Col>
        <Col flex="0 0 45px" style={{ textAlign: 'center' }}>
          <Button type="primary" loading={sending} onClick={sendMsg} disabled={!feedbackable}>发送</Button>
        </Col>
      </Row>
      <Row align="middle" gutter={[16, 16]} style={{ marginTop: 15 }}>
        {pictures.map((picture, index) =>
          <Col>
            <Image src={picture} width={60} height={60} />
            <Index>
              <DeleteOutlined onClick={() => {
                pictures.splice(index, 1)
                setPictures([...pictures])
              }} />
            </Index>
          </Col>
        )}
      </Row>
    </Block>
  )
}

export default Feedbacks
