import { Col, Row} from 'antd'
import React, { FC } from 'react'
import { Key, Value } from './style'

interface Props {
  name: string,
  colw?: number
}

const HashItem: FC<Props> = (props) => {
  return (
    <Row>
      <Col span={props.colw || 6}>
        <Key>{props.name}</Key>
      </Col>
      <Col>
        <Value>{props.children}</Value>
      </Col>
    </Row>
  )
}

export default HashItem
