import React, { FC, useState, useEffect, useCallback } from 'react'
import { RereadRuleType, PeriodSpaceType, PeriodType as PeriodTypeList } from 'types/time'
import { Drawer, Input, DatePicker, Select, Button, Radio } from 'antd'
import { useParams } from 'react-router'
import { FormatDay } from 'utils/time'
import { InputBox } from 'components'
import { PanelRowStyle, PanelInput, useRuleList, periodList } from 'consts/time'
import moment from "moment"
import { InputRow } from './style'
import SelectWeek from 'components/SelectWeek'
import { time } from 'services'

interface QuickPanelType {
  isOpen: boolean,
  onClose: () => void,
  onSubmit: () => void
}

const QuickPanel: FC<QuickPanelType> = (props) => {
  const { Option } = Select
  const { RangePicker } = DatePicker
  const { cardId: resource_id } = useParams<{ cardId: string }>()
  const [date, setDate] = useState<Date>(new Date())
  const [timeRange, setTimeRange] = useState<[string, string]>(['08:00', '16:00'])
  const [loading, setLoading] = useState<boolean>(false)
  const [space, setSpace] = useState<number>(0)
  const [size, setSize] = useState<number>()
  const [load, setLoad] = useState<number>()
  const [selectUseRule, setSelectUseRule] = useState<RereadRuleType>(RereadRuleType.today)
  const [periodSpace, setPeriodSpace] = useState<PeriodSpaceType>(PeriodSpaceType.week)
  const [periodValue, setPeriodValue] = useState<number>()
  const [periodDay, setPeriodDay] = useState<number[]>([])
  const [periodType, setPeriodType] = useState<PeriodTypeList>(PeriodTypeList.always)
  const [periodData, setPeriodData] = useState<any>()

  const handleClickSubmit = useCallback(async () => {
    setLoading(true)
    await time.submitQuick({
      resource_id: Number(resource_id),
      start_time: `${FormatDay(date)} ${timeRange[0]}`,
      end_time: `${FormatDay(date)} ${timeRange[1]}`,
      time: size!,
      space_time: space,
      load: load!,
      weight: 1,
      reread_rule: selectUseRule,
      custom_rule: {
        period_space: periodSpace || null,
        period_value: periodValue || null,
        period_type: periodType,
        period_data: moment(periodData).format('YYYY-MM-DD'),
        day: periodDay,
      },
    })
    setLoading(false)
    props.onSubmit()
  }, [resource_id, date, timeRange, size, space, load, selectUseRule,
    periodSpace, periodValue, periodType, periodData, periodDay
  ])

  useEffect(() => {
    setPeriodData('')
  }, [periodType])

  return (
    <Drawer
      visible={props.isOpen}
      width={512}
      title='快速生成'
      bodyStyle={{
        padding: ' 0 24px',
      }}
      onClose={props.onClose}
      footerStyle={{ display: 'flex', justifyContent: 'flex-end' }}
      footer={
        <React.Fragment>
          <Button onClick={props.onClose}>取消</Button>
          <Button
            loading={loading}
            disabled={loading}
            onClick={handleClickSubmit}
            type='primary'
            style={{ marginLeft: '8px' }}
          >
            确定
          </Button>
        </React.Fragment>
      }
    >
      <InputBox style={PanelRowStyle} title='选择日期'>
        <DatePicker
          value={moment(date)}
          style={PanelInput}
          onChange={(_value, string) => setDate(new Date(string))}
          allowClear={false}
        />
      </InputBox>
      <InputBox style={PanelRowStyle} title='起止时间'>
        <RangePicker
          picker='time'
          format='HH:mm'
          value={timeRange && timeRange.length ? [moment(timeRange[0], 'HH:mm'), moment(timeRange[1], 'HH:mm')] : undefined}
          onChange={(_value, string) => setTimeRange(string)}
          allowClear={false}
          style={{
            width: '224px'
          }}
        />
      </InputBox>
      <InputBox style={{ ...PanelRowStyle, paddingRight: '42px' }} title='设置单位时间段持续时间'>
        <InputRow>
          <Input
            type='number'
            value={size}
            onChange={(e) => { setSize(Number(e.target.value)) }}
            style={PanelInput}
          />
          <p>分钟</p>
        </InputRow>
      </InputBox>
      <InputBox style={{ ...PanelRowStyle, paddingRight: '42px' }} title='设置时间间隔'>
        <InputRow>
          <Input
            type='number'
            value={space}
            onChange={(e) => { setSpace(Number(e.target.value)) }}
            style={PanelInput}
          />
          <p>分钟</p>
        </InputRow>
      </InputBox>
      <InputBox style={PanelRowStyle} title='时段可预约人数'>
        <Input
          type='number'
          value={load}
          onChange={(e) => { setLoad(Number(e.target.value)) }}
          style={PanelInput}
        />
      </InputBox>
      <InputBox style={PanelRowStyle} title='生成范围'>
        <Select style={PanelInput} value={selectUseRule} onChange={(value) => { setSelectUseRule(value) }}>
          {useRuleList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
        </Select>
      </InputBox>
      {selectUseRule === RereadRuleType.custom ?
        <React.Fragment>
          <InputBox
            style={{
              ...PanelRowStyle,
              paddingRight: '135px'
            }}
            title='重复'
          >
            <Input
              type='number'
              value={periodValue}
              onChange={(e) => { setPeriodValue(Number(e.target.value)) }}
              style={{ width: '80px' }}
            />
            <Select
              value={periodSpace}
              onChange={(value) => { setPeriodSpace(value) }}
              style={{ width: '80px', marginLeft: '8px' }}
            >
              {periodList.map(item => <Option value={item.value}>{item.name}</Option>)}
            </Select>
          </InputBox>
          {periodSpace === PeriodSpaceType.week ?
            <InputBox
              style={{
                ...PanelRowStyle,
                paddingRight: '23px'
              }}
              title='按日重复'
            >
              <SelectWeek value={periodDay} onChange={(value) => { setPeriodDay(value) }}></SelectWeek>
            </InputBox>
            : null
          }
          <InputBox style={{ ...PanelRowStyle, alignItems: 'flex-start', paddingRight: 33 }} title='重复次数'>
            <Radio.Group
              value={periodType}
              onChange={(e) => { setPeriodType(e.target.value) }}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: 110
              }}
            >
              <Radio value={PeriodTypeList.always}>一直</Radio>
              <Radio value={PeriodTypeList.date}>
                从
                <DatePicker
                  disabled={periodType !== PeriodTypeList.date}
                  style={{ width: 180, marginLeft: 4, marginRight: 4 }}
                  value={periodType === PeriodTypeList.date ? periodData : null}
                  onChange={(_value, string) => { setPeriodData(_value) }}
                />
                开始
              </Radio>
              <Radio value={PeriodTypeList.number}>
                执行
                <Input
                  disabled={periodType !== PeriodTypeList.number}
                  style={{ width: 80, marginLeft: 4, marginRight: 4 }}
                  value={periodType === PeriodTypeList.number ? periodData : null}
                  type='number'
                  onChange={(e) => { setPeriodData(e.target.value) }}
                />
                次
              </Radio>
            </Radio.Group>
          </InputBox>
        </React.Fragment>
        : null}
    </Drawer>
  )
}

export default QuickPanel