/*
 * @Author: your name
 * @Date: 2020-10-15 20:24:01
 * @LastEditTime: 2020-10-22 16:20:51
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/pages/Administrators/View/style.ts
 */
import styled from 'styled-components'

export const Title = styled.div`
  width: 80px;
  height: 24px;
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  line-height: 24px;
  font-weight: 600;
  margin-top: 24px;
`

export const Name = styled.div`
  font-weight: 600;
  font-size: 16px;
  margin-top: 40px;
`

export const ButtonBar = styled.div`
  margin-left:-12px
`