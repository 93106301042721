import { createModel } from '@rematch/core'
import { Configure } from 'types/app'

interface AppState {
  configure: Configure,
  render: number
}

export const app = createModel({
  state: {
    render: 1
  } as AppState,
  reducers: {
    setConfigure: (state, payload: Configure): AppState => ({
      ...state,
      configure: payload
    }),
    setRender: (state, payload: number): AppState => ({
      ...state,
      render: payload
    }),
  },
  effects: {

  }
})

