import React, { FC, useEffect, useState } from 'react'
import { Route } from 'react-router'
import services from 'services'
import { ConfigureResult } from 'services/order'
import Detail from './View/Detail'
import List from './View/List'
import Queue from './View/Queue'

const Order: FC = () => {
  const [configure, setConfigure] = useState<ConfigureResult>()

  // init
  useEffect(() => {
    (async () => {
      const data = await services.order.getConfigure()
      setConfigure(data)
    })()
  }, [])

  return (
    <div>
      <Route path='/order/list' render={() => <List configure={configure} />} />
      <Route path='/order/detail/:orderId' render={() => <Detail />} />
      <Route path='/order/queue/:id' render={() => <Queue />} />
    </div>
  )
}

export default Order
