import React, { FC } from "react"
import { Route } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { BreadcrumbOption } from "types/antd"
import { Header } from "components"
import List from "./View/List"

const breadcrumbs: BreadcrumbOption[] = [{
  name: "系统配置",
}, {
  name: "注册登录信息"
}]

const Register: FC = () => {
  const { t } = useTranslation()

  const renderHeader = () => <Header
    title={t("page_register_list_title")}
    description={t("page_register_list_description")}
    breadcrumbs={breadcrumbs}
  />

  return (
    <div>
      <Route path='/register/list' render={renderHeader} />
      <Route path='/register/list' render={() => <List />} />
    </div>
  )
}

export default Register
