/*
 * File: /src/services/annex.tsx
 * Author: Mxsyx (zsimline@163.com)
 * Create Time: Wednesday 2020-11-11 06:03:20
 * Last Modified: Wednesday 2020-11-11 06:03:20
 * Modified By: Mxsyx (zsimline@163.com>)
 * 
 * Copyright © 2020 https://www.joyreserve.com
 */
import http from 'utils/http'
import { Feedback, Picture } from 'types/annex'

export default {
  // 获取照片列表
  getPictureList: (payload: Payload.GetPictureList) => http.get<DataList<Picture>>('/Scene/pictures', payload),
  // 改变照片状态为查看过
  changePictureViewed: (id: number) => http.put<any>(`/Scene/pictures/${id}`),
  // 导出图片为PDF
  exportAsPDF: (payload: IdPayload) => http.get<Result.Export>('/Scene/pictures/pdf', payload),
  // 导出图片为ZIP
  exportAsZIP: (payload: IdPayload) => http.get<Result.Export>('/Scene/pictures/zip', payload),
  // 获取反馈列表
  getFeedbackList: (payload: IdPayload) => http.get<Feedback[]>('/Scene/feedbacks', payload),
  // 管理员新增反馈
  sendMsg: (payload: Payload.SendMsg) => http.post('/Scene/feedbacks', payload),
  // 改变反馈状态为查看过
  changeFeedbackViewed: (id: number) => http.put(`/Scene/feedback/${id}/read`),
  // 检查是够可反馈
  checkFeedbackable: (payload: IdPayload) => http.get<Payload.CheckFeedbackable>('/Scene/feedbacks/status', payload)
}

declare namespace Payload {
  export interface GetPictureList extends PagePayload {
    id: string,
  }
  
  export interface SendMsg {
    order_id: string,
    content: string
  }

  export interface CheckFeedbackable {
    feedbackable: boolean
  }
}

declare namespace Result {
  export interface Export {
    url: string
  }
}
