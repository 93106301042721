/*
 * @Author: your name
 * @Date: 2020-11-12 20:56:35
 * @LastEditTime: 2020-11-13 15:02:20
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/pages/System/View/DisplayTime/style.ts
 */
import styled from 'styled-components'

export const Body = styled.div`
  height: 80px;
`
export const RelativeDiv = styled.div`
  position: relative;
  z-index: 1;
`
export const Mask = styled.div`
  height: 100%;
  width: 100%;
  background:rgba(221,221,221,0.7);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
`
