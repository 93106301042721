import { Brief, Detail, Record } from 'types/user'
import http from 'utils/http'

export default {
  // 新增用户
  create: (payload: Payload.Create) => http.post<any>('/Admin/User/add_user', payload),
  // 删除用户
  delete: (payload: IdPayload) => http.post<any>('/Admin/User/delete', payload),
  // 更新用户信息
  update: (payload: AnyObject) => http.post<any>('/Admin/User/update', payload),
  // 获取用户列表
  getList: (payload: Payload.GetList) => http.get<DataList<Brief>>('/Admin/User/users', payload),
  // 获取用户详情
  getDetail: (id: string) => http.get<Detail>(`/Admin/User/user/${id}`),
  // 获取用户预约记录
  getRecords: (id: string, payload: GetRecordsPayload) => http.get<DataList<Record>>(`/Scene/user/${id}/orders`, payload),
  // 重置用户密码
  resetPwd: (id: string) => http.put<any>(`/Admin/User/${id}/password`),
  // 导出用户列表
  export: () => http.get<AnyObject<string>[]>('/Admin/User/export')
}

declare namespace Payload {
  export interface Create {
    users: AnyObject[]
  }
  export interface GetList {
    q?: string,
    page_size?: number,
    page?: number,
    reserve_time: string[],
    order_time: string[]
  }
}

interface GetRecordsPayload {
  order_order?: 'asc' | 'dec'
  state?: string,
  page_size?: number,
  page?: number
}
