import React, { FC } from "react"
import { Route } from "react-router-dom"
import Attend from "./View/Attend"
import CustomOrderEdit from "./View/CustomOrderEdit"
import CustomOrderList from "./View/CustomOrderList"
import DisplayTime from "./View/DisplayTime"
import OrderRule from './View/OrderRule'
import Empower from './View/Empower'

const System: FC = () => {
  return (
    <div>
      <Route path='/system/display_time' render={() => <DisplayTime />} />
      <Route path='/system/custom_order_list' render={() => <CustomOrderList />} />
      <Route path='/system/custom_order_edit/:id' render={() => <CustomOrderEdit />} />
      <Route path='/system/order_rule' render={() => <OrderRule />} />
      <Route path='/system/attend' render={() => <Attend />} />
      <Route path='/system/empower' render={() => <Empower />} />
    </div>
  )
}

export default System
