/*
 * @Author: your name
 * @Date: 2020-11-05 12:05:26
 * @LastEditTime: 2020-12-01 17:58:10
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/services/system.ts
 */
import { CustomOrderType, RoughCustomOrderItemType } from 'types/system'
import { del, get, post, put } from '../utils/http/http'
import { CardList, RoomList } from './time'

const baseURL = '/Admin/'

export default {
  //预约规则-通用-获取
  getDisplayTime: () => get<any>(`${baseURL}resource_rule/rule_list`),
  //预约规则-通用-添加
  addNewRule: (payload: Rule) => post<{ id: number }>(`${baseURL}resource_rule/rule`, payload),
  //预约规则-通用-修改
  editRule: (payload: Rule) => put<null>(`${baseURL}resource_rule/rule`, payload),
  //预约规则-通用-删除 
  delRule: (payload: { id: number }) => del<null>(`${baseURL}resource_rule/del_rule/${payload.id}`),
  //预约规则-休息日-未添加列表
  getDisplayTimeRestAddList: () => get<RoomList[]>(`${baseURL}Resource/time_resources`),
  //预约规则-休息日-添加卡片
  postDisplayTimeRestCard: (payload: { id: number }) => post<null>(`${baseURL}Resource/binding_time/${payload.id}`),
  //预约规则-休息日-获取卡片
  getDisplayTimeRestCardList: () => get<CardList[]>(`${baseURL}Resource/binding_time`),
  //预约规则-休息日-一键同步
  getDisplayTimeRestCopy: (payload: { id: number, target_id: number }) => post<null>(`${baseURL}resource_display/copy/`, payload),
  //预约规则-休息日-获取卡片详情
  getDisplayTimeRestDetail: (payload: { id: number }) => get<RestDetail>(`${baseURL}resource_display/resouces_times/${payload.id}`),
  //预约规则-休息日-初始化卡片详情
  initDisplayTimeRestDetail: (payload: { id: number, data: RestDetail }) =>
    post<RestDetail>(`${baseURL}resource_display/resouces_times/${payload.id}`, payload.data),
  //预约规则-休息日-修改卡片详情
  editDisplayTimeRestDetail: (payload: { id: number, data: RestDetail }) =>
    put<null>(`${baseURL}resource_display/resouces_times/${payload.id}`, payload.data),
  //预约通用规则-修改
  editCommonRule: (payload: { show_days: number }) => post<null>(`${baseURL}Config/set_config`, payload),
  //预约可选项列表
  getCustomOrderList: () => get<RoughCustomOrderItemType[]>(`${baseURL}order_rule/order_rules`),
  //预约可选项详情-获取
  getCustomOrder: (payload: { id: number }) => get<CustomOrderType>(`${baseURL}order_rule/rules/${payload.id}`),
  //预约可选项详情-创建
  createCustomOrder: (payload: CustomOrderType) => post(`${baseURL}order_rule/rules`, payload),
  //预约可选项详情-修改
  editCustomOrder: (payload: CustomOrderType) => put(`${baseURL}order_rule/rules/${payload.id}`, payload),
  //预约可选项详情-删除
  delCustomOrder: (payload: { id: number }) => del(`${baseURL}order_rule/rules/${payload.id}`),
  //第三方配置-微信获取
  getEmpowerWeChat: () => get<EmpowerWeChatResult>(`${baseURL}Config/third_wecaht_config`),
  //第三方配置-微信保存
  postEmpowerWeChat: (payload: { is_wechat_config: boolean }) => post(`${baseURL}Config/set_config`, payload),
}

export interface EmpowerWeChatResult {
  empower_url: string,
  is_use: boolean,
  app_id: string,
}

export interface RestDetail {
  is_display: boolean,
  is_date: boolean,
  is_specific_date: boolean,
  date?: string[],
  specific_date?: string[],
  name?: string
}

export interface Rule {
  id?: number,
  rule_name: string,
  is_show_days: boolean,
  show_days: number,
  resource_id_list: number[]
}