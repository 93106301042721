/*
 * File: /src/pages/System/View/Attend/List.tsx
 * Author: Mxsyx (zsimline@163.com)
 * Create Time: Monday 2020-11-09 04:00:23
 * Last Modified: Monday 2020-11-09 04:00:23
 * Modified By: Mxsyx (zsimline@163.com>)
 * 
 * Copyright © 2020 https://www.joyreserve.com
 */
import React, { FC, Fragment, useCallback, useEffect, useState } from 'react'
import { Button, Col, Input, message, Modal, Pagination, Row, Table } from 'antd'
import { Block } from 'components'
import { Order } from 'types/attend'
import services from 'services'
import { Link } from 'react-router-dom'

const columns = [{
  key: 'orderId',
  dataIndex: 'orderId',
  title: '订单号',
  width: '200px'
}, {
  key: 'username',
  dataIndex: 'username',
  title: '用户名',
  width: '80px'
}, {
  key: 'periods',
  dataIndex: 'periods',
  title: '预约时间',
  width: '210px'
}, {
  key: 'phone',
  dataIndex: 'phone',
  title: '手机号',
  width: '120px'
}, {
  key: 'attendTime',
  dataIndex: 'attendTime',
  title: '签到时间',
  width: '200px'
}, {
  key: 'orderStatus',
  dataIndex: 'orderStatus',
  title: '订单状态',
  width: '90px'
}, {
  key: 'operation',
  dataIndex: 'operation',
  title: '操作'
}]

const List: FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [keyword, setKeyword] = useState<string>('')
  const [list, setList] = useState<any[]>([])
  const [page, setPage] = useState<{ current: number }>({ current: 1 })
  const [pageSize, setPageSize] = useState<number>(20)
  const [total, setTotal] = useState<number>(0)
  const [reason, setReason] = useState<string>('')
  const [visible, setVisible] = useState<boolean>(false)
  const [action, setAction] = useState<'拒绝收货' | '同意收货' | ''>('')
  const [activeId, setActiveId] = useState<string>('')

  const handleData = useCallback((data: Order[]) => {
    return data.map(item => ({
      key: item.order_id,
      orderId: (
        <Row>
          <Col>#{item.order_id}</Col>
          <Col>{item.order_time}</Col>
        </Row>
      ),
      username: item.username,
      periods: (
        <Row>
          <Col span={24}>{item.resource_name}</Col>
          {item.periods.map(period => <Col span={24}>{period}</Col>)}
        </Row>
      ),
      phone: item.phone,
      attendTime: item.attend_time,
      orderStatus: '已签到',
      operation: (
        <Fragment>
          <Button
            type="link"
            style={{ padding: 5 }}
            onClick={() => {
              setActiveId(item.order_id)
              setAction('同意收货')
              setVisible(true)
            }} >收货</Button>
          <Button
            type="link"
            style={{ padding: 5 }}
            onClick={() => {
              setActiveId(item.order_id)
              setAction('拒绝收货')
              setVisible(true)
            }}>拒收</Button>
          <Link to={`/order/detail/${item.order_id}`} style={{ padding: 5 }}>详情</Link>
        </Fragment >
      )
    }))
  }, [])

  const handleChangeStatus = useCallback(() => {
    setVisible(false)
    setLoading(true)
    services.order.action({
      action,
      id: [activeId],
      payload: { content: reason }
    })
      .then(() => {
        message.success(`${action}成功！`)
        loadList()
      })
      .catch(() => {
        message.error(`${action}失败~`)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [activeId, action, reason])

  const loadList = useCallback(() => {
    setLoading(true)
    services.attend.getOrderList({ page: page.current, size: pageSize, q: keyword })
      .then((data) => {
        setList(handleData(data.list))
        setTotal(data.total)
      })
      .catch(() => {
        message.error('获取签到列表失败~')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [page, pageSize, keyword, handleData])

  const handleSearch = useCallback(() => {
    setPage({ current: 1 })
  }, [keyword])

  useEffect(loadList, [page, pageSize])

  return (
    <Block loading={loading}>
      <Row justify="space-between">
        <Col>签到列表</Col>
        <Col>
          <Input.Search
            value={keyword}
            style={{ width: 260 }}
            placeholder="输入订单号/用户名/手机号"
            onChange={e => setKeyword(e.target.value)}
            onSearch={handleSearch}
          />
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={list}
        style={{ marginTop: 15 }}
        pagination={false}
      />
      <Row justify="end">
        <Col>
          <Pagination
            current={page.current}
            total={total}
            showQuickJumper
            showSizeChanger
            pageSize={pageSize}
            pageSizeOptions={['10', '20', '50', '100']}
            onChange={(_page, _pageSize) => {
              if (_page !== page.current) setPage({current:_page})
              if (_pageSize !== pageSize) setPageSize(_pageSize!)
            }}
            style={{
              marginTop: 20,
              marginRight: 0
            }}
          />
        </Col>
      </Row>
      <Modal
        title="请填写原因"
        visible={visible}
        okText="确认"
        cancelText="取消"
        onOk={handleChangeStatus}
        onCancel={() => setVisible(false)}
      >
        <Input.TextArea value={reason} onChange={e => setReason(e.target.value)} />
      </Modal>
    </Block>
  )
}

export default List
