/*
 * @Author: your name
 * @Date: 2020-09-02 15:12:12
 * @LastEditTime: 2020-10-20 14:23:45
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/components/SelectWeek/style.ts
 */
import styled from 'styled-components'

export const Row = styled.div`
  width: 280px;
  display: flex;
  justify-content: space-between;
  position: relative;
`
export const Space = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`

export const Item = styled.div`
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 50%;
  background: #D4ECE5;
  color: rgba(0, 0, 0, 0.45);
  user-select: none;
  cursor: pointer;
`
