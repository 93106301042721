import React, { FC, useCallback, useEffect, useState } from 'react'
import { Block, InputBox } from 'components'
import { Button, Input, message, Modal, Select, Switch, Table } from 'antd'
import { administrator, item } from 'services'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch, RootState } from 'src/store'
import { Link, useHistory } from 'react-router-dom'
import { resourceColumn } from 'consts/administrators'
import { Column } from 'types/administrator'

const New: FC = () => {
  const { Option } = Select
  const history = useHistory()
  const { administrators: { form, tempList } } = useSelector((state: RootState) => state)
  const { setForm, setTempList } = useDispatch<Dispatch>().administrators
  const [resourceList, setResourceList] = useState<{ id: number, name: string }[]>()
  const [selectList, setSelectList] = useState<Column[]>()
  const [isOpen, setIsOpen] = useState<boolean>(false)

  useEffect(() => {
    (async () => {
      const data = await item.getSummaryList()
      setResourceList(data)
    })()
  }, [])

  const handleSubmitForm = useCallback(async () => {
    const payload = {
      ...form,
      resource_id_list: tempList && tempList.length ? tempList.map(item => item.id) : []
    }
    try {
      await administrator.addAdministrator(payload)
      message.success("添加成功")
      history.push(`/administrators/list`)
    } catch (err) {
      err.response && message.error(err.response.data.message)
    }

  }, [form, tempList])

  const handleSubmitResource = useCallback(() => {
    setTempList(selectList || [])
    setIsOpen(false)
  }, [selectList])

  const handleChangeList = useCallback((idList: number[]) => {
    const list: Column[] = []
    if (resourceList && resourceList.length) {
      resourceList.forEach(item => {
        idList.some(id => id === item.id) && list.push({
          ...item, handle:
            <div>
              <Link to="">
                <Button type="link">详情</Button>
              </Link>
              <Button type="link" onClick={() => { }}>删除</Button>
            </div>
        })
      })
    }
    setSelectList(list)
  }, [resourceList])

  const handleReset = useCallback(() => {
    setSelectList(tempList)
  }, [tempList])

  useEffect(() => {
    !isOpen && setSelectList(tempList)
  }, [isOpen])

  console.log(form);
  
  return (
    <div>
      <Block
        title="添加管理员"
        description={<Button type="primary" onClick={handleSubmitForm}>添加</Button>}
        style={{ paddingBottom: 24 }}
      >
        <InputBox title="姓名" style={{ marginTop: 24, marginLeft: 32 }}>
          <Input value={form.name} onChange={(e) => setForm({ ...form, name: e.target.value })} style={{ width: 224 }} />
        </InputBox>
        <InputBox title="账号" style={{ marginTop: 16, marginLeft: 32 }}>
          <Input value={form.account} onChange={(e) => setForm({ ...form, account: e.target.value })} style={{ width: 224 }} />
        </InputBox>
        <InputBox title="密码" style={{ marginTop: 16, marginLeft: 32 }}>
          <Input.Password value={form.password} onChange={(e) => setForm({ ...form, password: e.target.value })} style={{ width: 224 }} />
        </InputBox>
        <InputBox title="请选择管理员权限" style={{ marginTop: 16, marginLeft: 0 }}>
          <Select value={form.grade} style={{ width: 160 }} onChange={value => setForm({...form, grade: value})}>
            <Select.Option value={1}>普通管理员</Select.Option>
            <Select.Option value={2}>外部协助者</Select.Option>
          </Select>
        </InputBox>
        <InputBox title="联系电话" style={{ marginTop: 16 }}>
          <Input
            type="number"
            value={form.phone}
            onChange={(e) => setForm({ ...form, phone: Number(e.target.value) })}
            style={{ width: 224 }}
          />
        </InputBox>
        <InputBox title="微信通知" style={{ marginTop: 16 }}>
          <Switch
            checkedChildren="使用"
            unCheckedChildren="禁用"
            checked={form.is_use_wechat_message}
            onChange={(value) => setForm({ ...form, is_use_wechat_message: value })}
          />
        </InputBox>
        <InputBox title="备注" style={{ marginTop: 16, marginLeft: 32, alignItems: "normal" }}>
          <Input.TextArea
            value={form.description}
            style={{ width: 464 }}
            onChange={(e) => setForm({ ...form, description: e.target.value })}
          />
        </InputBox>
      </Block>
      <Block title="项目权限">
        <Button
          type="primary"
          onClick={() => { setIsOpen(true) }}
          style={{ marginTop: 24 }}
        >
          添加项目
        </Button>
        <Table
          dataSource={tempList}
          columns={resourceColumn}
          style={{ marginTop: 24 }}
        />
      </Block>
      <Modal
        visible={isOpen}
        onCancel={() => { setIsOpen(false) }}
        title="管理员预约项目管理权限"
        footer={
          <div>
            <Button onClick={handleReset}>重置</Button>
            <Button type="primary" onClick={handleSubmitResource}>提交</Button>
          </div>
        }
      >
        <InputBox title="预约项目">
          <Select
            value={selectList?.map(item => item.id)}
            mode="multiple"
            placeholder="请选择预约项目(可多选)"
            style={{ width: 240 }}
            onChange={handleChangeList}
          >
            {
              resourceList && resourceList.length ? resourceList.map(item => (
                <Option key={item.id} value={item.id}>{item.name}</Option>
              )) : null
            }
          </Select>
        </InputBox>
      </Modal>
    </div >

  )
}

export default New