/*
 * File: /src/pages/System/View/OrderRule/index.tsx
 * Author: Mxsyx (zsimline@163.com)
 * Create Time: Monday 2020-11-09 05:04:13
 * Last Modified: Monday 2020-11-09 05:04:13
 * Modified By: Mxsyx (zsimline@163.com>)
 * 
 * Copyright © 2020 https://www.joyreserve.com
 */
import React, { FC, Fragment } from 'react'
import { Header } from 'components'
import { BreadcrumbOption } from 'types/antd'
import Common from './Common'
import Private from './Private'

const breadcrumbs: BreadcrumbOption[] = [{
  name: "系统配置",
}, {
  name: "预约规则设置"
}]

const OrderRule: FC = () => {
  return (
    <Fragment>
      <Header
        title="预约规则设置"
        description="一句话介绍此功能模块"
        breadcrumbs={breadcrumbs}
      />
      <Common />
      <Private />
    </Fragment>
  )
}

export default OrderRule
