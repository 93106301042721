
import styled from 'styled-components'

export const Box = styled.div`
  position: absolute;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index:4;
`

export const PopoverHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`