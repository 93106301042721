/*
 * @Author: your name
 * @Date: 2020-09-02 10:02:38
 * @LastEditTime: 2020-11-18 10:50:20
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/pages/Order/View/style.ts
 */
import styled from 'styled-components'

export const Header = styled.div`
  margin-top: 8px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const Nav = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Title = styled.h2`
  margin-bottom: 0;
  font-size: 16px;
  color: rgba(0,0,0,0.85);
`

export const View = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  margin-top: 20px;
`

export const Item = styled.div`
  display: flex;
  flex-direction: column;
`

export const State = styled.div`
  display: flex;
  align-items: center;
  color: rgba(0,0,0,0.85);
  font-size: 16px;
  height: 70px;
  font-weight: 600;
`

export const BoxSpace = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  position: absolute;
`

export const NoneBox = styled.div`
  width: 148px;
  height: 74px;
  border: 1px solid rgba(0,0,0,0.05);
  position: relative;
  float: left;
`
