import React, { FC } from 'react'
import { Layout, Spin } from 'antd'

interface Props {
  loading?: boolean,
  bgColor?: string
}

const Container: FC<Props> = (props) => {
  const { loading = false, bgColor = '#FFFFFF', children } = props

  return (
    <Layout style={{
      backgroundColor: bgColor
    }}>
      <Spin spinning={loading}>
        {children}
      </Spin>
    </Layout>
  )
}

export default Container
