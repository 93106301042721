import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons/lib/icons";
import { Button, Checkbox, Col, Input, message, Modal, Popconfirm, Row, Select, Switch, Table } from "antd"
import { Block, InputBox } from "components"
import React, { FC, useCallback, useEffect, useState } from "react"
import { SortableContainer as SortableContainerOS, SortableElement } from 'react-sortable-hoc';
import { ModalBody, Title } from "./style"
import arrayMove from 'array-move'
import './style.css'
import { item, system } from "services";
import { useHistory, useParams } from "react-router";
import { addCustomOrderModalVac } from "utils/vac";
import { CustomOrderType, Entry } from "types/system";
import { customOrderDefaultModelForm, customOrderEditColumns, customOrderTypeList } from "consts/system";

const CustomOrderEdit: FC = () => {
  const SortableItem = SortableElement((props: any) => <tr {...props} />)
  const SortableContainer = SortableContainerOS((props: any) => <tbody {...props} />)
  const id = Number(useParams<{ id: string }>().id)
  const history = useHistory()
  const { Option, OptGroup } = Select
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [modelForm, setModelForm] = useState<Entry>(customOrderDefaultModelForm)
  const [onlineForm, setOnlineForm] = useState<CustomOrderType>()
  const [form, setForm] = useState<CustomOrderType>({
    name: "",
    resource_list: [],
    is_disable: false,
    entry_list: []
  })

  const [resourceList, setResourceList] = useState<{ id: number, name: string }[]>()

  useEffect(() => {
    (async () => {
      const data = await item.getSummaryList()
      setResourceList(data)
      if (id) {
        const data = await system.getCustomOrder({ id })
        const result = { ...data, entry_list: data.entry_list.map(item => ({ ...item, type: handleOnlineSwitch(item.type) })) }
        setOnlineForm(result)
        setForm(result)
      }
    })()
  }, [id])

  const handleClickDel = useCallback((itemId) => {
    const newData = JSON.parse(JSON.stringify(form))
    newData.entry_list.splice(newData.entry_list.findIndex((item: any) => item.id === itemId), 1)
    setForm(newData)
  }, [form])

  const handleForm = useCallback((data: Entry[]) => {
    if (!data || !data.length) return []
    return data.map(item => {
      return ({
        index: item.index,
        name: item.name,
        type: item.type,
        handle: <div style={{ marginLeft: -15 }}>
          <Button type="link" onClick={() => { handleClickEdit(item.index) }}>修改</Button>
          <Popconfirm title="确定删除" onConfirm={() => handleClickDel(item.id)}>
            <Button type="link">删除</Button>
          </Popconfirm>
        </div>
      })
    })
  }, [form])

  const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMove([...form.entry_list], oldIndex, newIndex).filter((el: any) => !!el);
      console.log('Sorted items: ', newData);
      setForm({ ...form, entry_list: newData });
    }
  }, [form])

  const DraggableBodyRow = useCallback(({ className, style, ...restProps }: any) => {
    const index = form.entry_list.findIndex(x => x.index === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  }, [form])

  const DraggableContainer = useCallback((props) => (
    <SortableContainer
      useDragHandle
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  ), [form])

  const handleClickEdit = useCallback((index: number) => {
    index === form.entry_list.length ?
      setModelForm({ ...customOrderDefaultModelForm, index }) :
      setModelForm(form.entry_list.filter(item => item.index === index)[0])
    setIsOpen(true)
  }, [form])

  const handleChangeModalInput = useCallback((e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newData = JSON.parse(JSON.stringify(modelForm))
    newData.options[index] = e.target.value
    setModelForm(newData)
  }, [modelForm])

  const handleRemoveModalOptions = useCallback((index: number) => {
    const newData = JSON.parse(JSON.stringify(modelForm))
    newData.options.splice(index, 1)
    setModelForm(newData)
  }, [modelForm])

  const submitModal = useCallback(async () => {
    if (addCustomOrderModalVac(modelForm)) return
    const newData = JSON.parse(JSON.stringify(form))
    const index = newData.entry_list.findIndex((item: { index: number }) => item.index === modelForm.index)
    index === -1 ?
      newData.entry_list.push(modelForm) :
      newData.entry_list.splice(index, 1, modelForm)
    setForm(newData)
    setIsOpen(false)
  }, [modelForm, form])

  const handleSwitch = (type: string) => {
    switch (type) {
      case "单选": return "single"
      case "多选": return "multiple"
      case "文字输入框": return "text"
      case "图片上传": return "image"
      case "手机号码": return "phone"
      case "身份证号": return "id_card"
      case "车牌号": return "license_plate"
      default: return type
    }
  }

  const handleOnlineSwitch = (type: string) => {
    switch (type) {
      case "single": return "单选"
      case "multiple": return "多选"
      case "text": return "文字输入框"
      case "image": return "图片上传"
      case "phone": return "手机号码"
      case "id_card": return "身份证号"
      case "license_plate": return "车牌号"
      default: return type
    }
  }

  const createThisForm = useCallback(async () => {
    const newData = JSON.parse(JSON.stringify(form))
    newData.entry_list = form.entry_list && form.entry_list.map((item: Entry, index: number) => ({
      ...item,
      index,
      type: handleSwitch(item.type),
      options: item.options ? item.options : []
    }))
    if (id) {
      try {
        await system.editCustomOrder({ ...newData, id })
        history.push('/system/custom_order_list')
        message.success("修改成功")
      } catch (err) {
        err.response && message.error(err.response.data.message)
      }
    } else {
      try {
        await system.createCustomOrder(newData)
        history.push('/system/custom_order_list')
        message.success("创建成功")
      } catch (err) {
        err.response && message.error(err.response.data.message)
      }
    }

  }, [form, id])

  return (
    <div>
      <Block
        showBack
        title="订单信息配置"
        style={{ paddingBottom: 32 }}
        description={!Number(id) ?
          <Button type="primary" onClick={createThisForm}>创建</Button> :
          <div>
            <Popconfirm title="您确定要放弃当前更改?" onConfirm={() => { setForm(onlineForm!) }}>
              <Button>重置</Button>
            </Popconfirm>
            <Button style={{ marginLeft: 8 }} type="primary" onClick={createThisForm}>保存</Button>
          </div>}
      >
        <Title style={{ margin: "32px 0 16px" }}> 基本信息
        <Switch
            checked={!form.is_disable}
            checkedChildren="使用"
            unCheckedChildren="禁用"
            onChange={(value) => { setForm({ ...form, is_disable: !value }) }}
            style={{ marginLeft: 10 }}
          />
        </Title>
        <InputBox title="订单信息名称">
          <Input value={form.name} style={{ width: 272 }} onChange={(e) => { setForm({ ...form, name: e.target.value }) }} />
        </InputBox>
        <InputBox style={{ marginTop: 16 }} title="针对预约项目">
          <Select
            mode="multiple"
            value={form.resource_list}
            style={{ width: 272 }}
            onChange={(value) => { setForm({ ...form, resource_list: value }) }}
          >
            {resourceList?.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
          </Select>
        </InputBox>
      </Block>
      <Block
        title="订单信息填写"
      >
        <Button type="primary" style={{ margin: "24px 0" }} onClick={() => handleClickEdit(form.entry_list.length)}>添加填写内容</Button>
        <Table
          pagination={false}
          dataSource={handleForm(form.entry_list)}
          columns={customOrderEditColumns}
          rowKey="index"
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow,
            },
          }}
        />
      </Block>
      <Modal
        visible={isOpen}
        onCancel={() => setIsOpen(false)}
        onOk={submitModal}
        title="添加填写内容"
      >
        <ModalBody>
          <InputBox title="题目名称" style={{ width: 320 }}>
            <Input value={modelForm.name} style={{ width: 224 }} onChange={(e) => { setModelForm({ ...modelForm, name: e.target.value }) }} />
          </InputBox>
          <InputBox title="题目类型" style={{ width: 320, marginTop: 16 }}>
            <Select style={{ width: 224 }} value={modelForm.type} onChange={(value: string) => { setModelForm({ ...modelForm, type: value }) }}>
              {customOrderTypeList.map(optItem =>
                <OptGroup label={optItem.name} key={optItem.name}>
                  {optItem.list.map((item: string) => <Option key={`${optItem.name}-${item}`} value={item}>{item}</Option>)}
                </OptGroup>
              )}
            </Select>
            {modelForm.type === "单选" || modelForm.type === "多选" ?
              <PlusCircleOutlined
                onClick={() => setModelForm({ ...modelForm, options: modelForm.options ? [...modelForm.options, ""] : [""] })}
                style={{ fontSize: 18, marginLeft: 10 }}
              /> : null
            }
          </InputBox>
          {(modelForm.type === "单选" || modelForm.type === "多选") && modelForm.options &&
            modelForm.options.length ? modelForm.options.map((item, index) => (
              <InputBox title={`选项${index + 1}`} key={index} style={{ marginTop: 16, marginLeft: 75 }}>
                <Input
                  value={item}
                  onChange={(e) => handleChangeModalInput(e, index)}
                  style={{ width: 224 }}
                />
                <MinusCircleOutlined onClick={() => handleRemoveModalOptions(index)} style={{ fontSize: 18, marginLeft: 10 }} />
              </InputBox>
            )) : null
          }
          {modelForm.type === "图片上传" ?
            <InputBox title={`最大张数`} style={{ width: 320, marginTop: 16 }}>
              <Input
                min={1}
                type="number"
                value={modelForm.number}
                onChange={(e) => setModelForm({ ...modelForm, number: Number(e.target.value) })}
                style={{ width: 224 }}
              />
            </InputBox>
            : null
          }
          <InputBox title="填写要求" style={{ width: 320, marginTop: 16 }}>
            <Row style={{ width: 244 }}>
              <Col>
                <label>
                  <Checkbox
                    checked={modelForm.is_require}
                    onChange={(e) => setModelForm({ ...modelForm, is_require: e.target.checked })}
                  />&nbsp;必填
              </label>
              </Col>
              <Col offset={1}>
                <label>
                  <Checkbox
                    checked={modelForm.is_cache}
                    onChange={(e) => setModelForm({ ...modelForm, is_cache: e.target.checked })}
                  />&nbsp;自动填充上次记录信息
                </label>
              </Col>
            </Row>
          </InputBox>
        </ModalBody>
      </Modal>
    </div>
  )
}



export default CustomOrderEdit