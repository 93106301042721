import { Button, Col, Input, message, Row } from 'antd'
import { Block, InputBox } from 'components'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { item, system } from 'services'
import { Rule } from 'services/system'
import { flatten } from 'utils/common'
import DisplayTimeRuleCard from '../../Model/displayTimeRuleCard'

const DisplayTimeCommon: FC = () => {
  const [render] = useState<number>(1)
  const [onlineData, setOnlineData] = useState<any>()
  const [data, setData] = useState<any>()
  const [resourceList, setResourceList] = useState<{ id: number, name: string }[]>()
  const [publicResourceList, setPublicResourceList] = useState<{ id: number, name: string }[]>()
  const [isLoading, setLoading] = useState<boolean>(true)
  const [isEdit, setIsEdit] = useState<boolean>(false)

  useEffect(() => {
    (async () => {
      const data = await item.getSummaryList()
      setResourceList(data)
    })()
  }, [])

  useEffect(() => {
    resourceList && resourceList.length && (async () => {
      setLoading(true)
      const data = await system.getDisplayTime()
      setLoading(false)
      setOnlineData(data)
      const result = {
        public: data.public,
        private: data && data.private && data.private.length ? data.private.map((item: Rule) => ({
          form: item,
          state: "display",
        })) : []
      }
      setData(result)
    })()
  }, [render, resourceList])

  //设置通用时间
  const handleClickSubmitPublic = useCallback(async () => {
    const newOnlineData = JSON.parse(JSON.stringify(onlineData))
    try {
      setLoading(true)
      await system.editCommonRule({ show_days: data.public })
      setLoading(false)
      newOnlineData.public = data.public
      setOnlineData(newOnlineData)
    } catch (err) {

    }
  }, [data, onlineData])

  //重置通用时间
  const handleClickResetPublic = useCallback(() => {
    const newData = JSON.parse(JSON.stringify(data))
    newData.public = onlineData.public
    setData(newData)
    setIsEdit(false)
  }, [data, onlineData])

  //为每个篮子分配对象
  const getCardResourceList = useCallback((group): { id: number, name: string }[] => {
    let result = []
    if (group && group.length) {
      result = group.map((id: number) => {
        return resourceList?.filter(item => item.id === id)[0]
      })
    }
    return (Array.from(new Set(result.concat(publicResourceList))) as any[]).sort((item1, item2) => item1.id - item2.id)
  }, [resourceList, publicResourceList])

  //添加新规则
  const handleClickSetButton = useCallback(async () => {
    message.success(`新增规则-自定义规则`)
    setData({
      ...data,
      private: [{
        state: "edit",
        form: {
          id: new Date().getTime() * -1,
          is_show_days: true,
          resource_id_list: [],
          rule_name: "自定义特殊规则",
          show_days: data.public
        }
      },
      ...data.private
      ]
    })
  }, [data])

  //改变卡片内容更新data
  const handleChangeCardForm = useCallback((rule: Rule) => {
    const newData = JSON.parse(JSON.stringify(data))
    const index = newData.private.findIndex((item: any) => item.form.id === rule.id)
    newData.private[index].form = rule
    setData(newData)
  }, [data])

  //点击删除卡片
  const handleClickDelete = useCallback(async (rule: Rule) => {
    const newData = JSON.parse(JSON.stringify(data))
    const index = data.private.findIndex((item: any) => item.form.id === rule.id)
    const newOnlineData = JSON.parse(JSON.stringify(onlineData))
    const onlineIndex = onlineData.private.findIndex((item: any) => item.id === rule.id)
    if (rule.id! < 0) {
      newData.private.splice(index, 1)
      message.success(`已删除规则-${rule.rule_name}`)
    } else {
      try {
        setLoading(true)
        await system.delRule({ id: rule.id! })
        newData.private.splice(index, 1)
        newOnlineData.private.splice(onlineIndex, 1)
        setData(newData)
        setOnlineData(newOnlineData)
        message.success(`已删除规则-${rule.rule_name}`)
      } catch (err) {
        err.response && message.error(err.response.data.message)
      } finally {
        setLoading(false)
      }
    }
    setData(newData)
  }, [data, onlineData])

  //点击修改卡片
  const handleClickEdit = useCallback((id: number) => {
    const newData = JSON.parse(JSON.stringify(data))
    const index = newData.private.findIndex((item: any) => item.form.id === id)
    newData.private[index].state = "edit"
    setData(newData)
  }, [data])

  //还原卡片
  const handleClickReset = useCallback((id: number) => {
    const newData = JSON.parse(JSON.stringify(data))
    const index = newData.private.findIndex((item: any) => item.form.id === id)
    if (id > 0) {
      const onlineIndex = onlineData.private.findIndex((item: any) => item.id === id)
      newData.private[index] = { state: "display", form: { ...onlineData.private[onlineIndex] } }
    } else {
      newData.private[index].form = {
        id: newData.private[index].form.id,
        is_show_days: true,
        resource_id_list: [],
        rule_name: "自定义特殊规则",
        show_days: onlineData.public
      }
    }
    setData(newData)
  }, [data, onlineData])

  //提交卡片
  const handleClickSubmit = useCallback(async (id: number) => {
    const newData = JSON.parse(JSON.stringify(data))
    const index = data.private.findIndex((item: any) => item.form.id === id)
    const newOnlineData = JSON.parse(JSON.stringify(onlineData))
    const onlineIndex = onlineData.private.findIndex((item: any) => item.id === id)
    const payload = { ...data.private[index].form }
    try {
      setLoading(true)
      if (id > 0) {
        await system.editRule(payload)
        newOnlineData.private[onlineIndex] = newData.private[index].form
      } else {
        delete payload.id
        const { id: newId } = await system.addNewRule(payload)
        newData.private[index].form.id = newId
        newOnlineData.private.push(newData.private[index].form)
      }
      message.success("操作成功")
      newData.private[index].state = "display"
      setOnlineData(newOnlineData)
      setData(newData)
    }
    catch (err) {
      err.response && message.error(err.response.data.message)
    } finally {
      setLoading(false)
    }
  }, [data, onlineData])

  //重新分配共用篮子
  useEffect(() => {
    if (!resourceList || !resourceList.length) return
    if (!data || !data.private || !data.private.length) {
      setPublicResourceList(resourceList)
      return
    }
    let loseArr = flatten(data.private.map((item: any) => item.form.resource_id_list))
    if (loseArr.length) {
      loseArr = Array.from(new Set(loseArr))
    }
    const result = [...resourceList]
    loseArr.forEach(loseId => {
      result.splice(result.findIndex(item => item.id === loseId), 1)
    })
    setPublicResourceList(result)
  }, [data, resourceList])

  return (
    <div>
      <Block
        showBack
        title="通用预约显示规则"
        style={{ paddingBottom: 24 }}
        loading={isLoading}
      >
        <Row justify="space-between">
          <Col>
            <InputBox title="设置可预约天数" style={{ marginTop: 24 }}>
              <Input
                type="number"
                value={data?.public}
                onChange={(e) => { isEdit && setData({ ...data, public: Number(e.target.value) }) }}
                style={{ width: 80, cursor: isEdit ? "" : "not-allowed" }}
              />
            </InputBox>
          </Col>
          <Col span={4} style={{ marginTop: 24 }}>
            {isEdit ?
              <div>
                <Button onClick={handleClickResetPublic}>重置</Button>
                <Button onClick={handleClickSubmitPublic} style={{ marginLeft: 8 }} type="primary">保存</Button>
              </div> :
              <Button onClick={() => { setIsEdit(true) }}>修改</Button>
            }
          </Col>
        </Row>
      </Block>
      <Block loading={isLoading} title="特殊预约显示规则" style={{ paddingBottom: 32 }}>
        <Button type="primary" onClick={handleClickSetButton} style={{ marginTop: 32 }}>添加预约规则</Button>
        <div>
          {
            data && data.private && data.private.length ? data.private.map((item: any) => (
              <DisplayTimeRuleCard
                key={item.id}
                resourceList={getCardResourceList(item.form.resource_id_list)}
                state={item.state}
                form={item.form}
                setForm={handleChangeCardForm}
                handleClickDelete={handleClickDelete}
                handleClickEdit={handleClickEdit}
                handleClickReset={handleClickReset}
                handleClickSubmit={handleClickSubmit}
              />
            )) : null
          }
        </div>
      </Block>
    </div >
  )
}

export default DisplayTimeCommon