/*
 * @Author: your name
 * @Date: 2020-09-25 15:32:16
 * @LastEditTime: 2020-11-30 19:12:09
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/consts/daily.ts
 */
export const timeFragmentColumns = [{
  key: 'order_id',
  dataIndex: 'order_id',
  title: '订单'
}, {
  key: 'name',
  dataIndex: 'name',
  title: '用户名称'
},{
  key: 'attend_number',
  dataIndex: 'attend_number',
  title: '序号'
}, {
  key: 'state',
  dataIndex: 'state',
  title: '签到状态'
}]