import React, { FC, useCallback, useState } from 'react'
import { utils, read, writeFile } from 'xlsx-oc/node_modules/xlsx'
import { Upload, message, Typography, Spin } from 'antd'
import { RcFile } from 'antd/lib/upload'
import { ContainerOutlined } from '@ant-design/icons'

interface ColumnMap {
  [index: string]: {
    name: string,
    default?: any
  }
}

function formatJSON(matrix: AnyObject[], mapping: ColumnMap) {
  const keys = Object.keys(mapping)
  return matrix.map(row => {
    const newRow: AnyObject = {}
    keys.forEach(key => {
      newRow[mapping[key].name] = row[key] || mapping[key].default || null
    })
    return newRow
  })
}

interface Props {
  mapping: ColumnMap,
  onChange: (matrix: AnyObject[]) => any,
  hasHeader?: boolean,
  type?: 'card' | 'dragger',
  loading?: boolean,
  showUploadList?: boolean
}

const XLSX: FC<Props> = (props) => {
  const { loading = false, showUploadList = true } = props
  const [disabled, setDisabled] = useState<boolean>(false)

  const beforeUpload = useCallback((file: RcFile) => {
    setDisabled(true)
    var reader = new FileReader()
    reader.onload = (e) => {
      if (!e.target || !e.target.result) return
      const workbook = read(e.target.result, { type: 'binary' })
      if (workbook.SheetNames.length === 0) message.error('🤥没有发现工作表')
      const firstWorksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonArr = utils.sheet_to_json(firstWorksheet, { header: "A", defval: null })
      const matrix = formatJSON(jsonArr as AnyObject[], props.mapping)
      props.onChange(props.hasHeader ? matrix.slice(1) : matrix)
    }
    reader.readAsBinaryString(file)
    return false
  }, [props])

  return (
    <Spin spinning={loading}>
      {
        props.type === 'dragger' ? (
          <Upload.Dragger
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            beforeUpload={beforeUpload}
            showUploadList={showUploadList}
          >
            <Typography>
              <ContainerOutlined style={{ color: "#30A694", fontSize: '30px' }} />
            </Typography>
            <Typography style={{ margin: '10px auto' }}>
              <Typography.Text strong>点击或将文件拖拽到这里上传</Typography.Text>
            </Typography>
            <Typography>
              <Typography.Text type="secondary">仅支持扩展名 .xlsx</Typography.Text>
            </Typography>
          </Upload.Dragger>
        ) : (
            <Upload
              showUploadList={showUploadList}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              beforeUpload={beforeUpload}
            >
              {props.children}
            </Upload>
          )
      }
    </Spin>
  )
}

export function exportExcel(data: AnyObject<string>[], name: string) {
  const book = utils.book_new();
  const sheet = utils.json_to_sheet(data)  
  utils.book_append_sheet(book, sheet)
  writeFile(book, `${name}.xlsx`)
}

export default XLSX
