import axios from 'axios'
import queryString from 'query-string'
import { HttpMethod, Options, Result } from '../../types/http'
import qs from 'qs'
import { handleError } from './handleError'

// 移动端管理员传递token
const { token } = qs.parse(window.location.search.replace('?', ''))
if (token) window.localStorage.setItem('token', token as string)

const service = axios.create({
  baseURL: process.env.REACT_APP_API + '/index.php',
  timeout: 180000,
  headers: {}
})

const request = (method: HttpMethod, url: string, options: Options) => service({
  method,
  url,
  data: options.type === 'form' ? queryString.stringify(options.data) : options.data,
  params: options.params,
  headers: {
    'content-type': options.type === 'form'
      ? 'application/x-www-form-urlencoded'
      : 'application/json',
    token: localStorage.getItem('token') || '',
    ...options.headers
  },
})

function get<T>(url: string, data: object = {}, options: object = { type: 'form' }) {
  return new Promise<T>((resolve, reject) => {
    request('GET', `${url}${JSON.stringify(data) === '{}' ? '' : '?'}${qs.stringify(data)}`, options)
      .then((res: { data: Result<T> }) => {
        if (res && res.data && res.data && (res.data.code === 200 || 201)) {
          resolve(res.data.data || undefined)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        handleError(err)
        reject(err)
      })
  })
}

function post<T>(url: string, data: object = {}, options: object = {}) {
  return new Promise<T>((resolve, reject) => {
    request('POST', url, { ...options, data })
      .then((res: { data: Result<T> }) => {
        if (res && res.data && res.data && (res.data.code === 200 || 201)) {
          resolve(res.data.data || undefined)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        handleError(err)
        reject(err)
      })
  })
}

function put<T>(url: string, data: object = {}, options: object = {}) {
  return new Promise<T>((resolve, reject) => {
    request('PUT', url, { ...options, data })
      .then((res: { data: Result<T> }) => {
        if (res && res.data && res.data && (res.data.code === 200 || 201)) {
          resolve(res.data.data || undefined)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        handleError(err)
        reject(err)
      })
  })
}

function del<T>(url: string, data: object = {}, options: object = {}) {
  return new Promise<T>((resolve, reject) => {
    request('DELETE', `${url}${JSON.stringify(data) === '{}' ? '' : '?'}${qs.stringify(data)}`, options)
      .then((res: { data: Result<T> }) => {
        if (res && res.data && res.data && (res.data.code === 200 || 201)) {
          resolve(res.data.data || undefined)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        handleError(err)
        reject(err)
      })
  })
}

export { get, post, put, del }
