import React, { FC, Fragment, useCallback, useEffect, useState } from 'react'
import { Block } from 'components'
import { Avatar, Button, Col, Descriptions, Form, Input, message, Popconfirm, Row, Select, Space, Spin, Statistic, Table } from 'antd'
import { Link, useParams } from 'react-router-dom'
import services from 'services'
import { UserOutlined } from '@ant-design/icons'
import { Detail as DetailType, Record } from 'types/user'
import { recordColumns } from 'consts/user'
import { FieldItem } from 'types/register'

function renderField(id: number, field: FieldItem) {
  switch (field.type) {
    case 1:
    case 1001: {
      return (
        <Col span={8}>
          <Form.Item key={field.id} label={field.title} name={id}>
            <Input />
          </Form.Item>
        </Col>
      )
    }
    case 2: {
      return (
        <Col span={8}>
          <Form.Item key={field.id} label={field.title} name={id}>
            <Select>
              {field.content.map((option, index) =>
                <Select.Option key={index} value={option}>{option}</Select.Option>
              )}
            </Select>
          </Form.Item>
        </Col>
      )
    }
    case 3: {
      return (
        <Col span={8}>
          <Form.Item key={field.id} label={field.title} name={id}>
            <Select mode="multiple">
              {field.content.map((option, index) =>
                <Select.Option key={index} value={option}>{option}</Select.Option>
              )}
            </Select>
          </Form.Item>
        </Col>
      )
    }
    default: {
      return <Col></Col>
    }
  }
}

const Detail: FC = () => {
  const params: { id: string } = useParams()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState<boolean>(true)
  const [editable, setEditable] = useState<boolean>(false)
  const [detail, setDetail] = useState<DetailType>()
  const [records, setRecords] = useState<Record[]>([])

  const loadDetail = useCallback(async () => {
    setLoading(true)
    try {
      const data = await services.user.getDetail(params.id)
      setDetail(data)
      const customFields: AnyObject = {}
      data.custom.map(field => customFields[field.id] = field.content || [])
      form.setFieldsValue({ phone: data.phone, username: data.name, ...customFields })
    } catch (error) {
      message.error('获取用户详情失败')
    } finally {
      setLoading(false)
    }
  }, [params.id, form])

  // 获取用户详情
  useEffect(() => {
    !editable && loadDetail()
  }, [editable, loadDetail])

  // 获取用户预约记录
  useEffect(() => {
    services.user.getRecords(params.id, { page: 1 })
      .then((data) => {
        const records = data.data.map(record => ({
          id: record.id,
          created: record.created,
          status: record.status,
          subject: record.subject,
          operation: <Link to={`/order/detail/${record.id}`}>查看详情</Link>
        }))
        setRecords(records)
      })
      .catch(() => {
      })
      .finally(() => {
        setLoading(false)
      })
  }, [params.id])

  const handleDelete = useCallback(async () => {
    if (!detail?.id) return
    setLoading(true)
    try {
      await services.user.delete({ id: detail.id })
      window.history.back()
    } catch (error) {
      message.error(`删除用户 ${detail.name} 失败`)
    } finally {
      setLoading(false)
    }
  }, [detail])

  const handleResetPwd = useCallback(() => {
    setLoading(true)
    services.user.resetPwd(params.id)
      .then(() => {
        message.success('重置密码成功！')
      })
      .catch(() => {
        message.error('重置密码失败~')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const handleSubmit = useCallback(() => {
    form.validateFields()
      .then(data => {
        const systemFields = {
          id: params.id,
          username: data.username,
          phone: data.phone
        }
        const customFields: AnyObject = {}
        Object.keys(data)
          .filter(key => Object.keys(systemFields).indexOf(key) === -1)
          .forEach(key => customFields[key] = data[key])

        setLoading(true)
        services.user.update({ ...systemFields, custom: customFields })
          .then(() => {
            message.success('更新用户信息成功！')
          })
          .catch(() => {
            message.error('更新用户信息失败~')
          })
          .finally(() => {
            setLoading(false)
          })
      })
      .catch(() => {
        message.warning('请正确填写表单！')
      })
      .finally(() => {
        setEditable(false)
      })
  }, [])

  return (
    <Fragment>
      <Block
        loading={loading}
        title="用户详情"
        showBack
        description={
          <Space>
            <Button onClick={handleResetPwd}>重置密码</Button>
            <Popconfirm 
              title="确定删除该用户吗？删除之后不可恢复" 
              okText="是" 
              cancelText="否" 
              onConfirm={handleDelete}
            >
              <Button>删除用户</Button>
            </Popconfirm>
            <Button onClick={editable ? handleSubmit : () => setEditable(true)}>
              {editable ? '保存' : '编辑'}
            </Button>
          </Space>
        }
        style={{ padding: '16px 32px' }}
      >
        {!editable ?
          <Row>
            <Col span={2}>
              <Avatar size={64} src={detail?.img_url} icon={<UserOutlined />} />
            </Col>
            <Col span={22}>
              <Descriptions title={detail?.name}>
                <Descriptions.Item label="联系方式">{detail?.phone}</Descriptions.Item>
                <Descriptions.Item label="注册时间">{detail?.register_time}</Descriptions.Item>
                {detail && detail.custom.map(field =>
                  <Descriptions.Item key={field.id} label={field.title}>
                    {Array.isArray(field.content) ? field.content.join('、') : field.content}
                  </Descriptions.Item>
                )}
              </Descriptions>
            </Col>
          </Row>
          :
          <Row>
            <Col span={2}>
              <Avatar size={64} src={detail?.img_url} icon={<UserOutlined />} />
            </Col>
            <Col span={22}>
              <Form form={form} style={{ display: 'flex' }} >
                <Row gutter={[12, 0]} style={{ width: '100%' }}>
                  <Col span={8}>
                    <Form.Item label="用户名" name="username">
                      <Input placeholder="请输入用户名" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="联系方式" name="phone">
                      <Input placeholder="请输入联系方式" />
                    </Form.Item>
                  </Col>
                  {detail && detail.custom.map(field => renderField(field.id, field.rule))}
                </Row>
              </Form>
            </Col>
          </Row>
        }
      </Block>
      <Block title="账户信息" loading={loading}>
        <Row>
          <Col span={8}>
            <Statistic
              title="累计预约次数"
              value={detail?.appointment_all}
              style={{
                padding: '16px 0px',
                textAlign: 'center'
              }}
            />
          </Col>
          <Col span={8}>
            <Statistic
              title="本月预约次数"
              value={detail?.appointment_month}
              style={{
                padding: '16px 0px',
                textAlign: 'center'
              }}
            />
          </Col>
        </Row>
      </Block>
      <Block title="预约记录">
        <Table
          loading={loading}
          columns={recordColumns}
          dataSource={records}
          style={{ marginTop: '20px' }}
        />
      </Block>
    </Fragment>
  )
}

export default Detail
