/*
 * @Author: your name
 * @Date: 2020-10-23 15:56:45
 * @LastEditTime: 2020-11-13 18:17:24
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/utils/common.ts
 */

import { TabOption } from "types/antd"

/**
 * 获取随机字符串
 * @param len 随机字符串长度
 */
export const getRandomString = (len: number = 8): string => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
  let pwd = ''
  for (let i = 0; i < len; i++) {
    pwd += chars.charAt(Math.floor(Math.random() * 52))
  }
  return pwd
}

/**
 * 延迟执行异步函数
 * 停止操作wait毫秒后执行异步函数
 * @param fn 待执行的函数
 * @param wait 延迟时间
 */
export function debounce(fn: Function, wait: number) {
  let timer: number

  return function () {
    clearTimeout(timer)
    return new Promise(resolve => {
      timer = setTimeout(async () => {
        // @ts-ignore
        const res = await fn.apply(null, arguments)
        resolve(res)
      }, wait)
    })
  }
}

/**
 * 解码存储在token中的系统信息
 */
export function getSystemInfo() {
  const token = localStorage.getItem('token') || '.'
  return JSON.parse(atob(token.split('.')[1]))
}

/**
 * 对比复杂类型是否相等
 */
export function advancedContras(..._args: any) {
  if (arguments.length < 2) return false
  let result = true
  for (let index = 1; index < arguments.length; index++) {
    if (JSON.stringify(arguments[0]) !== JSON.stringify(arguments[index])) {
      result = false
      break
    }
  }
  return result
}

type TransRecord = Record<string | number, string | number>
/**
 * 翻转Object的key/value
 */
export function transObj(obj: TransRecord): TransRecord {
  return Object.keys(obj).reduce((acc, key) => {
    // @ts-ignore
    acc[obj[key]] = key;
    return acc;
  }, {})
}

/**
 * 数组扁平化
 */
export function flatten(arr: any[]) {
  let result: any[] = []
  for (let i = 0, l = arr.length; i < l; i++) {
    if (Array.isArray(arr[i])) {
      //递归调用
      result = result.concat(flatten(arr[i]))
    } else {
      result.push(arr[i])
    }
  }
  return result
}

/**
 * 决定对数组插入或是删除
 */
export function handlePush(value: any, array?: any[]) {
  if (!array || !array.length) {
    return [value]
  }
  const newArray: any[] = JSON.parse(JSON.stringify(array))
  const index = newArray.findIndex(item => item === value)
  if (index !== -1) {
    newArray.splice(index, 1)
    return newArray
  } else {
    return [...array, value]
  }
}
/**
 * 获取当前路由对应的tabKey
 * @param tabs tab列表
 * @param pathname 当前路由路径
 * @param fallback 未找到对应的tabKey时的默认值
 */
export function getSelectTabKey(tabs: TabOption[], pathname: string, fallback: string = '0') {
  const index = tabs.findIndex((tab) => tab.link === pathname)
  return index === -1 ? fallback : tabs[index].key
}
