import React, { FC, useCallback, useEffect } from 'react'
import { RichTextWrapper } from './style'

interface Props {
  value?: string,
  onChange?: (value?: string) => void,
  defaultValue?: string
}

const RichText: FC<Props> = (props) => {
  const handleInput = useCallback(() => {
    if (props.onChange) {
      const dom = document.getElementById('rich-text') as HTMLDivElement      
      props.onChange(dom.innerHTML || '')
    }
  }, [props])

  useEffect(() => {
    const dom = document.getElementById('rich-text') as HTMLDivElement
    dom.innerHTML = props.defaultValue || ''
  }, [props.defaultValue])

  return (
    <RichTextWrapper
      id="rich-text"
      contentEditable
      onInput={handleInput}
    ></RichTextWrapper>
  )
}

export default RichText
