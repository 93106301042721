/*
 * @Author: your name
 * @Date: 2020-09-28 10:03:22
 * @LastEditTime: 2020-10-13 15:22:23
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/pages/Manual/View/style.ts
 */
import styled from "styled-components"


export const HeaderRow = styled.div`
  display: flex;
`

export const TitleList = styled.div`
  display: flex;
  margin-left: 43px;
  margin-top: 20px;
`

export const Title = styled.div`
  width: 148px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  padding: 10px;
  font-weight: 600;
`

export const BoxSpace = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  position: absolute;
`

export const NoneBox = styled.div`
  width: 148px;
  height: 74px;
  border: 1px solid rgba(0,0,0,0.05);
  position: relative;
  float: left;
`