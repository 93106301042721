import { Modal } from "antd"
import React, { FC, ReactNode } from "react"

interface ModalType {
  title: string,
  isOpen: boolean,
  onClose: () => void
  callBack: (type: string) => void,
  children: ReactNode,
}

const EditModalConfirm: FC<ModalType> = (props) => {
  const { isOpen, callBack, onClose, children, title } = props

  return (
    <Modal
      visible={isOpen}
      title={title}
      okText="确认"
      cancelText="取消"
      onOk={() => { callBack("multiple") }}
      onCancel={() => { onClose() }}
    >
      {children}
    </Modal >
  )
}

export default EditModalConfirm