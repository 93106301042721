import { Alert, Button, Divider, message, Modal } from 'antd'
import Container from 'components/mobile/Container'
import HashItem from 'components/mobile/HashItem'
import moment from 'moment'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import services from 'services'
import { Queue as QueueType } from 'services/order'
import { Box, Title } from './style'


const Queue: FC = () => {
  const { id } = useParams<{ id: string }>()
  const [queue, setQueue] = useState<QueueType[]>([])
  const [visible, setVisible] = useState<boolean>(false)
  const [selected, setSelected] = useState<number>(0)

  useEffect(() => {
    (async () => {
      services.order.getQueue({ id })
        .then(data => {
          setQueue(data.orders)
        })
    })()
  }, [id])

  const selectJump = useCallback(() => {
    services.order.action({
      action: '插队',
      id: [id],
      payload: { type: 'replace', num: selected }
    })
      .then(() => {
        window.history.back()
        message.success('插队成功')
      })
      .catch(() => {
        message.error('插队失败')
      })
  }, [selected,id])

  const directJump = useCallback(() => {
    services.order.action({
      action: '插队',
      id: [id],
      payload: { type: 'direct' }
    })
      .then(() => {
        window.history.back()
        message.success('插队成功')
      })
      .catch(() => {
        message.error('插队失败')
      })
  }, [id])

  return (
    <Container bgColor="F5F6F8">
      <Alert
        message="本时段已无剩余可预约名额，请选择直接插队"
        type="warning"
        showIcon
      />
      <Box>
        <div>预约项目：{queue[0]?.subject}</div>
        <Divider style={{ margin: '8px auto' }} />
        <div>下单时间：{moment(queue[0]?.created).format('YYYY-MM-DD')}</div>
      </Box>
      {
        queue.map(item =>
          <Box onClick={() => {setSelected(item.num);setVisible(true)}}>
            <Title>{`序号${item.num} ${item.supplier}`}</Title>
            <HashItem name="用户名称：">{item.name}</HashItem>
            <HashItem name="联系方式：">{item.cellphone}</HashItem>
            <HashItem name="供应商类型：">{item.supplier_type}</HashItem>
          </Box>
        )
      }
      <Box style={{ textAlign: 'center' }}>
        <Button
          type="primary"
          size="large"
          style={{ width: '90%' }}
          onClick={directJump}
        >直接插队</Button>
      </Box>
      <Modal visible={visible} okText="确认" cancelText="取消" onCancel={() => setVisible(false)} onOk={selectJump}>
        是否插队
      </Modal>
    </Container>
  )
}

export default Queue
