/*
 * File: /src/components/RichText/style.ts
 * Author: Mxsyx (zsimline@163.com)
 * Create Time: Friday 2020-11-27 12:41:05
 * Last Modified: Friday 2020-11-27 12:41:08
 * Modified By: Mxsyx (zsimline@163.com>)
 * 
 * Copyright © 2020 https://www.joyreserve.com
 */
import styled from 'styled-components'

export const RichTextWrapper = styled.div`
  border: 1px solid #d9d9d9;
  padding: 4px 11px;
  border-radius: 3px;
  min-height: 70px;
  max-height: 300px;
  overflow: scroll;
`
