/*
 * File: /src/pages/System/View/OrderRule/Rule.tsx
 * Author: Mxsyx (zsimline@163.com)
 * Create Time: Tuesday 2020-11-10 10:47:17
 * Last Modified: Tuesday 2020-11-10 10:47:18
 * Modified By: Mxsyx (zsimline@163.com>)
 * 
 * Copyright © 2020 https://www.joyreserve.com
 */
import { Row, Col, Tree } from "antd"
import React, { FC } from "react"
import { Input } from "./style"

export interface StorePorps {
  '0-0-T': [number, number, number],
  '0-0-D': [number, number, number],
  '0-1-T': [number, number, number],
  '0-1-D': [number, number, number],
  '0-2-T': [number, number, number],
  '0-2-D': [number, number, number]
}

export type Checks = ['T' | 'D', 'T' | 'D', 'T' | 'D']

export const mapping: AnyObject<string> = {
  '0-0-T': '0-0-D',
  '0-0-D': '0-0-T',
  '0-1-T': '0-1-D',
  '0-1-D': '0-1-T'
}

export function toSecond(second: number): [number, number, number] {
  return [
    Math.floor(second / 86400),
    Math.floor(second % 86400 / 3600),
    Math.floor(second % 3600 / 60)
  ]
}

export function toDHM(dhm: [number, number, number]): number {
  return dhm[0] * 86400 + dhm[1] * 3600 + dhm[2] * 60
}

interface InputItemProps {
  type: 'T' | 'D',
  onChange: (value: [number, number, number]) => void,
  value: [number, number, number],
  editable?: boolean
}

interface RuleProps {
  store: StorePorps
  onChange: (key: keyof StorePorps, value: [number, number, number]) => void,
  onCheck: (value: string[]) => void,
  checks: string[],
  editable?: boolean
}

const InputItem: FC<InputItemProps> = (props) => {
  return (
    <Row>
      <Col>{props.type === 'T' ? '提前预约时段' : '提前预约日期'}</Col>
      <Col>
        <Input
          type="number"
          bordered={false}
          value={props.value[0]}
          disabled={props.editable}
          onChange={(e) => {
            props.value[0] = Math.abs(parseInt(e.target.value))
            props.onChange(props.value)
          }}
        />
      </Col>
      <Col>天</Col>
      <Col>
        <Input
          type="number"
          bordered={false}
          value={props.value[1]}
          disabled={props.editable}
          onChange={(e) => {
            props.value[1] = Math.abs(parseInt(e.target.value))
            props.onChange(props.value)
          }}
        />
      </Col>
      <Col>小时</Col>
      <Col>
        <Input
          type="number"
          bordered={false}
          value={props.value[2]}
          disabled={props.editable}
          onChange={(e) => {
            props.value[2] = Math.abs(parseInt(e.target.value))
            props.onChange(props.value)
          }}
        />
      </Col>
      <Col>分钟</Col>
    </Row>
  )
}

const Rule: FC<RuleProps> = (props) => {
  const treeData = [
    {
      title: '预约开始时间',
      key: '0-0',
      checkable: false,
      children: [
        {
          title:
            <InputItem
              editable={props.editable}
              type="T"
              value={props.store['0-0-T']}
              onChange={(value) => props.onChange('0-0-T', value)}
            />,
          key: '0-0-T'
        },
        {
          title:
            <InputItem
              editable={props.editable}
              type="D"
              value={props.store['0-0-D']}
              onChange={(value) => props.onChange('0-0-D', value)}
            />,
          key: '0-0-D'
        },
      ]
    },
    {
      title: '预约截止时间',
      key: '0-1',
      checkable: false,
      children: [
        {
          title:
            <InputItem
              editable={props.editable}
              type="T"
              value={props.store['0-1-T']}
              onChange={(value) => props.onChange('0-1-T', value)}
            />,
          key: '0-1-T'
        },
        {
          title: 
            <InputItem
              editable={props.editable} 
              type="D"
              value={props.store['0-1-D']}
              onChange={(value) => props.onChange('0-1-D', value)}
            />,
          key: '0-1-D'
        },
      ]
    },
    {
      title: '预约取消时间',
      key: '0-2',
      checkable: false,
      children: [
        {
          title: 
            <InputItem 
              editable={props.editable} 
              type="T"
              value={props.store['0-2-T']}
              onChange={(value) => props.onChange('0-2-T', value)} 
            />,
          key: '0-2-T'
        },
        {
          title: 
            <InputItem
              editable={props.editable} 
              type="D"
              value={props.store['0-2-D']}
              onChange={(value) => props.onChange('0-2-D', value)}
            />,
          key: '0-2-D'
        },
      ]
    },
  ]

  return (
    <Tree
      disabled={props.editable}
      checkable
      treeData={treeData}
      checkedKeys={props.checks}
      onCheck={(value) => props.onCheck(value as string[])}
    />
  )
}

export default Rule
