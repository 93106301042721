import React, { FC } from 'react'
import { Space, Tag } from 'antd'
import { Link } from 'react-router-dom'
import { Box, Title, Meta } from './style'

interface Props {
  title: string,
  status: string,
  id: string,
  date: string,
  username: string,
  phone: string,
  color: string
}

const OrderCard: FC<Props> = (props) => {
  const { title, status, id, date, username, color, phone } = props
  return (
    <Link to={`/order/detail/${id}`}>
      <Box>
        <Title>
          <Space size={6}>
            {title} <Tag color={color}>{status}</Tag>
          </Space>
        </Title>
        <Meta>订单编号：#{id}</Meta>
        <Meta>下单时间：{date}</Meta>
        <Meta>用户姓名：{username}</Meta>
        <Meta>联系电话：{phone}</Meta>
      </Box>
    </Link>
  )
}

export default OrderCard
