import http from '../utils/http'
import { Notice } from 'types/notice'

export default {
  // 创建公告
  create: (payload: Payload.Create) => http.post<any>('/Admin/notices', payload),
  // 删除公告
  delete: (id: number) => http.del<any>(`/Admin/notices/${id}`),
  // 更新公告
  update: (id: number, payload: Payload.Create) => http.put<any>(`/Admin/notices/${id}`, payload),
  // 获取公告列表
  getList: (payload: PagePayload) => http.get<DataList<Notice>>('/Admin/notices', payload),
}

declare namespace Payload {
  export interface Create {
    title: string,
    content: string,
    open: boolean,
    rule: string,
    cycle: number
  }
}
