import { Button, Checkbox, Popconfirm, Popover, Table, Tooltip } from "antd"
import { InputBox } from "components"
import { getWeekTableRange } from "components/WeekTable/utils"
import { timeFragmentColumns } from "consts/daily"
import React, { FC, useCallback, useRef, useState } from "react"
import { Link } from "react-router-dom"
import { TimeFragment } from "services/dilay"
import { weekItem } from "types/time"
import { timeDoubleFormat } from "utils/time"
import RejectModal from "../RejectModal"
import { Box as Border, PopoverHeader, Order } from "./style"

interface BoxType {
  selectTimeFragment: string,
  type: string,
  selectResourceList?: number[] | undefined,
  weekList?: weekItem[],
  data: TimeFragment,
  onClick: (key: string) => void,
  selectRowIdList: React.ReactText[],
  onChange: (selectRowIdList: React.ReactText[]) => void,
  onSubmit: () => void,
  onCancel: () => void,
}

const Box: FC<BoxType> = (props) => {
  const border = useRef(null)
  const { data, selectResourceList, selectTimeFragment,
    selectRowIdList, onChange, type, weekList, onCancel
  } = props
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const getSecond = useCallback((time: string) => {
    return (Number(time.split(":")[0]) * 60) + Number(time.split(":")[1])
  }, [])

  const switchStateColor = useCallback((state: string) => {
    let primary = ""
    let secondary = ""
    switch (state) {
      case "empty":
        primary = "#1890FF"
        secondary = "#E6F7FF"
        break
      case "usable":
        primary = "#52C41A"
        secondary = "#F6FFED"
        break
      case "full":
        primary = "#FAAD14"
        secondary = "#FFFBE6"
        break
      case "disable":
        primary = "#ddd"
        secondary = "#fff"
        break
      default:
        primary = ""
        secondary = ""
        break
    }
    return { primary, secondary }
  }, [])

  const [startTime, endTime] = data.time.split("-")
  const startTimeLength = getSecond(startTime)
  const endTimeLength = getSecond(endTime)
  const timeLength = endTimeLength - startTimeLength
  let key = ""
  const { primary, secondary } = switchStateColor(data.color)
  let timeLengthIndex = 0
  if (type === "resource") {
    key = data.resource_id.toString() + data.id.toString()
    selectResourceList!.sort((a, b) => a - b).forEach((selectResource, index) => {
      if (selectResource === Number(data.resource_id)) {
        timeLengthIndex = index
      }
    })
  }
  if (type === "time") {
    key = data.date + data.resource_id.toString() + data.id.toString()
    weekList!.forEach((week, index) => {
      if (`${week.year}-${timeDoubleFormat(Number(week.month))}-${timeDoubleFormat(Number(week.day))}` === data.date) {
        timeLengthIndex = index
      }
    })
  }

  const top = getWeekTableRange(startTimeLength)
  const height = getWeekTableRange(timeLength)
  const left = timeLengthIndex * 148
  const isSelect = !!(selectTimeFragment === key)
  const userList = data.user_list.map(item => ({
    ...item,
    order_id: (
      <Order>
        <Tooltip title={item.order_id}>
          <Link to={`/order/detail/${item.order_id}`}>
            {item.order_id}
          </Link>
        </Tooltip>
      </Order>
    ),
    key: item.order_id
  }))
  const displayInfoList = []
  for (let name in data.display_list) {
    displayInfoList.push(<InputBox title={name}>{data.display_list[name]}</InputBox>)
  }
  return (
    <React.Fragment>
      <Popover
        visible={isSelect}
        content={
          <div>
            <div>预约信息</div>
            <div>
              {displayInfoList}
            </div>
            <Table
              dataSource={userList}
              columns={timeFragmentColumns}
              pagination={false}
              rowSelection={{
                selectedRowKeys: selectRowIdList,
                onChange: selectedRowKeys => { onChange(selectedRowKeys) }
              }}
              style={{
                width: 540,
                marginTop: 10,
              }}
            />
          </div>
        }
        placement="right"
        title={
          <PopoverHeader>
            <div>{data.time}</div>
            <div>
              <Popconfirm
                title="将选中订单状态变为已签到"
                onConfirm={props.onSubmit}
                okText="确认签到"
                disabled={!selectRowIdList.length}
              >
                {/* <Button disabled={!selectRowIdList.length}>签到</Button> */}
              </Popconfirm>
              <Button disabled={!selectRowIdList.length} style={{ marginLeft: 8 }} onClick={() => setIsOpen(true)}>取消</Button>
            </div>
          </PopoverHeader >
        }
        trigger="hover"
      >
        <Border
          ref={border}
          tabIndex={-1}
          onFocus={() => { data.state !== "不可用" && (data.max_load + 1) >= data.use_load && props.onClick(key) }}
          onBlur={() => { console.log('b') }}
          key={key}
          style={isSelect ? {
            width: 158,
            top: top - 5,
            left: left - 5,
            height: height + 10,
            zIndex: 6,
            background: "#fff",
            color: primary,
            border: `1px solid ${primary}`
          } : {
              width: 148,
              top,
              left,
              height,
              background: secondary,
              color: primary,
              border: `1px solid ${primary}`
            }
          }
        >
          {isSelect ?
            <Checkbox
              checked
              style={{ right: 8, top: 8, position: "absolute" }}
            /> :
            null
          }
          <div>({data.use_load}/{data.max_load}) </div>
          <div> {data.state} </div>
        </Border>
      </Popover>
      <RejectModal
        isOpen={isOpen}
        onCancel={() => setIsOpen(false)}
        onConfirm={() => {
          setIsOpen(false)
          onCancel()
        }}
        selectRowIdList={selectRowIdList}
      />
    </React.Fragment>
  )
}

export default Box