import { getWeekTableRange } from "components/WeekTable/utils"
import React, { FC, useCallback } from "react"
import { TimeFragment } from "types/app"
import { weekItem } from "types/time"
import { timeDoubleFormat } from "utils/time"
import { Box as _Box } from "./style"

interface BoxType {
  data: TimeFragment,
  weekList: weekItem[],
  isSelect: boolean,
  onClick: (display_id: string) => void
}

const Box: FC<BoxType> = (props) => {
  const { data, weekList, onClick, isSelect } = props
  const getSecond = useCallback((time: string) => {
    return (Number(time.split(":")[0]) * 60) + Number(time.split(":")[1])
  }, [])

  const switchStateColor = useCallback((state: string) => {
    let primary = ""
    let secondary = ""
    switch (state) {
      case "empty":
        primary = "#1890FF"
        secondary = "#E6F7FF"
        break
      case "usable":
        primary = "#52C41A"
        secondary = "#F6FFED"
        break
      case "full":
        primary = "#FAAD14"
        secondary = "#FFFBE6"
        break
      case "disable":
        primary = "#ddd"
        secondary = "#fff"
        break
      default:
        primary = ""
        secondary = ""
        break
    }
    return { primary, secondary }
  }, [])

  const [startTime, endTime] = data.time.split("-")
  const startTimeLength = getSecond(startTime)
  const endTimeLength = getSecond(endTime)
  const timeLength = endTimeLength - startTimeLength
  const key = data.resource_id.toString() + data.id.toString()
  const { primary, secondary } = switchStateColor(data.color)
  const top = getWeekTableRange(startTimeLength)
  const height = getWeekTableRange(timeLength)
  const left = 148 * weekList.findIndex(item =>
    `${item.year}-${timeDoubleFormat(Number(item.month))}-${timeDoubleFormat(Number(item.day))}` === data.date
  )

  const handleClick = useCallback((display_id) => {
    if (
      data.state === "已过期" ||
      data.state === "不可用" ||
      data.state === "已约满"
    ) return
    onClick(display_id)
  }, [onClick, data.state])

  return (

    <_Box
      onClick={() => handleClick(data.display_id)}
      key={key}
      style={isSelect ? {
        width: 158,
        top: top - 5,
        left: left - 5,
        height: height + 10,
        zIndex: 6,
        background: "#fff",
        color: primary,
        border: `1px solid ${primary}`,
        boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.15)"
      } : {
          width: 148,
          top,
          left,
          height,
          background: secondary,
          color: primary,
          border: `1px solid ${primary}`
        }
      }
    >
      <div>({data.use_load}/{data.max_load}) </div>
      <div> {data.state} </div>
    </_Box>
  )
}

export default Box