import http from 'utils/http'
import { Car } from 'types/car'

const resource = '/Scene/cars'

export default {
  // 添加车辆
  create: (payload: Payload.Create) => http.post<any>(resource, payload),
  // 删除车辆
  delete: (id: number) => http.del<any>(`${resource}/${id}`),
  // 获取车辆列表
  getList: (payload: QueryPagePayload) => http.get<DataList<Car>>(resource, payload),
}

declare namespace Payload {
  export type Create = {
    cars: {
      lpn: string,
      supplier: string
    }[]
  }
}
