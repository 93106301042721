import styled from 'styled-components'

export const Header = styled.div`
  display: flex;
`

export const TitleList = styled.div`
  display: flex;
  margin-left: 43px;
  margin-top: 20px;
`

export const Title = styled.div`
  width: 148px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  padding: 10px;
  font-weight: 600;
`

export const NoneBoxList = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const NoneBox = styled.div`
  width: 148px;
  height: 74px;
  border: 1px solid rgba(0,0,0,0.05);
  position: relative;
`

export const Box = styled.div`
  width: 148px;
  height: 74px;
  border: 1px solid rgba(0,0,0,0.05);
  position: relative;
  float: left;
`

export const BoxSpace = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  position: absolute;
`