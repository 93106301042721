import React, { FC } from 'react'
import { Route } from 'react-router-dom'
import CardList from './View/CardList'
import Detail from './View/Detail'

const Time: FC = () => {

  return (
    <div>
      <Route path='/time/list' render={() => <CardList />} />
      <Route path='/time/detail/:cardId' render={() => <Detail />} />
    </div>
  )
}

export default Time