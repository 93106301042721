/*
 * @Author: your name
 * @Date: 2020-10-15 20:31:59
 * @LastEditTime: 2020-10-26 16:16:01
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/consts/administrators.ts
 */
export const administratorsColumn = [{
  key: "name",
  dataIndex: "name",
  title: "管理员姓名",
}, {
  key: "account",
  dataIndex: "account",
  title: "系统账号",
}, {
  key: "grade",
  dataIndex: "grade",
  title: "职位",
}, {
  key: "create_time",
  dataIndex: "create_time",
  title: "添加时间",
}, {
  key: "phone",
  dataIndex: "phone",
  title: "联系方式",
}, {
  key: "handle",
  dataIndex: "handle",
  title: "操作",
}]

export const resourceColumn = [{
  key: "name",
  dataIndex: "name",
  title: "预约项目"
}, {
  key: "handle",
  dataIndex: "handle",
  title: "操作"
}]