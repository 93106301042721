import React, { FC, useCallback, useEffect, useState } from 'react'
import { Button, Col, DatePicker, Form, Input, message, Pagination, Row, Space, Table } from 'antd'
import { Block } from 'components'
import { Link } from 'react-router-dom'
import services from 'services'
import { Brief } from 'types/user'
import { Title } from './style'
import { SearchOutlined } from '@ant-design/icons'
import AddUser from '../Model/AddUser'
import ImportUser from '../Model/ImportUser'
import moment from 'moment'
import { exportExcel } from 'components/XLSX'

const columns = [{
  key: 'name',
  dataIndex: 'name',
  title: '用户姓名'
}, {
  key: 'phone',
  dataIndex: 'phone',
  title: '联系方式'
}, {
  key: 'order_time',
  dataIndex: 'order_time',
  title: '最近一次预约时间'
}, {
  key: 'operation',
  dataIndex: 'operation',
  title: '操作'
}]

const List: FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [list, setList] = useState<any[]>([])
  const [keyword, setKeyword] = useState<string>('')
  const [addUserVisible, setAddUserVisible] = useState<boolean>(false)
  const [importUserVisible, setImportUserVisible] = useState<boolean>(false)
  const [page, setPage] = useState<{current: number}>({current: 1})
  const [pageSize, setPageSize] = useState<number>(20)
  const [total, setTotal] = useState<number>(0)
  const [reserveTime, setReserveTime] = useState<string[]>([])
  const [orderTime, setOrderTime] = useState<string[]>([])

  const handleData = useCallback((data: Brief[]) => {
    return data.map((item) => ({
      key: item.id,
      name: item.name,
      phone: item.phone,
      order_time: item.order_time,
      operation: <Link to={`/user/detail/${item.id}`}>查看详情</Link>
    }))
  }, [])

  const loadList = useCallback(() => {
    setLoading(true)
    services.user.getList({
      page: page.current,
      page_size: pageSize,
      q: keyword,
      reserve_time: reserveTime,
      order_time: orderTime
    })
      .then((data) => {
        setList(handleData(data.list))
        setTotal(data.total)
      })
      .catch(() => {
        message.error('获取用户列表失败')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [page, pageSize, keyword, reserveTime, orderTime, setList])

  useEffect(loadList, [page, pageSize])

  const resetInput = useCallback(() => {
    setKeyword('')
    setReserveTime([])
    setOrderTime([])
  }, [])

  const handleExport = useCallback(() => {
    setLoading(true)
    const hide = message.loading('正在导出Excel...')
    services.user.export()
      .then(data => {
        exportExcel(data, '用户列表')
      })
      .catch(() => {
        message.error('导出用户列表时遇到不可预知的错误~')
      })
      .finally(() => {
        hide()
        setLoading(false)
      })
  }, [])

  const handleChangeTime = useCallback((type: 'rtime' | 'otime', value: any) => {
    if (type === 'rtime') {
      setReserveTime(value === null ? [] : [
        value[0].format('YYYY-MM-DD'),
        value[1].format('YYYY-MM-DD')
      ])
    } else {
      setOrderTime(value === null ? [] : [
        value[0].format('YYYY-MM-DD'),
        value[1].format('YYYY-MM-DD')
      ])
    }
  }, [])

  return (
    <Block loading={loading}>
      <Row gutter={[12, 20]} >
        <Col span={8}>
          <Form.Item label="搜索">
            <Input
              placeholder="请输入关键字"
              suffix={<SearchOutlined />}
              onChange={e => setKeyword(e.target.value)}
              value={keyword}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="预约日期">
            <DatePicker.RangePicker
              picker="date"
              value={reserveTime.length ? [moment(reserveTime[0]), moment(reserveTime[1])] : null}
              onChange={(value) => handleChangeTime('rtime', value)}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="下单日期">
            <DatePicker.RangePicker
              picker="date"
              value={orderTime.length ? [moment(orderTime[0]), moment(orderTime[1])] : null}
              onChange={(value) => handleChangeTime('otime', value)}
            />
          </Form.Item>
        </Col>
        <Col span={24} style={{ marginTop: '-20px' }}>
          <Space size="middle">
            <Button type="primary" onClick={() => setPage({current: 1})}>查询</Button>
            <Button onClick={resetInput}>重置</Button>
          </Space>
        </Col>
      </Row>
      <Title>用户信息</Title>
      <Row style={{ margin: '14px 0px' }}>
        <Col span={20}>
          <Space size="middle">
            <Button type="primary" onClick={() => setImportUserVisible(true)}>导入用户列表</Button>
            <Button onClick={() => setAddUserVisible(true)}>添加用户</Button>
          </Space>
        </Col>
        <Col span={4} style={{ textAlign: 'right' }}>
          <Button type="primary" onClick={handleExport}>导出Excel</Button>
        </Col>
      </Row>
      <Table
        dataSource={list}
        columns={columns}
        pagination={false}
      />
      <Row justify="end">
        <Col>
          <Pagination
            current={page.current}
            total={total}
            showQuickJumper
            showSizeChanger
            pageSize={pageSize}
            pageSizeOptions={['10', '20', '50', '100']}
            onChange={(_page, _pageSize) => {
              if (_page !== page.current) setPage({current:_page})
              if (_pageSize !== pageSize) setPageSize(_pageSize!)
            }}
            style={{
              marginTop: 20,
              marginRight: 0
            }}
          />
        </Col>
      </Row>
      <AddUser
        visible={addUserVisible}
        onCancel={() => setAddUserVisible(false)}
        onOk={loadList}
      />
      <ImportUser
        visible={importUserVisible}
        onCancel={() => setImportUserVisible(false)}
        onOk={loadList}
      />
    </Block >
  )
}

export default List
