import React, { useState, Fragment, useEffect, memo } from 'react'
import { Route, BrowserRouter } from 'react-router-dom'
import UserNavBar from 'components/UserNavBar'
import NavBar from 'components/NavBar'
import { Item, Order, Time, User, Daily } from './pages'
import { useSelector, useDispatch } from 'react-redux'
import { ConfigProvider, Drawer } from 'antd'
import { Dispatch, RootState } from './store'
import { Scene } from 'types/app'
import LoginModel from 'components/LoginModel'
import { isMobile } from 'utils/ua'
import Mobile from './mobile'
import Manual from './pages/Manual'
import Notice from './pages/Notice'
import Administrators from './pages/Administrators'
import System from './pages/System'
import Register from './pages/Register'
import Reserve from './pages/Reserve'
import zh_CN from 'antd/lib/locale-provider/zh_CN'
import moment from "moment"
import "moment/locale/zh-cn"
import "./index.css"
import './customAntd.less'
import './config/i18n'

moment.locale('zh-cn')
const root = document.getElementById('root')

function App() {
  const { app: { configure, render } } = useSelector((state: RootState) => state)
  const [isMini, setIsMini] = useState<boolean>((root!.offsetWidth < 1395))
  const appDispatch = useDispatch<Dispatch>().app

  window.onresize = function () {
    setIsMini((root!.offsetWidth < 1395))
  }

  useEffect(() => {
    setTimeout(() => appDispatch.setConfigure({ scene: Scene.johnson }), 0)
  }, [appDispatch])

  if (isMobile()) {
    return <Mobile />
  }

  return (
    <ConfigProvider locale={zh_CN}>
      <div className='App'>
        <BrowserRouter>
          <div className='g-body' key={`body${render}`}>
            <Drawer></Drawer>
            <div className='g-nav'>
              <NavBar inlineCollapsed={isMini} />
            </div>
            <div className='g-main' style={{ marginLeft: isMini ? '85px' : '257px' }}>
              <UserNavBar />
              {configure ?
                <Fragment>
                  <Route path='/reserve' render={() => <Reserve />} ></Route>
                  <Route path='/item' render={() => <Item />}></Route>
                  <Route path='/time' render={() => <Time />}></Route>
                  <Route path='/order' render={() => <Order />}></Route>
                  <Route path='/user' render={() => <User />}></Route>
                  <Route path='/administrators' render={() => <Administrators />} ></Route>
                  <Route path='/daily' render={() => <Daily />}></Route>
                  <Route path='/manual' render={() => <Manual />} ></Route>
                  <Route path='/notice' render={() => <Notice />} ></Route>
                  <Route path='/system' render={() => <System />} ></Route>
                  <Route path='/register' render={() => <Register />} ></Route>
                </Fragment>
                : '应用初始化界面，当初始化函数没加载完全时会呈现这种状态'
              }
            </div>
          </div>
        </BrowserRouter>
      </div>
      <LoginModel />
    </ConfigProvider>
  );
}

export default memo(App) 
