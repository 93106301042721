import { Spin } from 'antd'
import { Loading } from 'components/Block/style'
import React, { FC, ReactNode, useCallback, useEffect, useRef } from 'react'
import { Body, Border, Bottom, BottomColumnSpace, BottomContentSpace, BoxListBorder, ClickLayerBorder, Column, Content, Time, TopContentSpace } from './style'

interface RichTextProps {
  background: ReactNode,
  isLoading?: boolean,
  scrollTop?: number,
}

const TimeScroll: FC<RichTextProps> = (props) => {
  const border = useRef(null)
  let time: any = null
  //渲染时间列表
  const renderTimeList = useCallback(() => {
    const result = []
    for (let index = 0; index <= 24; index++) {
      result.push(<Time key={'time' + index}>{index < 10 ? `0${index}` : index}:00</Time>)
    }
    return result
  }, [])

  //渲染时间格子后移动高度
  useEffect(() => {
    if (typeof(props.scrollTop) !== "number") return
    clearInterval(time)
    const dom = border.current as unknown as HTMLElement
    const action = dom.scrollTop > props.scrollTop ? "sec" : "add"
    let index = 1
    if (action === "sec") {
      time = setInterval(() => {

        if ((props.scrollTop! + 30) > dom.scrollTop) {
          dom.scrollTop = props.scrollTop!
          clearInterval(time)
        } else {
          dom.scrollTop = dom.scrollTop - (30 * index)
        }
      }, 10)
    } else {
      time = setInterval(() => {
        if ((props.scrollTop! - 30) < dom.scrollTop) {
          dom.scrollTop = props.scrollTop!
          clearInterval(time)
        } else {
          dom.scrollTop = props.scrollTop! + (30 * index)
        }
      }, 10)
    }
  }, [props.scrollTop])

  return (
    <Body>
      <Border ref={border}>
        <Column>
          {renderTimeList()}
        </Column>
        <Content>
          <ClickLayerBorder>
            {props.children}
          </ClickLayerBorder>
          <BoxListBorder>
            {props.background}
          </BoxListBorder>
        </Content>
        <Bottom />
        <TopContentSpace />
        <BottomContentSpace />
        <BottomColumnSpace />
      </Border>
      {props.isLoading ? <Loading><Spin size="large" /></Loading> : null}
    </Body>
  )
}

export default TimeScroll