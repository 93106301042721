/*
 * File: /src/pages/UserAuth/View/List.tsx
 * Author: Mxsyx (zsimline@163.com)
 * Create Time: Tuesday 2020-11-03 11:44:42
 * Last Modified: Tuesday 2020-11-03 12:02:27
 * Modified By: Mxsyx (zsimline@163.com>)
 * 
 * Copyright © 2020 https://www.joyreserve.com
 */
import React, { FC, Fragment, useState, useCallback, useEffect } from 'react'
import { Button, Checkbox, Form, Input, message, Modal, Select, Table } from 'antd'
import { MinusCircleOutlined, PlusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Block } from 'components'
import services from 'services'
import { FieldItem } from 'types/register'
import Method from './Method'

const columns = [{
  key: 'title',
  dataIndex: 'title',
  title: '注册信息'
}, {
  key: 'is_show',
  dataIndex: 'is_show',
  title: '是否注册显示'
}, {
  key: 'is_required',
  dataIndex: 'is_required',
  title: '是否必填'
}, {
  key: 'type',
  dataIndex: 'type',
  title: '类型'
}, {
  key: 'operation',
  dataIndex: 'operation',
  title: '操作'
}]

interface FieldType {
  name: string,
  isMultiple: boolean
}
export const fieldTypes: AnyObject<FieldType> = {
  '1': {
    name: '输入框',
    isMultiple: false
  },
  '2': {
    name: '单选框',
    isMultiple: true
  },
  '3': {
    name: '复选框',
    isMultiple: true
  },
  '1001': {
    name: '身份证号',
    isMultiple: false
  }
}

const checkboxOpts = [
  { label: '显示', value: 'is_show' },
  { label: '必填', value: 'is_required' },
]

const List: FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [visible, setVisible] = useState<boolean>(false)
  const [data, setData] = useState<FieldItem[]>([])
  const [list, setList] = useState<any[]>([])
  const [form] = Form.useForm()
  const [options, setOptions] = useState<string[]>([])
  const [isMultiple, setIsMultiple] = useState<boolean>(false)
  const [activeIndex, setActiveIndex] = useState<number>(-1)
  const [operation, setOperation] = useState<'create' | 'update'>('create')
  const [rnd, setRnd] = useState<number>(0)

  const handleCreate = useCallback(() => {
    setVisible(true)
    setActiveIndex(-1)
    setOptions([])
  }, [])

  const handleDelete = useCallback((id: number) => {
    setLoading(true)
    services.register.delete(id)
      .then(() => {
        message.success('删除注册信息成功！')
        loadList()
      })
      .catch(() => {
        message.error('删除注册信息失败~')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const handleUpdate = useCallback((index: number) => {
    setOperation('update')
    setActiveIndex(index)
    setRnd(Math.random())
    setVisible(true)
  }, [])

  const handleData = useCallback((data: FieldItem[]) => {
    return data.map((item, index) => ({
      title: item.title,
      is_show: item.is_show ? '是' : '否',
      is_required: item.is_required ? '是' : '否',
      type: fieldTypes[String(item.type)].name,
      operation: !item.is_edit ? '不可编辑' : <Fragment>
        <Button type="link" onClick={() => handleUpdate(index)}>编辑</Button>
        <Button type="link" onClick={() => handleDelete(item.id)}>删除</Button>
      </Fragment>
    }))
  }, [handleUpdate, handleDelete])

  const loadList = useCallback(() => {
    setLoading(true)
    services.register.getList()
      .then((data) => {
        setData(data)
        setList(handleData(data))
      })
      .catch(() => {
        message.error('获取自定义注册信息失败~')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const handleSubmit = useCallback(() => {
    form.validateFields()
      .then((data) => {
        const postData = {
          title: data.title,
          is_show: data.opts.indexOf('is_show') !== -1,
          is_required: data.opts.indexOf('is_required') !== -1,
          type: data.type,
          content: fieldTypes[data.type].isMultiple ? options : []
        }

        const promise = operation === 'create'
          ? services.register.create(postData)
          : services.register.update(data.id, postData)
        promise
          .then(() => {
            message.success(`${operation === 'create' ? '创建' : '更新'}注册信息成功！`)
            loadList()
            setVisible(false)
          })
          .catch(() => {
            message.error(`${operation === 'create' ? '创建' : '更新'}用户注册信息失败~`)
          })
      })
      .catch(() => {
        message.warning('请正确填写表单！')
      })
  }, [options, operation])

  const handleAddOption = useCallback(() => {
    setOptions([...options, ''])
  }, [options])

  const handleRemoveOption = useCallback((index: number) => {
    options.splice(index, 1)
    setOptions([...options])
  }, [options])

  const handleOptionInput = useCallback((e, index) => {
    options[index] = e.target.value
    setOptions([...options])
  }, [options])

  const handleChangeFieldType = useCallback((value) => {
    if (fieldTypes[value].isMultiple) {
      setIsMultiple(true)
    } else {
      setIsMultiple(false)
      setOptions([])
    }
  }, [data, activeIndex])

  useEffect(() => {
    if (activeIndex === -1) {  // 新增
      form.resetFields()
      return
    }
    form.setFieldsValue({
      id: data[activeIndex].id,
      title: data[activeIndex].title,
      opts: [data[activeIndex].is_show ? 'is_show' : undefined,
      data[activeIndex].is_required ? 'is_required' : undefined],
      type: `${data[activeIndex].type}`
    })
    if (fieldTypes[data[activeIndex].type].isMultiple) {
      setIsMultiple(true)
      setOptions([...data[activeIndex].content] || [])
    }
  }, [activeIndex, data, rnd])

  useEffect(loadList, [])

  return (
    <Fragment>
      <Method />
      <Block title="自定义注册信息" loading={loading}>
        <Button type="primary" onClick={handleCreate}>
          <PlusOutlined />添加注册信息
        </Button>
        <Table
          dataSource={list}
          columns={columns}
          style={{ marginTop: '15px' }}
        />
      </Block>
      <Modal
        title="添加注册信息"
        visible={visible}
        okText="保存"
        cancelText="取消"
        onOk={handleSubmit}
        onCancel={() => setVisible(false)}
      >
        <Form form={form} validateMessages={{ required: "'${label}' 不能为空" }}>
          <Form.Item name="id" hidden></Form.Item>
          <Form.Item name="title" label="注册信息" rules={[{ required: true }]}>
            <Input placeholder="请填写注册信息名称" />
          </Form.Item>
          <Form.Item name="opts" label="填写要求">
            <Checkbox.Group options={checkboxOpts}>
              <Checkbox>显示</Checkbox>
              <Checkbox>必填</Checkbox>
            </Checkbox.Group>
          </Form.Item>
          <Form.Item name="type" label="类型" rules={[{ required: true }]}>
            <Select
              placeholder="请选择注册信息的类型"
              onChange={handleChangeFieldType}
            >
              <Select.OptGroup label="基础表单">
                <Select.Option value="1">输入框</Select.Option>
                <Select.Option value="2">单选框</Select.Option>
                <Select.Option value="3">复选框</Select.Option>
              </Select.OptGroup>
              <Select.OptGroup label="常用表单">
                <Select.Option value="1001">身份证号</Select.Option>
              </Select.OptGroup>
            </Select>
          </Form.Item>
          {isMultiple &&
            <Button style={{ marginLeft: '10px', float: 'right' }} onClick={handleAddOption}>
              <PlusCircleOutlined />添加选项
            </Button>
          }
          <Form.Item>
            <Form style={{ marginTop: '15px' }}>
              {options.map((item, index) =>
                <Form.Item name={`field-item-${index}`} label={`选项${index + 1}`}>
                  <Input
                    style={{ width: '70%', marginRight: '10px' }}
                    value={options[index]}
                    onInput={(value) => handleOptionInput(value, index)}
                  />
                  <MinusCircleOutlined onClick={() => handleRemoveOption(index)} />
                </Form.Item>
              )}
            </Form>
          </Form.Item>
        </Form>
      </Modal>
    </Fragment>
  )
}

export default List
