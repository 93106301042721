import React, { FC } from 'react'
import { Route } from 'react-router'
import { BrowserRouter } from 'react-router-dom'
import Order from './Order'

const Mobile: FC = () => {
  return (
    <BrowserRouter>
      <Route path='/order' render={() => <Order />} />
    </BrowserRouter>
  )
}

export default Mobile
