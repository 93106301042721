/*
 * File: /src/pages/Register/View/Method.tsx
 * Author: Mxsyx (zsimline@163.com)
 * Create Time: Wednesday 2020-11-04 02:30:11
 * Last Modified: Wednesday 2020-11-04 02:50:46
 * Modified By: Mxsyx (zsimline@163.com>)
 * 
 * Copyright © 2020 https://www.joyreserve.com
 */
import { Button, Form, message, Radio, Select } from 'antd'
import { Block } from 'components'
import React, { FC, useCallback, useEffect, useState } from 'react'
import services from 'services'

const Method: FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [form] = Form.useForm()

  const handleUpdate = useCallback(() => {
    const data = form.getFieldsValue()
    setLoading(true)
    services.common.setConfig(data)
      .then(() => {
        message.success('改变登录注册方式成功！')
      })
      .catch(() => {
        message.error('改变登录注册方式失败~')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  // 获取注册登录方式
  useEffect(() => {
    setLoading(true)
    services.common.getConfig({
      configs_need: ['basic_is_just_login', 'basic_is_use_phone_login']
    })
      .then((data) => {
        form.setFieldsValue({
          basic_is_just_login: `${data['basic_is_just_login']}`,
          basic_is_use_phone_login: `${data['basic_is_use_phone_login']}`
        })
      })
      .catch(() => {
        message.error('获取登录注册方式失败~')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return (
    <Block title="登录注册方式" loading={loading}>
      <Form form={form}>
        <Form.Item name="basic_is_just_login" label="登录注册方式">
          <Radio.Group>
            <Radio value="0">注册+登录</Radio>
            {/* <Radio value="1">只允许登录</Radio>
            <Radio value="2">免注册登录</Radio> */}
          </Radio.Group>
        </Form.Item>
        <Form.Item name="basic_is_use_phone_login">
          <Select style={{ width: '30%', marginLeft: '100px' }}>
            <Select.Option value="0">手机号注册登录</Select.Option>
            <Select.Option value="1">短信验证码登录</Select.Option>
          </Select>
        </Form.Item>
      </Form>
      <Button type="primary" onClick={handleUpdate}>确认</Button>
    </Block>
  )
}

export default Method
