import React, { FC, useState, useEffect, useCallback } from 'react'
import { Block, InputBox } from 'components'
import { useParams } from 'react-router'
import { item, system } from 'services'
import { RestDetail } from 'services/system'
import { Title } from '../style'
import { Button, Calendar, Col, DatePicker, Drawer, message, Radio, Row, Select, Switch } from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio'
import moment from 'moment'
import { Body, Mask, RelativeDiv } from './CanlendarStyle'
import { handlePush } from 'utils/common'
import { getMonthAllDate } from 'utils/time'

const defaultRestDetailData: RestDetail = {
  "is_display": true,
  "is_date": false,
  "is_specific_date": true,
  // "date": [],
  "specific_date": []
}

const DisplayTimeDetail: FC = () => {
  const { id } = useParams<{ id: string }>()
  const { Option } = Select
  const { RangePicker } = DatePicker
  const [onlineData, setOnlineData] = useState<RestDetail>(defaultRestDetailData)
  const [data, setData] = useState<RestDetail>(defaultRestDetailData)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [render, setRender] = useState<number>(1)
  const [selectMonth, setSelectMonth] = useState<number>(new Date().getMonth())
  const [selectYear, setSelectYear] = useState<number>(new Date().getFullYear())
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [bindingList, setBingingList] = useState<{ id: number, name: string }[]>()
  const [bindingTargetId, setBindingTargetId] = useState<number>()

  useEffect(() => {
    (async () => {
      setIsLoading(true)
      const data = await system.getDisplayTimeRestDetail({ id: Number(id) })
      if (!data || !(data.date instanceof Array)) {
        await system.initDisplayTimeRestDetail({ id: Number(id), data: defaultRestDetailData })
        setRender(render + 1)
      } else {
        setData(data)
        setOnlineData(data)
      }
      setIsLoading(false)
    })()
  }, [id, render])

  useEffect(() => {
    (async () => {
      const data = await item.getSummaryList()
      data.splice(data.findIndex(item => item.id.toString() === id), 1)
      setBingingList(data)
    })()
  }, [])

  const handleClickRadio = useCallback((e: RadioChangeEvent) => {
    const newData = JSON.parse(JSON.stringify(data))
    const isCheckDate = !!(e.target.value === "date")
    setData({ ...newData, is_date: isCheckDate, is_specific_date: !isCheckDate })
  }, [data])

  const handleChangeRangeDate = useCallback((value) => {
    const newData = JSON.parse(JSON.stringify(data))
    setData({ ...newData, date: value })
  }, [data])

  const handleClickDate = useCallback((_date: moment.Moment) => {
    const newData = JSON.parse(JSON.stringify(data))
    const date = moment(_date).format("YYYY-MM-DD")
    setData({ ...newData, specific_date: handlePush(date, data.specific_date) })
  }, [data])

  const renderDateBorder = useCallback((_date: moment.Moment) => {
    const date = moment(_date).format("YYYY-MM-DD")
    return <Body>
      <div
        className={!data.specific_date?.some(item => item === date) ? "box select" : "box un-select"}
        onClick={() => { handleClickDate(_date) }}
      >
        {moment(_date).format("DD")}
      </div>
    </Body>
  }, [data])

  const handleClickSubmit = useCallback(async () => {
    setIsLoading(true)
    try {
      await system.editDisplayTimeRestDetail({ id: Number(id), data })
      setRender(render + 1)
      message.success("更新完成")
    } catch (err) {
      err.response && message.error(err.response.data.message)
    } finally {
      setIsLoading(false)
    }
  }, [data, render])

  const handleClickAll = useCallback(() => {
    const newData = JSON.parse(JSON.stringify(data))
    for (let index = 0; index < newData.specific_date.length; index++) {
      const month = Number(newData.specific_date[index].split("-")[1]) - 1
      if (month === selectMonth) {
        newData.specific_date.splice(index, 1)
        index--
      }
    }
    setData(newData)
  }, [selectMonth, selectYear, data])

  const handleSubmitCopy = useCallback(async () => {
    try {
      await system.getDisplayTimeRestCopy({ target_id: Number(bindingTargetId), id: Number(id) })
      setIsOpen(false)
      message.success("更新完成")
    } catch (err) {
      err.response && message.error(err.response.data.message)
    }
  }, [bindingTargetId, id])

  const handleClickClear = useCallback(() => {
    const newData = JSON.parse(JSON.stringify(data))
    const list = getMonthAllDate(selectYear, selectMonth)
    newData.specific_date.forEach((item: string, index: number) => {
      const month = Number(item.split("-")[1]) - 1
      if (month === selectMonth) {
        newData.specific_date.splice(index, 1)
      }
    })
    newData.specific_date = [...newData.specific_date, ...list]
    setData(newData)
  }, [selectMonth, selectYear, data])

  return (
    <React.Fragment>
      <Block
        loading={isLoading}
        title={`${data?.name}显示配置`}
        description={
          <div>
            <Button onClick={() => { setIsOpen(true) }}>一键同步</Button>
            <Button type="primary" onClick={handleClickSubmit} style={{ marginLeft: 8 }}>保存</Button>
          </div>
        }
        showBack
      >
        <Row>
          <Col>
            <Title>日期显示方式</Title>
          </Col>
          <Col style={{ display: "flex", alignItems: "center", marginLeft: 10 }}>
            <Switch
              checked={data.is_display}
              checkedChildren="使用"
              unCheckedChildren="停用"
              onChange={(value) => { setData({ ...data, is_display: value }) }}
            />
          </Col>
        </Row>
        <Radio.Group onChange={handleClickRadio} value={data.is_date ? "date" : "specific_date"} style={{ marginTop: 20, fontSize: 14 }}>
          <div>
            <Radio value="date">按照日期范围显示</Radio>
            <div>
              <InputBox title="日期起止范围" style={{ marginTop: 20, marginLeft: 24 }}>
                <RangePicker
                  value={data.date && data.date.length ? [moment(data.date[0]), moment(data.date[1])] : void 0}
                  onChange={(_moment, string) => handleChangeRangeDate(string)}
                  disabled={data.is_specific_date}
                />
              </InputBox>
            </div>
          </div>
          <div>
            <Radio value="specific_date" style={{ marginTop: 20 }}>按照选择具体日期显示</Radio>
            <RelativeDiv>
              <Calendar
                dateFullCellRender={renderDateBorder}
                onChange={(date) => {
                  setSelectMonth(date.month())
                  setSelectYear(date.year())
                }}
              />
              <Button onClick={handleClickAll} type="primary" style={{ position: "absolute", top: 20, left: 24 }}>全选当月</Button>
              <Button onClick={handleClickClear} style={{ position: "absolute", top: 20, left: 124 }}>清空当月</Button>
              {data.is_date ? <Mask /> : null}
            </RelativeDiv>
          </div>
        </Radio.Group>
      </Block>
      <Drawer
        visible={isOpen}
        onClose={() => { setIsOpen(false) }}
        title="一键同步"
        footer={
          <Button
            disabled={!(typeof (bindingTargetId) === "number")}
            onClick={handleSubmitCopy}
            style={{ float: "right" }}
          >
            提交
          </Button>
        }
      >
        <Select style={{ width: 200 }} onChange={(value) => { setBindingTargetId(value as number) }}>
          {bindingList?.map(item => (
            <Option key={item.id} value={item.id}>{item.name}</Option>
          ))}
        </Select>
      </Drawer>
    </React.Fragment >
  )
}

export default DisplayTimeDetail