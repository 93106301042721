/*
 * File: /src/pages/System/View/OrderRule/Common.tsx
 * Author: Mxsyx (zsimline@163.com)
 * Create Time: Monday 2020-11-09 05:04:30
 * Last Modified: Monday 2020-11-09 05:04:30
 * Modified By: Mxsyx (zsimline@163.com>)
 * 
 * Copyright © 2020 https://www.joyreserve.com
 */
import React, { FC, useCallback, useEffect, useState } from 'react'
import { Block } from 'components'
import Rule, { mapping, StorePorps, toDHM, toSecond } from './Rule'
import services from 'services'
import { Button, message } from 'antd'
import { CommonRule } from 'types/orderRule'

const Common: FC = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [store, setStore] = useState<StorePorps>({
    '0-0-T': [0, 0, 0],
    '0-0-D': [0, 0, 0],
    '0-1-T': [0, 0, 0],
    '0-1-D': [0, 0, 0],
    '0-2-T': [0, 0, 0],
    '0-2-D': [0, 0, 0]
  })
  const [checks, setChecks] = useState<string[]>([])

  const handleChange = (key: keyof StorePorps, value: [number, number, number]) => {
    store[key] = value
    setStore({ ...store })
  }

  const handleCheck = (value: string[]) => {
    const index = value.indexOf(mapping[value[value.length - 1]])
    if (index !== -1) value.splice(index, 1)
    setChecks([...value])
  }

  const handleSubmit = useCallback(() => {
    // 预约开始时间与预约结束时间必须同时为 `按时间段` 或者 `按预约日期` 
    if (checks.indexOf('0-0-T') !== -1 && checks.indexOf('0-1-D') !== -1
      || checks.indexOf('0-0-D') !== -1 && checks.indexOf('0-1-T') !== -1) {
      message.error('预约开始时间与预约结束时间必须同时为 `按时间段` 或者 `按预约日期` ~')
      return
    }

    // 预约开始时间不能小于预约截止时间
    if (checks.indexOf('0-0-T') !== -1 && checks.indexOf('0-1-T') !== -1 && store['0-0-T'] < store['0-1-T']) {
      message.error('预约开始时间不能小于预约截止时间~')
      return
    }
    if (checks.indexOf('0-0-D') !== -1 && checks.indexOf('0-1-D') !== -1 && store['0-0-D'] < store['0-1-D']) {
      message.error('预约开始时间不能小于预约截止时间~')
      return
    }

    const _checks = []
    for (let i = 0; i < checks.length; i++) {
      _checks[parseInt(checks[i][2])] = checks[i][4]
    }
    const postData: CommonRule = {
      start_time: [_checks[0] as ('T' | 'D') || 'N', toDHM(store['0-0-T']), toDHM(store['0-0-D'])],
      end_time: [_checks[1] as ('T' | 'D') || 'N', toDHM(store['0-1-T']), toDHM(store['0-1-D'])],
      cancel_time: [_checks[2] as ('T' | 'D') || 'N', toDHM(store['0-2-T']), toDHM(store['0-2-D'])]
    }

    setLoading(true)
    services.orderRule.common.update(postData)
      .then(() => {
        message.success('更新通用预约规则成功！')
      })
      .catch(() => {
        message.error('更新通用预约规则失败~')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [store, checks])

  useEffect(() => {
    services.orderRule.common.get()
      .then((data) => {
        setStore({
          '0-0-T': toSecond(data?.start_time?.[1] || 0),
          '0-0-D': toSecond(data?.start_time?.[2] || 0),
          '0-1-T': toSecond(data?.end_time?.[1] || 0),
          '0-1-D': toSecond(data?.end_time?.[2] || 0),
          '0-2-T': toSecond(data?.cancel_time?.[1] || 0),
          '0-2-D': toSecond(data?.cancel_time?.[2] || 0)
        })
        setChecks([
          `0-0-${data?.start_time?.[0] || 'N'}`,
          `0-1-${data?.end_time?.[0] || 'N'}`,
          `0-2-${data?.cancel_time?.[0] || 'N'}`
        ])
      })
      .catch(() => {
        message.error('获取通用预约规则失败~')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return (
    <Block
      loading={loading}
      title="通用规则设置"
      description={
        <Button type="primary" onClick={handleSubmit}>保存</Button>
      }
    >
      <Rule store={store} checks={checks} onChange={handleChange} onCheck={handleCheck} />
    </Block>
  )
}

export default Common
