/*
 * @Author: your name
 * @Date: 2020-09-29 15:26:02
 * @LastEditTime: 2020-10-29 16:40:25
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/services/index.ts
 */
import order from './order'
import time from './time'
import item from './item'
import userLogin from './userLogin'
import daily from './dilay'
import user from './user'
import administrator from './administrator'
import notice from './notice'
import car from './car'
import system from './system'
import register from './register'
import common from './common'
import orderRule from './orderRule'
import attend from './attend'
import annex from './annex'
import platform from './platform'

export {
  order,
  item,
  time,
  userLogin,
  daily,
  administrator,
  system
}

export default {
  item,
  register,
  order,
  user,
  notice,
  car,
  common,
  orderRule,
  attend,
  annex,
  system,
  platform,
  administrator
}
