import styled from 'styled-components'

export const Box = styled.div`
  padding: 15px 16px;
  box-sizing: border-box;
  border-bottom: 1px solid #EBEDF0;
`

export const Title = styled.h3`
  font-size: 18px;
  color: #323233;
  line-height: 28px;
  margin-bottom: 6px;
`

export const Meta = styled.div`
  font-size: 14px;
  color: #969799;
  line-height: 22px;
`
