/*
 * @Author: your name
 * @Date: 2020-10-12 11:38:42
 * @LastEditTime: 2020-10-12 11:41:30
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/pages/Daily/Model/Modal/style.ts
 */
import styled from 'styled-components'

export const View = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  margin-top: 20px;
`