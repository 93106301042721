/*
 * File: /src/pages/Order/View/Annex/Pictures.tsx
 * Author: Mxsyx (zsimline@163.com)
 * Create Time: Wednesday 2020-11-11 05:47:56
 * Last Modified: Wednesday 2020-11-11 05:47:56
 * Modified By: Mxsyx (zsimline@163.com>)
 * 
 * Copyright © 2020 https://www.joyreserve.com
 */
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Block } from 'components'
import { useParams } from 'react-router'
import { Button, Space, Image, Row, Col, message, Pagination } from 'antd'
import services from 'services'
import { Picture } from 'types/annex'
import { Badge, Index } from './style'
import { ossURL } from 'consts/url'

const Pictures: FC = () => {
  const { orderId } = useParams<{ orderId: string }>()
  const [loading, setLoading] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(false)
  const [pictureList, setPictureList] = useState<Picture[]>([])
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(8)
  const [total, setTotal] = useState<number>(0)
  const [downloadUrl, setDownloadUrl] = useState<string>('')
  const downloadRef = useRef<HTMLAnchorElement>(null)

  // 加载照片列表
  useEffect(() => {
    setLoading(true)
    services.annex.getPictureList({ id: orderId, page, size: pageSize })
      .then((data) => {
        setPictureList(data.data)
        setTotal(data.total)
      })
      .catch(() => {
        message.error('获取照片列表失败~')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [page, pageSize])

  const handleView = useCallback((index: number) => {
    if (pictureList[index].viewed) return
    services.annex.changePictureViewed(pictureList[index].id)
      .then(() => {
        pictureList[index].viewed = true
        setPictureList([...pictureList])
      })
      .catch(() => {
        message.warning('改变附件查看状态时遇到错误~')
      })
  }, [pictureList])

  const handleExport = useCallback((fmt: 'PDF' | '压缩包') => {
    let promise = fmt === 'PDF'
      ? services.annex.exportAsPDF({ id: orderId })
      : services.annex.exportAsZIP({ id: orderId })
    const hide = message.loading('导出中...')
    setDisabled(true)
    promise
      .then((data) => {
        setDownloadUrl(data.url)
        downloadRef.current && downloadRef.current.click()
      })
      .catch(() => {
        message.error(`导出图片为${fmt}时失败~`)
      })
      .finally(() => {
        hide()
        setDisabled(false)
      })
  }, [downloadRef])

  return (
    <Block
      loading={loading}
      title={`订单编号: #${orderId}`}
      showBack
      description={
        <Space>
          <Button disabled={disabled} onClick={() => handleExport('PDF')}>导出PDF</Button>
          <Button disabled={disabled} onClick={() => handleExport('压缩包')} >下载</Button>
        </Space>
      }
    >
      <Row>
        {pictureList.map((picture, index) =>
          <Col span={6}>
            <div style={{ height: '200px', overflow: 'scroll', textAlign: 'center' }}>
              <Image
                width={200}
                src={picture.url}
                fallback={`${ossURL}/img/fallback.png`}
                onClick={() => handleView(index)}
              />
              {!picture.viewed && <Badge />}
            </div>
            <Index>{picture.name || index + 1}</Index>
          </Col>
        )}
        <Col span={24}>
          <Pagination
            total={total}
            showSizeChanger
            current={page}
            pageSize={pageSize}
            pageSizeOptions={['8', '12', '16', '20', '24']}
            showQuickJumper
            onChange={(_page, _pageSize) => {
              if (_page !== page) setPage(_page)
              if (_pageSize !== pageSize) setPageSize(_pageSize!)
            }}
            style={{ float: 'right', marginTop: 15 }}
          />
        </Col>
        <a href={downloadUrl} download target="_blank" ref={downloadRef}></a>
      </Row>
    </Block>
  )
}

export default Pictures
