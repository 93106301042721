import React, { FC } from "react"
import { Box, BoxContent, BoxSpace, Text } from "./style"
import { PlusOutlined } from "@ant-design/icons"
import { NewDefaultType } from "types/time"

interface ItemType {
  date: string,
  time: string,
  onClick: (data: NewDefaultType) => void
}

const Item: FC<ItemType> = (props) => {
  const { date, time, onClick } = props
  return (
    <Box onClick={() => onClick({ defaultDate: date, defaultTime: time })}>
      <BoxContent>
        <PlusOutlined size={12} />
        <Text>单机空白创建</Text>
      </ BoxContent >
      <BoxSpace />
    </Box>
  )
}

export default Item

