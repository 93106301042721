import React, { FC, useCallback } from "react"
import { Row, Item, Space } from "./style"

interface RichTextProps {
  value: number[],
  disabled?: boolean,
  onChange: (value: number[]) => void
}

const SelectWeek: FC<RichTextProps> = (props) => {
  const renderItem = useCallback((index, name) => {
    if (props.value.some(item => item === index)) {
      const temp = [...props.value]
      return (
        <Item
          onClick={() => {
            temp.splice(temp.findIndex(item => item === index), 1)
            props.onChange(temp)
          }}
          style={{ background: props.disabled ? "#ccc" : "#30A694", color: "white" }}
        >
          {name}
        </Item >)
    } else {
      return (
        <Item
          style={props.disabled ? { background: "#ddd" } : {}}
          onClick={() => { props.onChange([...props.value, index]) }}>
          {name}
        </Item>)
    }
  }, [props.value, props.disabled])

  return (
    <Row>
      {props.disabled ? <Space /> : null}
      {renderItem(0, "日")}
      {renderItem(1, "一")}
      {renderItem(2, "二")}
      {renderItem(3, "三")}
      {renderItem(4, "四")}
      {renderItem(5, "五")}
      {renderItem(6, "六")}
    </Row>
  )
}

export default SelectWeek
