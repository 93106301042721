import React, { FC } from "react"
import { DatePicker, Input, Radio, Select } from "antd"
import { InputBox } from "components"
import { CustomRuleType, PeriodSpaceType, PeriodType, RereadRuleType } from "types/time"
import { PanelRowStyle, periodList, rereadRuleList } from "consts/time"
import SelectWeek from "components/SelectWeek"
import moment from "moment"

interface RereadType {
  disabled?: boolean
  reread_rule: RereadRuleType
  custom_rule: CustomRuleType
  onchangeRereadRule: (reread_rule: RereadRuleType) => void
  onchangeCustomRule: (custom_rule: CustomRuleType) => void
}
const Reread: FC<RereadType> = (props) => {
  const { reread_rule, custom_rule, onchangeRereadRule, onchangeCustomRule, disabled } = props
  const { Option } = Select

  return (
    <div >
      <InputBox style={{ ...PanelRowStyle, paddingRight: 70, marginTop: 13 }} title='重复规则'>
        <Select
          disabled={disabled}
          value={reread_rule}
          onChange={(value) => { onchangeRereadRule(value) }}
          placeholder='请选择重复规则'
          style={{ width: 235 }}
        >
          {rereadRuleList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
        </Select>
      </InputBox>
      {
        reread_rule === RereadRuleType.custom ?
          <React.Fragment>
            <InputBox
              style={{
                ...PanelRowStyle,
                paddingRight: '135px'
              }}
              title='重复'
            >
              <Input
                disabled={disabled}
                type='number'
                value={custom_rule.period_value}
                onChange={(e) => { onchangeCustomRule({ ...custom_rule, period_value: Number(e.target.value) }) }}
                style={{ width: '80px' }}
              />
              <Select
                disabled={disabled}
                value={custom_rule.period_space}
                onChange={(value) => { onchangeCustomRule({ ...custom_rule, period_space: value }) }}
                style={{ width: '80px', marginLeft: '8px' }}
              >
                {periodList.map(item => <Option key={item.value} value={item.value}>{item.name}</Option>)}
              </Select>
            </InputBox>
            {custom_rule.period_space === PeriodSpaceType.week ?
              <InputBox
                style={{
                  ...PanelRowStyle,
                  paddingRight: '23px'
                }}
                title='按日重复'
              >
                <SelectWeek
                  disabled={disabled}
                  value={custom_rule.day}
                  onChange={(value) => { onchangeCustomRule({ ...custom_rule, day: value }) }}
                />
              </InputBox>
              : null
            }
            <InputBox style={{ ...PanelRowStyle, alignItems: 'flex-start', paddingRight: 33 }} title='重复次数'>
              <Radio.Group
                value={custom_rule.period_type}
                disabled={disabled}
                onChange={(e) => {
                  onchangeCustomRule(
                    {
                      ...custom_rule,
                      period_type: e.target.value,
                      period_data: ""
                    }
                  )
                }}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: 110
                }}
              >
                <Radio value={PeriodType.always}>一直</Radio>
                <Radio value={PeriodType.date}>
                  从
                    <DatePicker
                    disabled={((custom_rule.period_type !== PeriodType.date) || disabled)}
                    style={{ width: 180, marginLeft: 4, marginRight: 4 }}
                    value={
                      custom_rule.period_type === PeriodType.date &&
                        custom_rule.period_data.length === 10 ? moment(custom_rule.period_data) : null
                    }
                    onChange={(_value, string) => {
                      onchangeCustomRule({ ...custom_rule, period_data: string })
                    }}
                  />
                    开始
                  </Radio>
                <Radio value={PeriodType.number}>
                  执行
                    <Input
                    disabled={((custom_rule.period_type !== PeriodType.number) || disabled)}
                    style={{ width: 80, marginLeft: 4, marginRight: 4 }}
                    value={custom_rule.period_type === PeriodType.number ? Number(custom_rule.period_data) : 0}
                    type='number'
                    onChange={(e) => { onchangeCustomRule({ ...custom_rule, period_data: e.target.value }) }}
                  />
                    次
                  </Radio>
              </Radio.Group>
            </InputBox>
          </React.Fragment>
          : null
      }
    </div >
  )
}

export default Reread