import React, { FC, useCallback, useEffect, useState } from 'react'
import {  Select, Tabs } from 'antd'
import { ConfigureResult, Order } from 'services/order'
import { useDispatch, useSelector } from 'react-redux'
import { RootState, Dispatch } from 'src/store'
import Container from 'components/mobile/Container'
import { Tab, Column } from 'types/order'
import services from 'services'
import { renderTableCustom, renderTableRow } from 'src/pages/Order/scene'
import { OrderCard } from '../Model'
import { Search, StatusSelect } from './style'

const List: FC<{ configure?: ConfigureResult }> = (props) => {
  const [tabList, setTabList] = useState<Tab[]>()
  const [loading, setLoading] = useState<boolean>(true)
  // const [total, setTotal] = useState<number>(0)
  const [search, setSearch] = useState<string>('')
  const [columns] = useState<Column[]>([])
  const [data, setData] = useState<Order[]>([])

  const { order: { selectTab, selectOption, page } } = useSelector((state: RootState) => state)
  const { setSelectTab, setSelectOption, setPage } = useDispatch<Dispatch>().order

  // 初始化Tabs
  useEffect(() => {
    if (!props.configure) return
    setLoading(false)
    const { status } = props.configure
    const temp: Tab[] = []
    for (let name in status) { temp.push({ name, options: status[name] }) }
    temp.unshift({ name: '全部订单', options: [] })
    setTabList(temp)
    if (!selectTab) {
      setSelectTab(temp[0])
    }
  }, [props.configure])

  // 生成数据
  const handleData = useCallback((data: Order[]) => {
    if (!data || !data.length) return []
    const result: any[] = []
    data.forEach(order => {
      const item: any = {}
      columns.forEach(column => {
        const titleList = column.key.split(',')
        item[column.key] = renderTableRow(column.key, order, titleList)
      })
      item.key = order.id
      item.custom = renderTableCustom(item.key)
      result.push(item)
    })
    return result
  }, [columns])

  // 请求列表
  useEffect(() => {
    (async () => {
      setLoading(true)
      const data = await services.order.getList({
        status: selectOption || '',
        q: search,
        page: page.toString()
      })
      setLoading(false)
      // setTotal(data.total)
      setData(data.data)
    })()
  }, [page, selectOption, search, columns, handleData])

  // 切换Tab事件
  const handleChangeTabs = useCallback((name) => {
    if (!tabList) return
    setPage(1)
    setSelectTab(tabList.filter(item => item.name === name)[0])
    setSelectOption(tabList.filter(item => item.name === name)[0].options[0])
  }, [tabList])

  return (
    <Container loading={loading}>
      <Search
        onSearch={value => setSearch(value)}
        placeholder="搜索订单号/用户名/手机号"
      />
      {
        tabList && tabList.length &&
        <Tabs
          defaultActiveKey={selectTab?.name}
          type="line"
          onChange={handleChangeTabs}
          style={{ borderBottom: 'none', marginLeft: '10px' }}
        >
          {tabList.map(item => <Tabs.TabPane key={item.name} tab={item.name} />)}
        </Tabs>
      }
      {
        selectTab && selectTab.options && selectTab.options.length ?
          <StatusSelect 
            defaultValue={selectTab.options[0]} 
            value={selectOption}
            onChange={value => setSelectOption(value as string)}
          >
            {selectTab.options.map(name => <Select.Option value={name}>{name}</Select.Option>)}
          </StatusSelect> : null
      }
      <div style={{ marginTop: '-16px' }}>
        {
          data.map(item =>
            <OrderCard
              key={item.id}
              title={item.subject}
              status={item.status}
              id={item.id}
              date={item.created}
              username={item.name}
              phone={item.phone}
              color={item.color}
            />
          )
        }
      </div>
    </Container>
  )
}

export default List
