import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Button, DatePicker } from 'antd'
import moment from 'moment'
import { TimeScroll } from 'components'
import React, { CSSProperties, FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { weekItem } from 'types/time'
import { FormatDay, FormatDayChinese, getEndTimeFromWeek, getWeekDay, handleDateToWeek, handleWeekStartDate } from 'utils/time'
import {
  ActiveWeekDate, DateBorder, DateBox, DateTime, Header, Table, TodayButtonStyle, Week,
  WeekBox, WeekDate, WeekList, WeekText
} from './style'

interface WeekTableType {
  now: Date,
  date: Date,
  loading: boolean,
  scrollTop?: number,
  setDate: (payload: Date) => void,
  borderStyle?: CSSProperties,
  headerStyle?: CSSProperties,
  renderNoneBoxList: () => ReactNode,
  getWeekList?: (weekList: weekItem[]) => void,
  customHeader?: ReactNode,
}

/**
 * 生成时间格子组件
 * @param now 当前时间
 * @param date 选中时间
 * @param setDate 改变选中时间回调
*/
const WeekTable: FC<WeekTableType> = (props) => {
  const {
    borderStyle, headerStyle, now, date, setDate, scrollTop,
    renderNoneBoxList, children, getWeekList, customHeader, loading
  } = props
  const dateRef = useRef(null) //控制DatePicker实例
  const [isOpen, setIsOpen] = useState<boolean>(false) //控制DatePicker开关
  const [weekList, setWeekList] = useState<weekItem[]>([]) //
  const [week, setWeek] = useState<string>(`${now.getFullYear()}-${handleDateToWeek(now)}`)
  const [startTime, setStartTime] = useState<Date>(
    new Date(handleWeekStartDate(parseInt(week.split('-')[0]), parseInt(week.split('-')[1])))
  )
  const [endTime, setEndTime] = useState<Date>(
    new Date(new Date(handleWeekStartDate(parseInt(week.split('-')[0]), parseInt(week.split('-')[1]))).getTime()
      + (24 * 60 * 60 * 1000 * 6))
  )
  //点击日期左右箭头
  const handleClickAllow = useCallback((point: string) => {
    const startDate = new Date(handleWeekStartDate(parseInt(week.split("-")[0]), parseInt(week.split("-")[1])))
    const endDate = new Date(getEndTimeFromWeek(startDate))
    const result = point === "left" ? new Date(startDate.getTime()) : new Date(endDate.getTime() + 86400000 * 3)
    setWeek(`${result.getFullYear()}-${handleDateToWeek(result)}`)
  }, [week])

  //点击日期
  const handleClickDate = useCallback(() => {
    setIsOpen(true)
    const dom = dateRef.current as unknown as HTMLElement
    dom.focus()
  }, [dateRef])

  //点击今天
  const handleClickToday = useCallback(() => {
    setWeek(`${now.getFullYear()}-${handleDateToWeek(now)}`)
  }, [])

  //切换星期
  useEffect(() => {
    if (week) {
      const startDate = new Date(handleWeekStartDate(parseInt(week.split("-")[0]), parseInt(week.split("-")[1])))
      const tempWeekList = []
      for (let index = 0; index < 7; index++) {
        const date = new Date(startDate.getTime() + (24 * 60 * 60 * 1000 * index))
        tempWeekList.push({
          year: date.getFullYear().toString(),
          month: (date.getMonth() + 1).toString(),
          day: date.getDate().toString(),
          week: getWeekDay(date.getDay()),
          isToady: !!(FormatDay(date) === FormatDay(now))
        })
      }
      setStartTime(startDate)
      setEndTime(getEndTimeFromWeek(startDate))
      setWeekList(tempWeekList)
      setDate(startDate)
    }
  }, [week])

  //导出weekList
  useEffect(() => {
    getWeekList && getWeekList(weekList)
  }, [weekList])

  return (
    <div style={borderStyle}>
      <Header style={headerStyle}>
        <div>
          {customHeader}
        </div>
        <DateBorder>
          <Button
            style={TodayButtonStyle}
            onClick={handleClickToday}
          >
            今天
      </Button>
          <LeftOutlined
            color="#ccc"
            style={{ fontSize: "9px", marginLeft: "16px", cursor: "pointer" }}
            onClick={() => { handleClickAllow("left") }}
          />
          <DateBox onClick={handleClickDate}>
            <DateTime>{FormatDayChinese(startTime)}</DateTime>
            <div>-</div>
            <DateTime>{FormatDayChinese(endTime)}</DateTime>
          </DateBox>
          <RightOutlined
            color="#ccc"
            style={{ fontSize: "9px", cursor: "pointer" }}
            onClick={() => { handleClickAllow("right") }}
          />
          <DatePicker
            ref={dateRef}
            value={moment(date)}
            open={isOpen}
            onChange={(_value, string) => {
              setWeek(string)
              setIsOpen(false)
            }}
            picker="week"
            style={{
              position: "absolute",
              height: 20,
              left: 130,
              top: 10,
              zIndex: 2,
            }}
            onBlur={() => { setIsOpen(false) }}
          />
        </DateBorder>
      </Header>
      <Week>
        <WeekList>
          {weekList && weekList.length ? weekList.map(item =>
            <WeekBox key={item.day}>
              <WeekText>{item.week}</WeekText>
              {item.isToady ?
                <ActiveWeekDate>{item.day}</ActiveWeekDate>
                : <WeekDate>{item.day}</WeekDate>
              }
            </WeekBox>
          ) : null
          }
        </WeekList>
      </Week>
      <Table>
        <TimeScroll
          isLoading={loading}
          background={renderNoneBoxList()}
          scrollTop={scrollTop}
        >
          {children}
        </TimeScroll>
      </Table>
    </div>
  )
}

export default WeekTable