/*
 * File: /src/components/NavBar/index.tsx
 * Author: Mxsyx (zsimline@163.com)
 * Create Time: Monday 2020-11-23 04:17:25
 * Last Modified: Tuesday 2020-11-24 11:09:40
 * Modified By: Mxsyx (zsimline@163.com>)
 * 
 * Copyright © 2020 https://www.joyreserve.com
 */
import React, { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Menu, Spin } from 'antd'
import { InsertRowAboveOutlined, CrownOutlined, UserOutlined, FormOutlined } from '@ant-design/icons'
import services from 'services'
import { ossURL } from 'consts/url'
import { Body } from './style'

const entryTree = [{
  key: '1',
  title: '预约管理',
  icon: <CrownOutlined />,
  children: [{
    key: '1.1',
    title: '预约项目',
    link: '/item/list'
  }, {
    key: '1.2',
    title: '项目时间段配置',
    link: '/time/list'
  }, {
    key: '1.3',
    title: '月台管理',
    link: '/reserve/platform/list'
  }]
}, {
  key: '2',
  title: '系统配置',
  icon: <FormOutlined />,
  children: [{
    key: '2.1',
    title: '预约公告',
    link: '/notice/list'
  }, {
    key: '2.2',
    title: '注册登录信息',
    link: '/register/list'
  }, {
    key: '2.3',
    title: '日期展示设置',
    link: '/system/display_time/common'
  }, {
    key: '2.4',
    title: '自定义订单信息',
    link: '/system/custom_order_list'
  }, {
    key: '2.5',
    title: '第三方平台配置',
    link: '/system/empower'
  }, {
    key: '2.6',
    title: '预约规则设置',
    link: '/system/order_rule'
  }, {
    key: '2.7',
    title: '预约签到设置',
    link: '/system/attend/qrcode'
  }]
}, {
  key: '3',
  title: '订单管理',
  icon: <InsertRowAboveOutlined />,
  children: [{
    key: '3.1',
    title: '订单列表',
    link: '/order/list'
  }, {
    key: '3.2',
    title: '预约实况',
    link: '/daily/today'
  }, {
    key: '3.3',
    title: '手动添加预约',
    link: '/manual/detail'
  }]
}, {
  key: '4',
  title: '人员管理',
  icon: <UserOutlined />,
  children: [{
    key: '4.1',
    title: '管理员管理',
    link: '/administrators/list'
  }, {
    key: '4.2',
    title: '用户管理',
    link: '/user/list'
  }]
}]

interface Props {
  inlineCollapsed?: boolean
}
const NavBar: FC<Props> = (props) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [entryKeys, setEntryKeys] = useState<Set<string>>(new Set())
  const [subEntryKeys, setSubEntryKeys] = useState<Set<string>>(new Set())

  useEffect(() => {
    services.administrator.getGrade()
      .then(keys => {
        setEntryKeys(new Set(keys.map(key => key.split('.')[0])))
        setSubEntryKeys(new Set(keys))
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return (
    <Body>
      <img
        src={`${ossURL}/img/${props.inlineCollapsed ? 'mini_' : ''}logo.png`}
        style={props.inlineCollapsed ?
          { width: '36px', margin: '19px 22px 44px' } :
          { width: '188px', margin: '19px 34px 44px' }
        }
      />
      <Spin spinning={loading}>
        <Menu
          mode='inline'
          style={{ minHeight: '100%', borderRight: 'none' }}
          inlineCollapsed={props.inlineCollapsed}
        >
          {entryTree.filter(entry => entryKeys.has(entry.key)).map(entry =>
            <Menu.SubMenu key={entry.key} title={entry.title} icon={entry.icon}>
              {entry.children.filter(subEntry => subEntryKeys.has(subEntry.key)).map((subEntry) =>
                <Menu.Item key={subEntry.key}>
                  <Link to={subEntry.link}>{subEntry.title}</Link>
                </Menu.Item>
              )}
            </Menu.SubMenu>
          )}
        </Menu>
      </Spin>
    </Body >
  )
}

export default NavBar
