/*
 * File: /src/pages/System/View/OrderRule/style.ts
 * Author: Mxsyx (zsimline@163.com)
 * Create Time: Monday 2020-11-09 06:35:41
 * Last Modified: Monday 2020-11-09 06:35:41
 * Modified By: Mxsyx (zsimline@163.com>)
 * 
 * Copyright © 2020 https://www.joyreserve.com
 */

import styled from "styled-components"
import { Input as AntInput } from 'antd'

export const Input = styled(AntInput)`
  border-bottom: solid 1px #CCC;
  width: 60px;
`

export const PrivateRuleName = styled(AntInput)`
  font-size: 18px;
  font-weight: 800;
`
