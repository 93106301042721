import { Button, message, Popconfirm, Table, Tooltip } from "antd"
import { Block, Header } from "components"
import React, { FC, ReactNode, useCallback, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { item, system } from "services"
import { BreadcrumbOption } from "types/antd"
import { RoughCustomOrderItemType } from "types/system"

const columns = [{
  key: 'name',
  dataIndex: 'name',
  title: '订单信息名称',
  className: 'drag-visible'
}, {
  key: 'is_disable',
  dataIndex: 'is_disable',
  title: '状态'
}, {
  key: 'resource_list',
  dataIndex: 'resource_list',
  title: '预约项目'
}, {
  key: 'handle',
  dataIndex: 'handle',
  title: '操作'
}]

const CustomOrderList: FC = () => {
  const [breadcrumbs] = useState<BreadcrumbOption[]>([{
    name: '系统配置'
  }, {
    name: '自定义订单信息'
  }])
  const [data, setData] = useState<RoughCustomOrderItemType[]>()
  const [resourceList, setResourceList] = useState<{ id: number, name: string }[]>()
  const [render, setRender] = useState<number>(1)

  useEffect(() => {
    (async () => {
      const data = await item.getSummaryList()
      setResourceList(data)
    })()
  }, [])

  useEffect(() => {
    resourceList && resourceList.length && (async () => {
      const data = await system.getCustomOrderList()
      setData(data)
    })()
  }, [render, resourceList])

  const handleClickDel = useCallback(async (id: number) => {
    try {
      await system.delCustomOrder({ id })
      setRender(render + 1)
      message.success("删除成功")
    } catch (err) {
      err.response && message.error(err.response.data.message)
    }
  }, [render])

  const handleData = useCallback((data?: RoughCustomOrderItemType[]) => {
    if (!data || !data.length || !resourceList || !resourceList.length) return []
    return data.map(item => {
      let tempList: string[] = []
      let resourceColumn: ReactNode = null
      if (item.resource_list && item.resource_list.length) {
        tempList = item.resource_list.map(item => resourceList.filter(option => Number(item) === option.id)[0].name)
      }
      if (tempList.length === 1) {
        resourceColumn = tempList[0]
      }
      if (tempList.length > 1) {
        resourceColumn = <Tooltip title={tempList.map(item => <div>{item}</div>)}>{tempList[0]}...</Tooltip>
      }
      return ({
        ...item,
        resource_list: resourceColumn,
        handle: <div style={{ marginLeft: -15 }}>
          <Link to={`/system/custom_order_edit/${item.id}`}>
            <Button type="link">编辑</Button>
          </Link>
          <Popconfirm title="确定删除" onConfirm={() => handleClickDel(item.id)}>
            <Button type="link">删除</Button>
          </Popconfirm>
        </div>
      })
    })
  }, [resourceList, render])

  return (
    <div>
      <Header
        title="自定义订单信息"
        description="配置移动端用户跟随订单一起提交的额外信息，信息名称以及内容填写方式支持自定义。"
        breadcrumbs={breadcrumbs}
      />
      <Block>
        <Link to="/system/custom_order_edit/0">
          <Button type="primary" style={{ margin: "24px 0" }}>添加订单信息</Button>
        </Link>
        <Table
          columns={columns}
          dataSource={handleData(data)}
        />
      </Block>
    </div>
  )
}

export default CustomOrderList