/*
 * @Author: your name
 * @Date: 2020-11-06 21:18:18
 * @LastEditTime: 2020-12-04 11:48:53
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/consts/system.ts
 */
import { MenuOutlined } from "@ant-design/icons"
import React from "react"
import { Entry } from "types/system"

export const customOrderTypeList = [
  { name: "普通类型", list: ["单选", "多选", "文字输入框", "图片上传"] },
  { name: "特殊类型", list: ["手机号码", "身份证号", "车牌号"] }
]

export const customOrderDefaultModelForm: Entry = {
  name: "",
  type: "",
  index: 0,
  options: [],
  number: 1,
  is_cache: false,
  is_require: false
}

export const customOrderEditColumns = [{
  title: '排序',
  dataIndex: 'sort',
  width: 30,
  className: 'drag-visible',
  render: () => <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />,
}, {
  key: 'name',
  dataIndex: 'name',
  title: '名称',
  className: 'drag-visible'
}, {
  key: 'type',
  dataIndex: 'type',
  title: '类型'
}, {
  key: 'handle',
  dataIndex: 'handle',
  title: '操作'
}]