import styled from "styled-components"

export const Box = styled.div`
  width: 148px;
  height: 74px;
  border: 1px solid rgba(0,0,0,0.05);
  position: relative;
  float: left;
`

export const BoxSpace = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  position: absolute;

  :hover {
    background: none;
  }
`

export const BoxContent = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border: 2px dashed #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  color:rgba(0,0,0,0.45);
  font-size: 12px;
  cursor: pointer;
`

export const Text = styled.div`
  user-select: none;
  margin-left: 5px;
`

