/*
 * @Author: your name
 * @Date: 2020-10-13 10:01:43
 * @LastEditTime: 2020-10-21 15:45:34
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/consts/time.ts
 */
import { PeriodSpaceType, PeriodType } from "types/time"

export const PanelRowStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  paddingRight: '78px',
  marginTop: '16px'
}

export const PanelLeftRowStyle = {
  display: 'flex',
  justifyContent: 'flex-start',
  paddingLeft: '95px',
  marginTop: '16px'
}

export const PanelInput = {
  width: '224px'
}

export const rereadRuleList = [{
  id: 'day',
  name: '每天重复配置'
}, {
  id: 'week',
  name: '每周重复配置'
}, {
  id: 'month',
  name: '每月重复配置'
}, {
  id: 'work',
  name: '在工作日重复配置(周一至周五)'
}, {
  id: 'rest',
  name: '在休息日重复配置(不含节假日)'
}, {
  id: 'custom',
  name: '自定义重复规则'
}]

export const useRuleList = [{
  id: 'today',
  name: '仅当日'
}, {
  id: 'now_week',
  name: '仅本周'
}, {
  id: 'now_month',
  name: '仅本月'
}, {
  id: 'day',
  name: '全部日'
}, {
  id: 'work',
  name: '工作日(周一至周五)'
}, {
  id: 'rest',
  name: '休息日(不含节假日)'
}, {
  id: 'custom',
  name: '自定义'
}]

export const periodList = [{
  name: '周',
  value: PeriodSpaceType.week
}, {
  name: '月',
  value: PeriodSpaceType.month
}]

export const defaultCustomRule = {
  period_space: PeriodSpaceType.day,
  period_value: 0,
  period_type: PeriodType.always,
  period_data: null,
  day: []
}