/*
 * @Author: your name
 * @Date: 2020-09-02 10:02:38
 * @LastEditTime: 2020-10-10 17:25:52
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/components/UserNavBar/style.ts
 */
import styled from "styled-components"

export const Body = styled.div`
  position: relative;
  height: 64px;
  margin-bottom: 2px;
  background: white;
  display:flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 20px 24px;
`

export const Bar = styled.div`
  display: flex;
  align-items: center;
`

export const Name = styled.p`
  margin-left:8px;
  margin-bottom: 0;
`

export const AvatarBar = styled.div`
  margin-left:12px;
  padding-left:12px;
  padding-right:12px;
  height: 64px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;

  :hover {
    background: #D4ECE5;
  }
`

export const Panel = styled.div`
  width: 100%;
  top: 64px;
  left: 0;
  background: white;
  position: absolute;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
`