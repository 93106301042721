export const recordColumns = [{
  key: 'id',
  dataIndex: 'id',
  title: '订单号'
}, {
  key: 'subject',
  dataIndex: 'subject',
  title: '预约项目'
}, {
  key: 'created',
  dataIndex: 'created',
  title: '预约时间'
}, {
  key: 'status',
  dataIndex: 'status',
  title: '订单状态'
}, {
  key: 'operation',
  dataIndex: 'operation',
  title: '操作'
}]
