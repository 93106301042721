import { Input, message, Modal } from "antd"
import React, { FC, useCallback, useState } from "react"
import order, { ActionPayload } from "services/order"
import { View } from "./style"

interface ModalType {
  isOpen: boolean,
  onConfirm: () => void,
  onCancel: () => void,
  selectRowIdList: React.ReactText[],
}

const RejectModal: FC<ModalType> = (props) => {
  const { isOpen, onConfirm, onCancel, selectRowIdList } = props
  const { TextArea } = Input
  const [content, setContent] = useState<string>()

  const reject = useCallback(async () => {
    try {
      let payload: ActionPayload = {
        id: selectRowIdList.map(item => item.toString()),
        action: "取消",
        payload: { content: content || "未填写理由" }
      }
      await order.action(payload)
      message.success(`您已取消选中订单`, 5)
      onConfirm()
    } catch (err) {
      message.error(`操作出现异常`, 5)
    }
  }, [selectRowIdList, content, onConfirm])

  return (
    <Modal
      visible={isOpen}
      title="取消订单"
      onOk={reject}
      onCancel={() => onCancel()}
    >
      <View style={{ marginTop: 0 }}>
        <p>原因:</p>
        <TextArea
          rows={4}
          value={content}
          onChange={(e) => { setContent(e.target.value) }} />
      </View>
    </Modal>
  )
}

export default RejectModal
