import { createModel } from '@rematch/core'

interface UserLoginState {
  isOpen: boolean
}

export const userLogin = createModel({
  state: {
    isOpen: false
  } as UserLoginState,
  reducers: {
    setIsOpen: (state, payload: boolean): UserLoginState => ({
      ...state,
      isOpen: payload
    }),
  },
  effects: {

  }
})