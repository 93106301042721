import React, { FC, useState } from "react"
import { Route } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { BreadcrumbOption } from "types/antd"
import { Header } from "components"
import List from "./View/List"
import Detail from "./View/Detail"

const User: FC = () => {
  const { t } = useTranslation()

  const [breadcrumbs] = useState<BreadcrumbOption[]>([{
    name: "人员管理"
  }, {
    name: "用户管理"
  }])

  const renderHeader = () => <Header
    title={t("page_user_list_title")}
    description={t("page_user_list_description")}
    breadcrumbs={breadcrumbs}
  />

  return (
    <div>
      <Route path='/user/list' render={renderHeader} />
      <Route path='/user/list' render={() => <List />} />
      <Route path='/user/detail/:id' render={() => <Detail />} />
    </div>
  )
}

export default User