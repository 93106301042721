import store from "src/store"
import { Scene } from "types/app"

export const scene = (payload: {
  johnson: () => void
}) => {
  const { configure } = store.getState().app  
  switch (configure.scene) {
    case Scene.johnson: return payload.johnson()
  }
}