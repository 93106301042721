import React, { FC, useState, useCallback, useEffect } from 'react'
import { InputBox } from 'components'
import { RereadRuleType, CustomRuleType } from 'types/time'
import moment from "moment"
import { Drawer, Tabs, Input, DatePicker, Switch, Button, message } from 'antd'
import { PanelRowStyle, PanelLeftRowStyle, PanelInput, defaultCustomRule } from 'consts/time'
import { FormatDay } from 'utils/time'
import { useParams } from 'react-router'
import Reread from '../Reread'
import { time } from 'services'

interface NewPanelType {
  isOpen: boolean,
  defaultDate: string,
  defaultTime: string,
  onClose: () => void,
  onSubmit: () => void,
}

const NewPanel: FC<NewPanelType> = (props) => {
  const { cardId: resource_id } = useParams<{ cardId: string }>()
  const { isOpen, defaultDate, defaultTime, onClose, onSubmit } = props
  const { TabPane } = Tabs
  const { RangePicker } = DatePicker
  const [tag, setTag] = useState<string>()
  const [load, setLoad] = useState<number>(0)
  const [date, setDate] = useState<Date>()
  const [timeRange, setTimeRange] = useState<string[]>()
  const [rereadRule, setRereadRule] = useState<RereadRuleType>(RereadRuleType.day)
  const [isUseRereadRule, setIsUseRereadRule] = useState<boolean>(false)
  const [disable, setDisable] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [customRule, setCustomRule] = useState<CustomRuleType>(defaultCustomRule)

  useEffect(() => {
    setDate(new Date(defaultDate))
    setTimeRange([defaultTime!, defaultTime.split(':')[0] === '23' ? '23:59' : `${Number(defaultTime!.split(':')[0]) + 1}:00`])
  }, [defaultTime, defaultDate])

  const createNewItem = useCallback(async () => {
    setLoading(true)
    const payload = {
      time_id: 0,
      tag: tag || '',
      weight: 1,
      load: load || -1,
      resource_id: Number(resource_id),
      date: FormatDay(date!),
      start_time: timeRange![0],
      end_time: timeRange![1],
      reread_rule: isUseRereadRule && rereadRule ? rereadRule : RereadRuleType.today,
      custom_rule: customRule,
      disable,
      month_number: 0,
    }
    try {
      await time.newTimeItem(payload)
      onSubmit()
    } catch (err) {
      err.response && message.error(err.response.data.message)
    } finally {
      setLoading(false)
    }
  }, [date, timeRange, load, tag, rereadRule, isUseRereadRule, customRule, disable, onSubmit, resource_id])

  return (
    <React.Fragment>
      <Drawer
        destroyOnClose
        visible={isOpen}
        width={512}
        title='新建'
        bodyStyle={{
          padding: ' 0 24px',
        }}
        onClose={onClose}
        footerStyle={{ display: 'flex', justifyContent: 'flex-end' }}
        footer={
          <React.Fragment>
            <Button
              loading={loading}
              disabled={loading}
              onClick={onClose}
            >
              退出
            </Button>
            <Button
              loading={loading}
              disabled={loading}
              onClick={() => { createNewItem() }}
              type='primary'
              style={{ marginLeft: '8px' }}
            >
              新建
            </Button>
          </React.Fragment>
        }
      >
        <Tabs defaultActiveKey='1'>
          <TabPane tab='&nbsp;&nbsp;基本设置&nbsp;&nbsp;' key='1'>
            <InputBox style={PanelRowStyle} title='时间段标签'>
              <Input
                value={tag}
                onChange={(e) => { setTag(e.target.value) }}
                style={PanelInput}
              />
            </InputBox>
            <InputBox style={PanelRowStyle} title='选择日期'>
              <DatePicker
                value={moment(date)}
                style={PanelInput}
                onChange={(_value, string) => setDate(new Date(string))}
                allowClear={false}
              />
            </InputBox>
            <InputBox style={PanelRowStyle} title='起止时间'>
              <RangePicker
                picker='time'
                format='HH:mm'
                value={timeRange && timeRange.length ? [moment(timeRange[0], 'HH:mm'), moment(timeRange[1], 'HH:mm')] : undefined}
                onChange={(_value, string) => setTimeRange(string)}
                allowClear={false}
                style={{
                  width: '224px'
                }}
              />
            </InputBox>
            <InputBox style={PanelRowStyle} title='可预约人数'>
              <Input
                type='number'
                value={load}
                onChange={(e) => { setLoad(Number(e.target.value) < 0 ? 0 : Number(e.target.value)) }}
                style={PanelInput}
              />
            </InputBox>
            <InputBox style={PanelLeftRowStyle} title='开放状态'>
              <Switch
                checked={disable}
                checkedChildren="开放"
                unCheckedChildren="禁用"
                onChange={(value) => { setDisable(value) }}
              />
            </InputBox>
            <InputBox style={PanelLeftRowStyle} title='重复生成'>
              <Switch
                checked={isUseRereadRule}
                checkedChildren="使用"
                unCheckedChildren="禁用"
                onChange={(value) => { setIsUseRereadRule(value) }}
              />
            </InputBox>
            {
              isUseRereadRule ?
                <Reread
                  reread_rule={rereadRule}
                  custom_rule={customRule}
                  onchangeRereadRule={(value) => setRereadRule(value)}
                  onchangeCustomRule={(value) => setCustomRule(value)}
                />
                : null
            }
          </TabPane>
          <TabPane tab='&nbsp;&nbsp;高级设置&nbsp;&nbsp;' key='2'>
            暂无可选项
        </TabPane>
        </Tabs>
      </Drawer>
    </React.Fragment>
  )
}

export default NewPanel