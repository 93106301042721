/*
 * File: /src/services/platform.ts
 * Author: Mxsyx (zsimline@163.com)
 * Create Time: Monday 2020-11-16 01:14:57
 * Last Modified: Monday 2020-11-16 01:14:57
 * Modified By: Mxsyx (zsimline@163.com>)
 * 
 * Copyright © 2020 https://www.joyreserve.com
 */
import http from 'utils/http'
import { Platform, PlatformBase, Rule, Topic } from 'types/platform'

export default {
  // 新增月台
  createPlatform: (payload: PlatformBase) => http.post<any>('/Scene/platforms', payload),
  // 删除月台
  deletePlatform: (id: number) => http.del<any>(`/Scene/platforms/${id}`),
  // 更新月台
  updatePlatform: (id: number,  payload: PlatformBase) => http.put<any>(`/Scene/platforms/${id}`, payload),
  // 获取月台基本信息
  getPlatform: (id: number) => http.get<PlatformBase>(`/Scene/platforms/${id}`),
  // 获取月台列表
  getPlatformList: () => http.get<Platform[]>('/Scene/platforms'),
  // 新增月台规则
  createRule: (id: number, payload: Payload.CreateRule) => http.post<any>(`/Scene/platforms/${id}/rules`, payload),
  // 删除月台规则
  deleteRule: (id: number, ruleId: number) => http.del<any>(`/Scene/platforms/${id}/rules/${ruleId}`),
  // 更新月台规则
  updateRule: (id: number, ruleId: number, payload: Payload.CreateRule) => http.put<any>(`/Scene/platforms/${id}/rules/${ruleId}`, payload),
  // 获取月台规则列表
  getRuleList: (id: number) => http.get<Rule[]>(`/Scene/platforms/${id}/rules`),
}

declare namespace Payload {
  export interface CreateRule {
    topics: Topic[]
  }
}
