import styled from "styled-components"

export const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-width: 1161px;
`

export const Row = styled.div`
  display: flex;
`

export const Body = styled.div`
  margin-top: 30px;
  position: relative;
`

export const Border = styled.div`
  height: 665px;
  width: 1088px;
  padding-bottom:59px;
  overflow-y: auto;
  display: flex;
`

export const Content = styled.div`
  margin-left: 5px;
  width: 1050px;
  height: 1775px;
  margin-top: 5px;
  border-left: 1px solid rgba(0,0,0,0.45);
  position: relative;
  z-index: 2;
`

export const BoxItemBorder = styled.div`
  position: absolute;
  z-index: 3;

  :hover {
    z-index: 4;
  }
`

export const BoxItem = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  box-sizing: border-box;
  overflow: hidden;
  padding: 8px;
  border-radius: 5px;
  box-shadow:2px 2px 2px 3px rgba(0,0,0,0.15);
  border:1px solid #eee;
  user-select: none;

  :hover {
    top: -2px;
    left: -2px;
    z-index: 4;
    box-shadow:4px 4px 4px 3px rgba(0,0,0,0.15);
  }
`

export const Bottom = styled.div`
  margin-left: 38px;
  width: 1037px;
  height: 1px;
  background: rgba(0,0,0,0.45);
  position: absolute;
  bottom: 67px;
  z-index: 4;
`

export const TopContentSpace = styled.div`
  margin-left: 38px;
  width: 1045px;
  height: 5px;
  background: white;
  position: absolute;
  top: 0;
  z-index: 4;
`

export const BottomContentSpace = styled.div`
  margin-left: 35px;
  width: 1045px;
  height: 67px;
  background: white;
  position: absolute;
  bottom: 0;
  z-index: 4;
`

export const BottomColumnSpace = styled.div`
  margin-left: 0;
  width: 35px;
  height: 50px;
  background: white;
  position: absolute;
  bottom: 0;
  z-index: 4;
`

export const Column = styled.div`
  width: 33px;
  height: 1789px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Time = styled.div`
  height: 14px;
  width:33px;
  height:14px;
  font-size:12px;
  color:rgba(0,0,0,0.45);
  line-height:14px;
`

export const Text = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height:22px;
  height:22px;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;  
`

export const Right = styled.div`
  width: 183px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  right: 0;
`
