/*
 * @Author: your name
 * @Date: 2020-10-22 19:24:37
 * @LastEditTime: 2020-11-05 19:25:41
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/pages/DisplayTime/View/style.ts
 */
import styled from 'styled-components'

interface RectType {
  isDragging: boolean,
  top: number,
}

export const Title = styled.div`
  height: 24px;
  font-size: 16px;
  font-weight: 600;
`

export const Rect = styled.div.attrs<RectType>((props: any) => ({
  style: {
    transition: props.isDragging ? 'none' : 'all 500ms'
  }
})) <RectType>` 
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: ${({ top }) => top}px;
`;

export const ModalBody = styled.div`
  display: flex; 
  align-items: center;
  flex-direction: column ;
`
