/*
 * @Author: your name
 * @Date: 2020-10-28 10:01:12
 * @LastEditTime: 2020-10-29 11:16:14
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/pages/System/Model/displayTimeRuleCard/style.ts
 */
import styled from 'styled-components'

export const Body = styled.div`
  padding-top: 24px;
  position: relative;
`

export const Mark = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
  cursor: not-allowed;
`

export const Text = styled.p`
  margin-top: 15px;
  font-size: 14px;
`


